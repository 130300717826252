import React from 'react';

const ChartCardItem = ({ children, ...props }) => {
   return (
      <div className=' flex relative h-[48px] pb-[8px] pt-[10px] w-full items-end justify-center font-[Satoshi] text-[22px] text-white [&:last-child>div]:opacity-0'>
         <p className='text-[20px] absolute left-0'>{props.number}.</p>
         {children}
         <div className='absolute left-0 bottom-[1px] w-full h-[1px] bg-[linear-gradient(104deg,rgba(255,255,255,0.56)_12.06%,rgba(96,53,205,0.59)_94.63%)]'></div>
      </div>
   );
};

export default ChartCardItem;