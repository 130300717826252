import { auth, db } from '../firebase';
import { getDoc, doc, collection, ref, where, query } from "firebase/firestore"
// import data from '../json/insttest';

// var list = []
// Object.values(data).map((element, index) => {
//    list = [...list, element]
// })

export default class PostService {

   static async getById(id) {
      var currentElement
      await getDoc(doc(db, "institutions", id)).then(
         (querySnapshot) => {
            currentElement = querySnapshot.data();
         }
      )
      // await getDocs(query(collection(db, "institutions"), where('__name__', "==", id))).then((querySnapshot) => {
      //    currentElement = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      // })
      return currentElement;

   }
}