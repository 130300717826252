import React, { useEffect, useRef, useState } from 'react';


const Checkbox = ({ children, ...props }) => {
   const inputCheckboxBg = useRef();
   const mainInput = useRef()

   useEffect(() => {
      if (children) {
         if (children.includes(props.resetContent) || children.toString().replace(",[object Object],", " ").includes(props.resetContent)) {
            mainInput.current.checked = false;
            classesForCheckbox.map((element) => {
               inputCheckboxBg.current.className = inputCheckboxBg.current.className.replace(" " + element, "");
            })
            props.setResetContent(0)
         }
      }
   }, [props.resetContent])




   const classesForCheckbox = [props.color ? `${props.color}` : 'bg-purple', 'after:bg-cross', 'after:content-[""]', 'after:bg-center', 'after:absolute', 'after:top-0', 'after:left-0', 'sm:after:w-[14px]', 'sm:after:h-[14px]', 'after:bg-no-repeat', 'after:w-[10px]', 'after:h-[10px]'];
   function changeHandler(e) {
      if (props.filterCheck)
         props.func(props.formalcolor, children.toString().replace(",[object Object],", " "), children.toString().replace(",[object Object],", " "), e.target.checked);
      if (props.valueCheck) {
         props.value[Object.keys(props.value)[1]](!props.value[Object.keys(props.value)[0]])
      }
      if (!inputCheckboxBg.current.className.includes('after:bg-no-repeat'))
         classesForCheckbox.map((element) => {
            inputCheckboxBg.current.className += " " + element;
         })
      else
         classesForCheckbox.map((element) => {
            inputCheckboxBg.current.className = inputCheckboxBg.current.className.replace(" " + element, "");
         })
   }
   return (
      <div className=' select-none font-[Satoshi] font-light  text-white flex items-center gap-[10px] justify-between leading-none [&:not(&:last-child)]:mb-[8px]'>
         {children}
         <div className='relative flex justify-center'>
            <input required={props.required} ref={mainInput} onChange={changeHandler} type='checkbox' className='sm:w-[16px] w-[12px] sm:h-[16px] h-[12px] opacity-0 relative cursor-pointer pointer-events-auto
         ' />
            <div ref={inputCheckboxBg} className='pointer-events-none  border absolute w-[12px] h-[12px] sm:h-[16px] sm:w-[16px] opacity-100 top-0 left-0 boreder-white sm:rounded-[5px] rounded-[3px] '></div>
         </div>

      </div>

   );
};

export default Checkbox;