import React from 'react';
import Container from './container';
import LinkWithLogo from './UI/linkwithlogo';
import MapIco from '../img/location.svg';
import EmailIco from '../img/email.svg';
import LogoBlack from './UI/logoblack';
import LogoX from '../img/x.svg';
import LogoIn from '../img/in.svg';
import { useNavigate } from 'react-router-dom';
import LogoGit from '../img/git.svg';

const Footer = () => {
   const router = useNavigate();
   function BackLink() {
      router(-1);
   }
   return (
      <footer className='bg-black pt-[60px] pb-[60px] sm:pb-[78px]'>
         <Container>
            <div className='flex flex-col items-center sm:items-start sm:grid sm:grid-cols-3 gap-[50px] sm:gap-0'>
               <div className='flex items-center sm:items-start  flex-col gap-[15px] order-2 sm:order-1'>
                  <h1 className='font-[Satoshi] text-white font-medium text-[20px] select-none'>Contacts:</h1>
                  <LinkWithLogo className="">Nightlife, Maribor</LinkWithLogo>
                  <LinkWithLogo className='sm:p-0 px-[22px]' src={MapIco}>Here will be our address</LinkWithLogo>
                  <LinkWithLogo href="mailto:nightlife.clientservice@gmail.com " src={EmailIco}>nightlife.clientservice@gmail.com</LinkWithLogo>
               </div>
               <LogoBlack onClick={BackLink} className='transition duration-100 ease-linear hover:drop-shadow-[0px_0px_5px_white] cursor-pointer m-auto order-1 sm:order-2' />
               <div className='order-3 flex flex-col justify-end sm:self-end items-center sm:items-end'>
                  <div className='flex gap-[10px] mb-[20px]'>
                     <a target='_blank' href="https://twitter.com/derhachov_dev"><img src={LogoX} alt="" /></a>
                     <a target='_blank' href="https://www.linkedin.com/in/fedir-derhachov"><img src={LogoIn} alt="" /></a>
                     <a target='_blank' href="https://github.com/fdergachev"><img src={LogoGit} alt="" /></a>
                  </div>
                  <div className=' select-none font-[Satoshi] text-[18px] font-light text-white'>
                     ©Fedir Derhachev 2024
                  </div>
               </div>
            </div>
         </Container>
      </footer>
   );
};

export default Footer;