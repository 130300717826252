const data = {
   "month": "September",
   "rates": {
      "average": [
         {
            "id": 1,
            "name": "K4 Club",
            "average": 4.57,
            "img": require("../img/listitemimg.jpg")
         },
         {
            "id": 2,
            "name": "Cirkus Klub",
            "average": 4.27,
            "img": require("../img/circus.jpg")
         },
         {
            "id": 3,
            "name": "Noname1",
            "average": 3.27,
            "img": require("../img/circus.jpg")
         },
         {
            "id": 4,
            "name": "Noname2",
            "average": 1.27,
            "img": require("../img/circus.jpg")
         }
         ,
         {
            "id": 5,
            "name": "Noname3",
            "average": 2.27,
            "img": require("../img/circus.jpg")
         }
      ],
      "price": [
         {
            "id": 1,
            "name": "K4 Club",
            "average": 4.57,
            "img": require("../img/listitemimg.jpg")
         },
         {
            "id": 2,
            "name": "Cirkus Klub",
            "average": 4.27,
            "img": require("../img/circus.jpg")
         },
         {
            "id": 3,
            "name": "Noname1",
            "average": 3.27,
            "img": require("../img/circus.jpg")
         },
         {
            "id": 4,
            "name": "Noname2",
            "average": 1.27,
            "img": require("../img/circus.jpg")
         }
         ,
         {
            "id": 5,
            "name": "Noname3",
            "average": 2.27,
            "img": require("../img/circus.jpg")
         }
      ],
      "service": [
         {
            "id": 1,
            "name": "K4 Club",
            "average": 4.57,
            "img": require("../img/listitemimg.jpg")
         },
         {
            "id": 2,
            "name": "Cirkus Klub",
            "average": 4.27,
            "img": require("../img/circus.jpg")
         },
         {
            "id": 3,
            "name": "Noname1",
            "average": 3.27,
            "img": require("../img/circus.jpg")
         },
         {
            "id": 4,
            "name": "Noname2",
            "average": 1.27,
            "img": require("../img/circus.jpg")
         }
         ,
         {
            "id": 5,
            "name": "Noname3",
            "average": 2.27,
            "img": require("../img/circus.jpg")
         }
      ]


   }
}
export default data;