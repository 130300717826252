import React, { useEffect, useRef, useState } from 'react';

const FormTimeInput = (props) => {
   const [dataTime, setDataTime] = useState("null")
   const inputTime = useRef();

   const classesForCheckbox = [props.color ? `${props.color}` : 'bg-purple'];
   function changeHandler(e) {
      setDataTime(inputTime.current.value)
   }
   useEffect(() => {
      if (props.resetContent && props.resetContent.includes(dataTime)) {
         setDataTime(0);
         classesForCheckbox.map((element) => {
            inputTime.current.className = inputTime.current.className.replace(" " + element, " bg-[rgba(255,255,255,0)]");
         })
         inputTime.current.blur()
      }
   }, [props.resetContent])
   useEffect(() => {



      if (props.reset == 1) {
         props.resetfunc();
         setDataTime(0);
         classesForCheckbox.map((element) => {
            inputTime.current.className = inputTime.current.className.replace(" " + element, " bg-[rgba(255,255,255,0)]");
         })
         inputTime.current.blur()

      }

   })
   function focusHandler(e) {
      // console.log(e.target.value)
      props.func(dataTime);
      if (inputTime.current.value && !inputTime.current.className.includes('bg-purple') && !inputTime.current.className.includes(`${props.color}`))
         classesForCheckbox.map((element) => {
            inputTime.current.className = inputTime.current.className.replace(" bg-[rgba(255,255,255,0)]", " " + element);
         })
      else if (!inputTime.current.value)
         classesForCheckbox.map((element) => {
            inputTime.current.className = inputTime.current.className.replace(" " + element, " bg-[rgba(255,255,255,0)]");
         })
   }
   return (
      <input value={dataTime} className=' focus:outline-none text-[16px] bg-[rgba(255,255,255,0)] [&::-webkit-calendar-picker-indicator]:hidden px-[3px] pb-[0.5px] border h-[18px] w-[50px] boreder-white rounded-[5px]' type='time' onBlur={focusHandler} onChange={changeHandler} ref={inputTime} />
   );
};

export default FormTimeInput;