import React from 'react';
import Logo from './UI/logo.jsx';
import Container from './container.jsx';
import { useNavigate } from 'react-router-dom';

const SignInHeader = () => {
   const router = useNavigate();
   function ClickHandler() {
      router('/welcome');
   }
   function BackLink() {
      router(-1);
   }
   function ArrowSourceActive() {
      // setAarrowSource(ArrowSmallActive);
      document.getElementById('arrow').classList.add('bg-arrowSmallActive');
   }
   function ArrowSourceNonActive() {
      // setAarrowSource(ArrowSmall);
      document.getElementById('arrow').classList.remove('bg-arrowSmallActive');
   }
   return (

      <Container >
         <header className='pt-[20px] sm:pt-[40px] flex justify-between'>
            <Logo className='cursor-pointer' onClick={ClickHandler} />
            <div onMouseEnter={ArrowSourceActive} onMouseLeave={ArrowSourceNonActive}
               onClick={BackLink}
               className=' 
                     transition-all delay-0 duration-200 ease-in-out text-white font-[Satoshi] flex items-center gap-2.5
                     font-medium text-[16px] sm:text-[24px] leading-normal cursor-pointer hover:text-[#D5C3FF]
                     '>

               <div id="arrow" className='rotate-180 h-[16px] w-[16px] transition-all delay-0 duration-200 ease-in-out bg-arrowSmall'></div>
               {/* <img src={arrowSource} alt="" className='transition-all delay-0 duration-300 ease-linear' /> */}
               back
            </div>
         </header>
      </Container>

   );
};

export default SignInHeader;