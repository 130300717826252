import React, { useContext, useEffect, useState } from 'react';
import SignInHeader from '../components/signinheader.jsx';
import Form from '../components/UI/form.jsx';
import Container from '../components/container.jsx';
import FormInputWhite from '../components/UI/forminputwhite.jsx';
import Checkbox from '../components/UI/checkbox.jsx';
import Button from '../components/UI/button.jsx';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/footer.jsx';
import { AuthContext } from '../context';
import { createUserWithEmailAndPassword, sendEmailVerification, beforeAuthStateChanged } from 'firebase/auth';
import { getFunctions } from 'firebase/functions'
import { auth } from "../firebase.js";
import { db } from "../firebase.js"
import { doc, setDoc, getDocs, collection, query, addDoc, where } from "firebase/firestore";
import cross from "../img/errorcross.svg"
import Subtitle from './UI/subtitle.jsx';
import AgreePrivacy from './UI/agreeprivacy.jsx';
import imageCompression from 'browser-image-compression';
import ContextWindow from './contextwindow.jsx';
import Subtext from './UI/subtext.jsx';

const OwnerRegistration = () => {
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const [instName, setInstName] = useState("")
   const [name, setName] = useState("");
   const [location, setLocation] = useState("");
   const [receiveNews, setReceiveNews] = useState(false);
   const [privacy, setPrivacy] = useState(false);
   const [idPic, setIdPic] = useState();
   const [billPic, setBillPic] = useState();
   const [passwordDuplication, setPasswordDuplication] = useState("");

   const [instNameError, setInstNameError] = useState("false")
   const [locatioError, setLocationError] = useState("false")
   const [errors, setErrors] = useState([])
   const [passwordError, setPasswordError] = useState("false");
   const [emailError, setEmailError] = useState("false");
   const [usernameError, setUsernameError] = useState("false");

   const [isSuccessVisible, setIsSuccessVisible] = useState(false);
   const [applicationSent, setApplicationSent] = useState(false);


   var locationRegExp = new RegExp(/^[A-Za-z][a-z]*(?: [A-Za-z][a-z]*)?, [A-Z][a-z]*(?: [A-Z][a-z]*)?$/g)
   var emailRegExp = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);

   // const [compressedIdPic, setCompressedIdPic] = useState();
   // const [compressedBillPic, setCompressedBillPic] = useState();
   // async function fetchOwnersRequests() {
   //    await getDocs(collection(db, "ownersRequests")).then((querySnapshot) => {
   //       const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
   //       setUsernames(newData);
   //    })

   // }
   // function nameUniqueCheck() {
   //    var unique = true
   //    if (usernames)
   //       usernames.map(element => {
   //          if (element.nickname == username) unique = false;
   //       })
   //    setNameIsUnique(unique)
   // }

   useEffect(() => {
      if (!isSuccessVisible && applicationSent) {
         router("/main");
         window.scrollTo(0, 0);
      }
   }, [isSuccessVisible])

   async function sendToverify() {
      var errorsLocal = []
      var boundary = "Qz9Hq3Iju89";
      var body = new FormData();
      body.append("name", name);
      body.append("email", email);
      body.append("institution", instName);
      body.append("location", location);
      body.append("password", password);
      body.append("idpic", idPic);
      body.append("billpic", billPic);
      var xml = new XMLHttpRequest();
      xml.open("POST", "https://verification-app-nl.onrender.com/application");
      xml.send(body);
      if (receiveNews) {
         var emailTemp = [];
         await getDocs(query(collection(db, "emails"), where("email", "==", email))).then((querySnapshot) => {
            emailTemp = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
         }).finally(async () => {
            if (emailTemp.length == 0) {
               await addDoc(collection(db, "emails"), {
                  email: email
               })
            }
         })
      }
      setApplicationSent(true);
   }

   function clickCatcher(e) {
      var errors = [];
      e.preventDefault();

      if (password != passwordDuplication) {
         errors = [...errors, <div className='flex gap-[10px] text-[#DE3B3B] mt-[20px] text-[16px] sm:text-[20px] font-[Satoshi] font-normal '><img src={cross} alt="" />Passsword duplication isn't match</div>]
         setPasswordError("true")
      }
      if (!privacy) {
         errors = [...errors, <div className='flex gap-[10px] text-[#DE3B3B] mt-[20px] text-[16px] sm:text-[20px] font-[Satoshi] font-normal '><img src={cross} alt="" />Confirm agreement with privacy policy</div>]
      }
      if (!email.match(emailRegExp)) {
         errors = [...errors, <div className='flex gap-[10px] text-[#DE3B3B] mt-[20px] text-[16px] sm:text-[20px] font-[Satoshi] font-normal '><img src={cross} alt="" />Invalid e-mail address</div>]
         setEmailError("true")
      }
      if (!location.match(locationRegExp)) {
         errors = [...errors, <div className='flex gap-[10px] text-[#DE3B3B] mt-[20px] text-[16px] sm:text-[20px] font-[Satoshi] font-normal '><img src={cross} alt="" />Location format should be: "Maribor, Slovenia"</div>]
         setLocationError("true")
      }
      if (!idPic || !billPic) {
         errors = [...errors, <div className='flex gap-[10px] text-[#DE3B3B] mt-[20px] text-[16px] sm:text-[20px] font-[Satoshi] font-normal '><img src={cross} alt="" />Make sure that you uploaded both photos"</div>]
      }
      if ((password == passwordDuplication) && errors.length == 0) {
         sendToverify()
         setIsSuccessVisible(true)
      }
      setErrors(errors)
      // setIsAuth(true);
      // localStorage.setItem('auth', 'true');
   }
   const router = useNavigate();
   function routerLinker() {
      router('/login')
   }
   return (
      <>
         <ContextWindow description="●'◡'●" visibility={isSuccessVisible} title="Congratulations!" setvisibility={setIsSuccessVisible}>
            <div className='felex flex-col items-center justify-center text-start p-[10px] md:p-[15px]'>
               <Subtext className="[&>*]:text-start">Your Nightlife access application has been sent. Keep in mind, <b className='font-bold'>do not</b> create a regular user account with e-mail address which you have used for registration as an owner. <br />  <br /> P.S. Application processing will take some time.</Subtext>
            </div>
         </ContextWindow>
         <Form onSubmit={clickCatcher} className='pb-[100px] [&>*>:nth-child(2)]:hidden ' formTitle='Registration'>

            <FormInputWhite invalid={emailError} onFocus={() => { setEmailError("false") }} value={email} onChange={(e) => { setEmail(e.target.value) }} placeholder='E-mail' required type='email' />
            <FormInputWhite invalid={usernameError} onFocus={() => { setUsernameError("false") }} value={name} onChange={(e) => { setName(e.target.value) }} placeholder='Name and Sername' required type='text' />
            <FormInputWhite invalid={instNameError} onFocus={() => { setInstNameError("false") }} value={instName} onChange={(e) => { setInstName(e.target.value) }} placeholder='Name of the institution' required type='text' />
            <FormInputWhite invalid={locatioError} onFocus={() => { setLocationError("false") }} value={location} onChange={(e) => { setLocation(e.target.value) }} placeholder='Location of the institution' required type='text' />
            <FormInputWhite invalid={passwordError} onFocus={() => { setPasswordError("false") }} value={password} onChange={(e) => { setPassword(e.target.value) }} className=' tracking-[150%]' placeholder='Password' required type='password' />
            <FormInputWhite invalid={passwordError} onFocus={() => { setPasswordError("false") }} value={passwordDuplication} onChange={(e) => { setPasswordDuplication(e.target.value) }} className=' tracking-[150%]' placeholder='Repeat password' required type='password' />
            <div className='mt-[20px] flex flex-col gap-[20px] w-full  w-full sm:w-[400px]'>

               <div className=" flex gap-[15px] items-center  text-[16px] sm:text-[20px] font-[Satoshi] font-medium text-white ">
                  ID photo<span className='font-extralight'>(personal document)</span>:
                  <label htmlFor="file-upload-id" className='flex justify-center items-center gap-[5px] md:gap-[10px]  text-[16px] sm:text-[20px] font-[Satoshi] font-extralight text-yellow hover:underline cursor-pointer'>Upload</label>

                  <input accept=".jpg,.png,.webp" multiple onChange={(e) => {
                     if (e.target.files[0]) {
                        setIdPic(e.target.files[0])
                     }
                  }} className="hidden" id='file-upload-id' name='file-upload-id' type="file" />


               </div>
               {idPic ?
                  <div className='h-[150px]  object-cover flex justify-center '><img className=" h-full rounded-[25px] border border-white overflow-hidden" key={idPic.name} src={URL.createObjectURL(idPic)} />
                  </div>
                  : null}
               <div className=" flex gap-[15px] items-center  text-[16px] sm:text-[20px] font-[Satoshi] font-medium text-white ">
                  Photo of any bill:
                  <label htmlFor="file-upload-bill" className='flex justify-center items-center gap-[5px] md:gap-[10px]  text-[16px] sm:text-[20px] font-[Satoshi] font-extralight text-yellow hover:underline cursor-pointer'>Upload</label>

                  <input accept=".jpg,.png,.webp" multiple onChange={(e) => {
                     if (e.target.files[0]) {
                        setBillPic(e.target.files[0])

                     }
                  }} className="hidden" id='file-upload-bill' name='file-upload-bill' type="file" />

               </div>
               {billPic ?
                  <div className='h-[150px]  object-cover flex justify-center'><img className="rounded-[25px] border border-white  overflow-hidden h-full" key={billPic.name} src={URL.createObjectURL(billPic)} />
                  </div>
                  : null}
               <div className=' text-[16px] sm:text-[20px] font-[Satoshi] font-extralight text-white'><span className='font-medium'>Attention:</span> we should clearly see the name and sername from id and the name of organization on the bill</div>
            </div>
            <AgreePrivacy value={{ privacy, setPrivacy }}></AgreePrivacy>
            <div className='mt-[20px] gap-[10px]  flex  w-full w-full sm:w-[400px] font-[Satoshi] text-[16px] font-light text-white'>
               <Checkbox valueCheck value={{ receiveNews, setReceiveNews }}></Checkbox>
               I want to receive news on email
            </div>
            {errors}

            <Button className='sm:mt-[30px] mt-[20px]'>Sign up</Button>
            <div className='sm:mt-[30px] mt-[20px] font-[Satoshi] text-[16px] font-light text-white'>Already have an account? <span onClick={routerLinker} className='text-yellow hover:underline cursor-pointer'>Log in</span></div>
         </Form>
      </>

   );
};
export default OwnerRegistration;