import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context';
import SignInHeader from '../components/signinheader';
import Form from '../components/UI/form.jsx';
import Container from '../components/container.jsx';
import FormInputWhite from '../components/UI/forminputwhite.jsx';
import Checkbox from '../components/UI/checkbox.jsx';
import Button from '../components/UI/button.jsx';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/footer.jsx';
import { signInWithEmailAndPassword, setPersistence, browserSessionPersistence } from 'firebase/auth';
import { auth } from "../firebase.js"
import cross from "../img/errorcross.svg"

const LoginPage = () => {

   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const [passwordIsWrong, setPasswordIsWrong] = useState("false")
   const [rememberMe, setRememberMe] = useState(false);
   const [errorInputState, setErrorInputState] = useState(false);

   function signIn() {
      if (rememberMe) {
         signInWithEmailAndPassword(auth, email, password)
            .then((userCredantial) => {
            }).catch((error) => {
               setErrorInputState(true)
               setPasswordIsWrong("true")
               setEmail("")
               setPassword("")
               // console.log(error)
            });
      } else {
         setPersistence(auth, browserSessionPersistence)
            .then(() => {
               signInWithEmailAndPassword(auth, email, password)
                  .then((userCredantial) => {
                  }).catch((error) => {
                     setErrorInputState(true)
                     setPasswordIsWrong("true")
                     setEmail("")
                     setPassword("")
                     // console.log(error)
                  });
            })
            .catch((error) => {

               console.log(error)
            });
      }
   }



   function clickCatcher(e) {
      e.preventDefault();
      signIn()

      // setIsAuth(true);
      // localStorage.setItem('auth', 'true');
   }
   const router = useNavigate();
   function routerLinker() {
      router('/registration')
   }
   function toReset() {
      router('/passwordreset')
   }

   return (
      <div className=' relative bg-bgimage bg-cover  bg-no-repeat bg-center after:content-[""] after:absolute after:top-0 after:left-0 after:w-full after:h-full  after:bg-[radial-gradient(115.11%_87.41%_at_50%_100%,rgba(98,50,216,0.00)0%,#6131D7_100%)] after:z-10 after:bg-cover'>
         <div className='relative z-20'>
            <SignInHeader />
            <Container>
               <Form onSubmit={clickCatcher} className='sm:py-[150px] py-[90px]' formTitle='Welcome back'>
                  <FormInputWhite onFocus={() => { setErrorInputState(false); setPasswordIsWrong("false") }} value={email} onChange={(e) => { setEmail(e.target.value) }} placeholder='E-mail' required type='email' />
                  <FormInputWhite invalid={passwordIsWrong} onFocus={() => { setErrorInputState(false); setPasswordIsWrong("false") }} value={password} onChange={(e) => { setPassword(e.target.value) }} className=' tracking-[150%]' placeholder='Password' required type='password' />
                  {
                     errorInputState ? <div className='flex gap-[10px] text-[#DE3B3B] mt-[20px] text-[16px] sm:text-[20px] font-[Satoshi] font-normal '><img src={cross} alt="" />Password or email address is incorrect</div> : <></>
                  }
                  <div className='mt-[20px] flex  justify-between w-full sm:px-[50px] font-[Satoshi] text-[12px] sm:text-[16px] font-light text-white'>
                     <Checkbox valueCheck value={{ rememberMe, setRememberMe }}>Remember me</Checkbox>
                     <p className='leading-[100%] select-none cursor-pointer hover:underline' onClick={toReset}>Forgot password?</p>
                  </div>
                  <Button className='sm:mt-[30px] mt-[20px]'>Log in</Button>

                  <div className='sm:mt-[30px] mt-[20px] font-[Satoshi]  text-[12px] sm:text-[16px] text-center font-light text-white'>You still don't have an account? <span onClick={routerLinker} className='text-yellow hover:underline cursor-pointer'>Registration</span></div>
               </Form>
            </Container>
            <Footer />
         </div>
      </div>
   );
};

export default LoginPage;