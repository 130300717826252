import React from 'react';
import Checkbox from './checkbox';

const AgreePrivacy = ({ ...props }) => {
   return (
      <div className='mt-[20px] flex  w-full gap-[10px] items-start w-full sm:w-[400px] font-[Satoshi] text-[16px] font-light text-white'>
         <Checkbox required valueCheck value={props.value}>
         </Checkbox>
         <p>By clicking Sign up, you acknowledge you have read and agreed to our <a target='_blank' rel='nofollow' href="https://www.termsandconditionsgenerator.com/live.php?token=2vgOhkr6hLgtjz589fMmA5q5nzsZQJvq" className=' hover:underline inline-block font-medium'>Terms and Conditions</a>,  <a target='_blank' className=' hover:underline inline  font-medium' rel='nofollow' href="https://www.privacypolicies.com/live/ed800645-aaa3-4c6e-ba77-b48d2bec7dd7">Cookies policy</a> and <a target='_blank' className=' hover:underline inline  font-medium' rel='nofollow' href="https://www.privacypolicies.com/live/f48885fb-c5ee-4c43-ba7f-70724736865a">Privacy Policy</a>.</p>
      </div>
   );
};

export default AgreePrivacy;