import React from 'react';

const Picture = (props) => {
   return (
      <div className={props.className}>
         <div className=' pointer-events-none rounded-[20px] max-w-[560px] sm:h-[320px] overflow-hidden shadow-[0px_4px_10px_rgba(0,0,0,0.25)]'>
            <img src={props.src} alt="" className='w-full h-full object-cover' />
         </div>
      </div>

   );
};

export default Picture;