import React, { useEffect, useState, useRef } from 'react';

const StatusSwitch = (props) => {
   const [status, setStatus] = useState("offline");
   const handle = useRef();
   const statusText = useRef();
   useEffect(() => {
      if (props.status) {
         handle.current.style.transform = "translate(19px,0)";
         handle.current.style.backgroundColor = "#09B739";
         statusText.current.style.color = "#09B739";
         setStatus("online")
      } else {
         handle.current.style.transform = "translate(0,0)";
         handle.current.style.backgroundColor = "#FB5D3A";
         statusText.current.style.color = "#FB5D3A";
         setStatus("offline");
      }
   }, [props.status])
   function clickHandler() {
      props.setstatus(!props.status)
   }
   return (
      <div className='flex gap-[10px]'>
         <div onClick={clickHandler} className='w-[50px] cursor-pointer [&:hover>*]:drop-shadow-[0px_0px_5px_white] h-[31px] border-2 border-white rounded-full flex items-center overflow-hidden p-[2px]'>
            <div ref={handle} className='  h-[24px] w-[24px] rounded-full bg-orange transition-all duration-300 ease-in-out delay-0'></div>
         </div>
         <p ref={statusText} className='font-[Satoshi] text-[20px] text-orange transition-all duration-300 ease-in-out delay-0'>{status}</p>
      </div>
   );
};

export default StatusSwitch;