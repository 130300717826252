import React, { useEffect, useState } from 'react';
import logo1 from '../../img/NEW LOGO.svg'
import logo2 from '../../img/Logo2.svg'


const Logo = (props) => {
   const [logo, setLogo] = useState(logo1);
   useEffect(() => {
      if (document.body.clientWidth < 1024)
         setLogo(logo2);
      else
         setLogo(logo1)
      window.onresize = () => {
         if (document.body.clientWidth < 1024)
            setLogo(logo2);
         else
            setLogo(logo1)
      }

   })
   return (
      <div {...props}>
         <div className='relative pointer-events-none'>
            <img className="w-full lg:w-[148px] h-8 sm:h-10" src={logo} alt="" />
         </div>
      </div>

   );
};

export default Logo;