import React from 'react';
import ChartCardItem from './chartcarditem';

const ChartScrollItem = (props) => {
   return (
      //[background:linear-gradient(116deg,rgba(255,255,255,0.30)_19%,rgba(255,255,255,0.10)_81.25%)_padding-box,linear-gradient(104deg,rgba(255,255,255,0.56)12.06%,rgba(96,53,205,0.59)94.63%)_border-box]
      <div className='flex flex-col gap-[20px] items-center w-[220px]'>
         <p className='font-[Satoshi] text-[24px] text-white'>{props.date}</p>
         <div className='select-none relative z-10 w-[220px] h-[300px] px-[20px] rounded-[45px] justify-center flex flex-col  bg-[linear-gradient(116deg,rgba(255,255,255,0.30)_19%,rgba(255,255,255,0.10)_81.25%)]

         before:absolute before:bottom-0 before:right-0 before:top-0 before:left-0 before:border-[2px] before:border-transparent before:rounded-[45px] before:[background:linear-gradient(104deg,rgba(255,255,255,0.56)12.06%,rgba(96,53,205,0.59)94.63%)_border-box] before:[-webkit-mask:linear-gradient(#fff_0_0)_padding-box,linear-gradient(#fff_0_0)] before:[-webkit-mask-composite:destination-out] before:[mask-composite:exclude]

       backdrop-blur-[2.5px] shadow-[0px_4px_24px_0px_rgba(0,0,0,0.20)] after:bg-formbg after:content-[""] after:w-full after:h-full after:top-0 after:left-0 after:absolute after:rounded-[45px] after:-z-20 after:opacity-[0.04] '>
            {
               props.data.map((element, index) =>
                  <ChartCardItem key={index} number={index + 1}>{element}</ChartCardItem>)
            }
            {/* <ChartCardItem number="1">K4 Club</ChartCardItem>
            <ChartCardItem number="2">K4 Club</ChartCardItem>
            <ChartCardItem number="3">K4 Club</ChartCardItem>
            <ChartCardItem number="4">K4 Club</ChartCardItem>
            <ChartCardItem number="5">K4 Club</ChartCardItem> */}
         </div>
      </div>
   );
};

export default ChartScrollItem;