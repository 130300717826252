import React, { useEffect, useRef, useState } from 'react';
import Joy from '../../img/joy.svg';
import Vibe from "../../img/good-vibes.svg";
import Service from "../../img/Service.svg";
import Menu from '../../img/poinsettia.svg';
import Money from '../../img/money.svg';

const RateItem = ({ children, ...props }) => {
   var Icons = [
      { id: 1, ico: Joy, name: "Enjoyability" },
      { id: 2, ico: Vibe, name: "Overall vibe" },
      { id: 3, ico: Service, name: "Service quality" },
      { id: 4, ico: Menu, name: "Menu" },
      { id: 5, ico: Money, name: "Price" }
   ];

   // const [inputValue, setInputValue] = useState();

   const input = useRef()


   function changeFunc(e) {
      var value = e.target.value
      if (e.target.value.toString().length > 3)
         value = Number.parseFloat(e.target.value).toFixed(1);

      if (value <= 5)
         props.setvalue(value)
      else
         props.setvalue(5)
   }
   var currentIco;
   var currentName;

   if (parseInt(props.icotype) <= 5 && parseInt(props.icotype) >= 1) {
      currentIco = Icons.filter((e) => {
         return e.id == props.icotype;
      })[0].ico;
      currentName = Icons.filter((e) => {
         return e.id == props.icotype;
      })[0].name;
   }
   return (
      <div className='flex gap-[5px] md:gap-[10px] items-center'>
         <img src={currentIco} className='select-none pointer-events-none sm:h-[40px] sm:w-[40px] h-[35px] w-[35px] md:h-[50px] md:w-[50px]' alt="" />
         <div className=' select-none  flex flex-auto justify-end items-center font-[GeneralSans] text-[20px] md:text-[24px] text-white gap-[5px] md:gap-[10px]'>
            <p className='text-white text-[20px] md:text-[24px]'>:</p>
            <input required value={props.value} onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} onChange={changeFunc} ref={input} type="number" className='[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none w-[80px] h-[40px] md:w-[100px] md:h-[50px] flex border text-center border-white rounded-full bg-[rgba(255,255,255,0.10)] focus:outline-none  text-[20px] md:text-[24px] font-[Satoshi] font-extralight text-yellow placeholder:text-[rgba(255,255,255,0.76)] ' />
            <div className='h-[15px] md:h-[20px] w-[1px]  bg-white rounded-[0.5px] rotate-[20deg] mx-[5px] md:mx-[10px]'></div>
            5
         </div>
      </div >
   );
};

export default RateItem;