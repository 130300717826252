import React, { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../context/index.js'
import Logo from './UI/logo.jsx';
import Container from './container.jsx';
import Slash from '../img/slash.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import CitySearchBar from './citysearchbar.jsx';
import ContextWindow from "../components/contextwindow"
import { auth } from "../firebase.js"
import Subtext from "./UI/subtext.jsx"
import Subtitle from "./UI/subtitle.jsx"
import picTemp from "../img/profileico.svg";

const MainHeader = (props) => {
   const bestBotton = useRef()
   const location = useLocation()
   const [profilePic, setProfilePic] = useState();
   const [threadContextVisibility, setThreadContextVisibility] = useState(false)
   useEffect(() => {

      if (location.pathname == "/chart") {
         bestBotton.current.style.borderBottom = "2px solid white";

      }
      else {
         bestBotton.current.style.borderBottom = "";
      }

   }, [location])
   useEffect(() => {
      setProfilePic(fetchProfilePic())
   }, [])
   function fetchProfilePic() {
      return auth.currentUser.photoURL;
   }

   const router = useNavigate()
   function toProfile() {
      router("/profile", 0)
   }
   function toChart() {
      router('/chart', 0)
   }
   function toMain() {
      router("/main", 0)
   }
   function contextInteraction() {
      setThreadContextVisibility(true);
   }
   return (
      <header >
         <ContextWindow title="Oops!" visibility={threadContextVisibility} setvisibility={setThreadContextVisibility}>
            <div className='felex flex-col items-center justify-center text-center'>
               <Subtext>Thread isn't available right now. We'll add it later. Thanks for your patience!</Subtext>
            </div>
         </ContextWindow>
         <Container>
            <div className='pt-[20px] sm:pt-[40px] select-none flex flex-wrap md:flex-nowrap flex-row items-center justify-center sm:gap-0 gap-[20px] sm:justify-between '>
               <Logo className='order-1 cursor-pointer' onClick={toMain} />
               <div className='flex-auto flex justify-center sm:flex-none order-2 flex gap-[10px] sm:gap-[21px] font-[Satoshi] text-[20px] md:text-[24px] font-normal text-white'>
                  <p ref={bestBotton} onClick={toChart} className=' transition-all duration-200 ease-in-out hover:drop-shadow-[0px_0px_5px_white] cursor-pointer'>Best</p>
                  <img className='pointer-events-none ' src={Slash} alt="" />
                  <p onClick={contextInteraction} className=' cursor-pointer transition-all duration-200 ease-in-out hover:drop-shadow-[0px_0px_5px_white]'>Thread</p>
               </div>
               <CitySearchBar setlocation={props.setlocation} className='order-4 md:order-3 relative z-20' />
               {profilePic
                  ?
                  <div onClick={toProfile} className='order-3 md:order-4 relative w-8 sm:w-10 h-8 sm:h-10 border-2 border-white/70 rounded-full bg-gradient-to-br from-[rgba(255,255,255,0.30)] from-[19%] to-[rgba(255, 255, 255, 0.10)] to-[80%] backdrop-blur-[2.5px] overflow-hidden cursor-pointer transition-all duration-300 ease-in-out cursor-pointer hover:drop-shadow-[0px_0px_5px_white]
              
               '>
                     <img src={profilePic} className='h-full w-full object-cover' alt="" />
                  </div>
                  :
                  <div onClick={toProfile} className='order-3 md:order-4 relative w-8 sm:w-10 h-8 sm:h-10 border-2 border-white/70 rounded-full bg-gradient-to-br from-[rgba(255,255,255,0.30)] from-[19%] to-[rgba(255, 255, 255, 0.10)] to-[80%] backdrop-blur-[2.5px] overflow-hidden cursor-pointer transition-all duration-200 ease-in-out cursor-pointer hover:drop-shadow-[0px_0px_5px_white]
               after:bg-bgperson after:content-[""] after:absolute after:h-full after:w-full after:top-[3px] after:left-0 after:bg-no-repeat after:bg-center 
               '>
                  </div>}
            </div>

         </Container >
      </header >
   );
};

export default MainHeader;