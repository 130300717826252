import React, { useEffect, useState } from "react";
import { BrowserRouter, HashRouter } from "react-router-dom";
import AppRouter from "./components/approuter";
import { AuthContext } from "./context";
import { auth } from "./firebase"
import { onAuthStateChanged } from 'firebase/auth';
import { Helmet } from "react-helmet";
import ico from "./img/favicon.ico";
import ico32 from "./img/ico32.ico";
import ico16 from "./img/ico16.ico";
import icoMain from "./img/mainIco.ico";

function App() {


   const [isAuth, setIsAuth] = useState(false);
   const [isLoading, setIsLoading] = useState(true);
   const [authUser, setAuthUser] = useState(null)

   useEffect(() => {
      setIsLoading(true);
      console.log("loading start")
      const listen = onAuthStateChanged(auth, (user) => {
         if (user) {
            // setAuthUser(user)
            setIsAuth(true);
         }
         else {

            // setAuthUser(null)
            setIsAuth(false);
         }

         console.log("loading stop")
         setIsLoading(false);
      })
      return () => {
         listen()
      }
   }, [])

   // useEffect(() => {
   //    if (localStorage.getItem('auth'))
   //       setIsAuth(true);
   //    setIsLoading(false);
   // }, [])


   return (
      <AuthContext.Provider value={{
         isAuth,
         setIsAuth,
         isLoading,
         // authUser
      }}>
         <Helmet
         >
            <meta charSet="utf-8" />
            <title>Nightlife | Web application for rating institutions</title>
            <link rel="icon" type="image/png" sizes="32x32" href={ico}></link>
            <link rel="canonical" href="http://nightlifesi.com" />
            {/* GENERAL */}
            <meta name="theme-color" content="#6C3FDA" />
            <meta name="application-name" content="Nightlife" />

            <link rel="shortcut icon" href="/favicons/favicon.ico" />
            <link rel="icon" type="image/png" sizes="32x32" href={ico32} />
            <link rel="icon" type="image/png" sizes="16x16" href={ico16} />
            <meta name="description" content="Stay in tune with your city's nightlife scene with our web app! From clubs to restaurants and bars, rate and discover the hottest spots in town. Never miss out on the latest happenings again. Join us to keep your finger on the pulse of your city's nightlife." />
            <meta name="keywords" content="nightlife, city nightlife, clubs, restaurants, bars, ratings, reviews, city events, hotspots, nightlife app, Slovenia nightlife, Slovenian clubs, restaurants Slovenia, bars Slovenia, hotspots, nightlife app Slovenia" />
            {/* Apple */}
            <meta name="apple-mobile-web-app-title" content="Nightlife" />

            <link rel="apple-touch-icon" sizes="180x180" href={icoMain} />
            {/* <link rel="mask-icon" href="/favicons/safari-pinned-tab.svg" color="grey" /> */}
            {/* Microsoft */}
            <meta name="msapplication-TileColor" content="#6C3FDA" />
            {/* Open graph */}
            <meta property="og:title" content="Nightlife | Web application for rating institutions" />
            <meta property="og:description" content="Stay in tune with your city's nightlife scene with our web app! From clubs to restaurants and bars, rate and discover the hottest spots in town. Never miss out on the latest happenings again. Join us to keep your finger on the pulse of your city's nightlife." />
            <meta property="og:image" content={icoMain} />
            <meta property="og:url" content="http://nightlifesi.com" />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:site_name" content="Nightlife" />
            {/* <meta name="msapplication-config" content="/favicons/browserconfig.xml" /> */}
         </Helmet>
         <HashRouter>
            <AppRouter />
         </HashRouter>
      </AuthContext.Provider>

   );
}

export default App;
