import React, { useEffect, useRef } from 'react';

const ContextWindow = ({ children, ...props }) => {

   const menuRef = useRef()
   useEffect(() => {
      if (props.visibility) {
         menuRef.current.style.display = '';
         document.body.style.overflow = 'hidden';

      }
      else {
         menuRef.current.style.display = 'none'
         document.body.style.overflow = 'auto'
         if (props.onclose) props.onclose();
      }
   }, [props.visibility])

   if (props.full) {
      return (
         <div ref={menuRef} onMouseDown={(e) => { if (e.target === menuRef.current) props.setvisibility(false) }} className='fixed top-0 left-0 w-full h-full z-[30] backdrop-blur-md flex items-center'>
            <div className='select-none z-[40] pb-[30px] fixed sm:rounded-[45px] rounded-[35px]  flex flex-col  items-center w-[95vw] sm:w-[900px] bg-gradient-to-br from-[rgba(255,255,255,0.30)] from-19% to-[rgba(255,255,255,0.10)] to-81% overflow-hidden sm:px-[20px] px-[10px] max-sm:max-h-[600px] 
      shadow-[0px_4px_24px_0px_rgba(0,0,0,0.20)] overflow-y-scroll
      border border-[rgba(255,255,255,0.56)]  pt-[20px] sm:pt-[30px] pb-[27px]
      backdrop-blur-[2.5px]  [&::-webkit-scrollbar]:hidden [scrollbar-width:none] [-ms-overflow-style:none] 
      

       after:bg-formbg after:opacity-[0.04] after:-z-10 after:absolute after:top-0 after:left-0    after:w-full after:h-full after:mix-blend-overlay'>
               <h2 className='font-[GeneralSans] text-[24px] sm:text-[36px] text-white font-bold mb-[20px] text-center'>{props.title}</h2>
               {
                  props.description ? <p className='mt-[-20px] font-[GeneralSans] text-[16px] sm:text-[20px] text-white mb-[30px] text-center'>({props.description})</p> : null
               }

               {children}
            </div>
         </div>
      );
   }
   else
      return (
         <div ref={menuRef} onMouseDown={(e) => { if (e.target === menuRef.current) props.setvisibility(false) }} className='fixed top-0 left-0 w-full h-[100vh] z-[30] backdrop-blur-md flex items-center '>
            <div className='select-none z-[40] pb-[30px] fixed sm:rounded-[45px] rounded-[35px]  flex flex-col  items-center w-[95vw] sm:w-[500px] bg-gradient-to-br from-[rgba(255,255,255,0.30)] from-19% to-[rgba(255,255,255,0.10)] to-81% overflow-hidden sm:px-[20px] px-[10px] top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] 
      shadow-[0px_4px_24px_0px_rgba(0,0,0,0.20)]
      border border-[rgba(255,255,255,0.56)]  pt-[20px] sm:pt-[30px] pb-[27px]
      backdrop-blur-[2.5px]

       after:bg-formbg after:opacity-[0.04] after:-z-10 after:absolute after:top-0 after:left-0    after:w-full after:h-full after:mix-blend-overlay'>
               <h2 className='font-[GeneralSans] text-[24px] sm:text-[36px] text-white font-bold mb-[20px] text-center'>{props.title}</h2>
               {
                  props.description ? <p className='mt-[-20px] font-[GeneralSans] text-[16px] sm:text-[20px] text-white mb-[30px] text-center'>({props.description})</p> : null
               }

               {children}
            </div>
         </div>
      );
};

export default ContextWindow;