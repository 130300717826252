import React from 'react';

const Button = ({ children, ...props }) => {
   return (
      <div {...props} className={props.className}>
         <button type='none'
            className='
      text-yellow h-[45px] sm:h-[55px] px-[30px] sm:px-[45px] rounded-full border-[1.5px] border-yellow  shadow-[0_2px_17px_rgba(251,203,58,0.70)]
      font-[Satoshi] text-[20px] sm:text-[24px] font-light transition-all delay-0 duration-150 ease-in-out bg-opacity-50 relative
      
      after:content-[` `] after:absolute after:left-0 after:top-0 after:bg-gradient-to-b after:from-[rgba(255,206,60,0.24)]
      after:to-[rgba(251,203,58,0.64)] after:w-full after:h-full after:rounded-full after:opacity-50 after:transition-all after:delay-0 after:duration-150 after:ease-in-out
      
      hover:after:to-90% hover:after:opacity-70 hover:shadow-[0_2px_25px_rgba(251,203,58,0.90)]
      '
         >
            {children}
         </button>
      </div>

   );
};

export default Button;