import React, { useEffect, useState } from "react";
import Arrow from '../img/ArrowSmallSubmit.svg';
import Arrow2 from '../img/arrowDown2.svg';
import RateItem from "./UI/rateitem";
import Button from "./UI/button";
import { auth, db } from "../firebase";
import { addDoc, average, collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";

export function Rates(props) {
   const [rates, setRates] = useState()
   const [rateJoy, setRateJoy] = useState();
   const [rateVibe, setRateVibe] = useState();
   const [rateService, setRateService] = useState();
   const [rateMenu, setRateMenu] = useState();
   const [ratePrice, setRatePrice] = useState();
   const [viewRates, setViewRates] = useState(false);

   useEffect(() => {
      fetchRate()
   }, [])

   function reFetch() {
      props.refetch(1);
      props.refetch(0);
   }
   async function fetchRate() {
      await getDocs(query(collection(db, "rates"), where("user", "==", auth.currentUser.uid), where("institution", "==", props.id))).then(querySnapshot => {
         const newRate = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
         if (newRate[0]) setRates(newRate[0])
         else setViewRates(true)
      })
   }
   async function rateHandler(e) {
      e.preventDefault()
      if (rates) {
         await updateDoc(doc(db, "rates", rates.id), {
            joy: rateJoy,
            vibe: rateVibe,
            service: rateService,
            menu: rateMenu,
            price: ratePrice,
         }).then(async () => {
            console.log("Has rate: Rate pdated successfully")
            setViewRates(false)
            await getDoc(doc(db, "institutions", props.id)).then(async (result) => {
               var inst = result.data();
               var newJoy;
               var newVibe;
               var newService;
               var newMenu;
               var newPrice;
               var newAverage;
               // console.log(result.data())

               newJoy = ((inst.joy * inst.reviews) - parseFloat(rates.joy) + parseFloat(rateJoy)) / (inst.reviews)
               newVibe = ((inst.vibe * inst.reviews) - parseFloat(rates.vibe) + parseFloat(rateVibe)) / (inst.reviews)
               newService = ((inst.service * inst.reviews) - parseFloat(rates.service) + parseFloat(rateService)) / (inst.reviews)
               newMenu = ((inst.menu * inst.reviews) - parseFloat(rates.menu) + parseFloat(rateMenu)) / (inst.reviews)
               newPrice = ((inst.price * inst.reviews) - parseFloat(rates.price) + parseFloat(ratePrice)) / (inst.reviews)
               newAverage = (newJoy + newVibe + newService + newMenu + newPrice) / 5;
               // newAverage = (((inst.average * inst.reviews) - ((parseFloat(rates.joy) + parseFloat(rates.vibe) + parseFloat(rates.service) + parseFloat(rates.menu) + parseFloat(rates.price)) / 5) + ((newJoy + newVibe + newService + newMenu + newPrice) / 5))) / (inst.reviews)


               await updateDoc(doc(db, "institutions", props.id), {
                  joy: newJoy,
                  vibe: newVibe,
                  service: newService,
                  menu: newMenu,
                  price: newPrice,
                  average: newAverage,
               }).then(() => {
                  console.log("Has rate: Inst. successfully updated")
                  reFetch()
                  fetchRate()
               })
            })
         })
      } else {
         await addDoc(collection(db, "rates"), {
            user: auth.currentUser.uid,
            institution: props.id,
            joy: rateJoy,
            vibe: rateVibe,
            service: rateService,
            menu: rateMenu,
            price: ratePrice,
         }).then(async () => {
            console.log("Successfully rated")
            setViewRates(false)
            await getDoc(doc(db, "institutions", props.id)).then(async (result) => {
               var inst = result.data();
               var newJoy;
               var newVibe;
               var newService;
               var newMenu;
               var newPrice;
               var newAverage;
               // console.log(result.data())
               if (inst.reviews != 0) {
                  newJoy = ((inst.joy * inst.reviews) + parseFloat(rateJoy)) / (inst.reviews + 1)
                  newVibe = ((inst.vibe * inst.reviews) + parseFloat(rateVibe)) / (inst.reviews + 1)
                  newService = ((inst.service * inst.reviews) + parseFloat(rateService)) / (inst.reviews + 1)
                  newMenu = ((inst.menu * inst.reviews) + parseFloat(rateMenu)) / (inst.reviews + 1)
                  newPrice = ((inst.price * inst.reviews) + parseFloat(ratePrice)) / (inst.reviews + 1)
                  newAverage = (newJoy + newVibe + newService + newMenu + newPrice) / 5;
                  // newAverage = (((inst.average * inst.reviews) + ((newJoy + newVibe + newService + newMenu + newPrice) / 5))) / (inst.reviews + 1)

               } else {
                  newJoy = parseFloat(rateJoy);
                  newVibe = parseFloat(rateVibe);
                  newService = parseFloat(rateService);
                  newMenu = parseFloat(rateMenu);
                  newPrice = parseFloat(ratePrice);
                  newAverage = (newJoy + newVibe + newService + newMenu + newPrice) / 5;

               }

               await updateDoc(doc(db, "institutions", props.id), {
                  joy: newJoy,
                  vibe: newVibe,
                  service: newService,
                  menu: newMenu,
                  price: newPrice,
                  average: newAverage,
                  reviews: (inst.reviews + 1)
               }).then(() => {
                  console.log("successfully updated")
                  reFetch()
                  fetchRate()
               })
            })
         })
      }


   }
   return (<>
      {auth.currentUser.emailVerified ?
         viewRates ?
            <form onSubmit={rateHandler} className='flex gap-[30px] md:gap-y-[50px] md:gap-x-[120px] flex-wrap items-center justify-center' >
               <RateItem value={rateJoy} setvalue={setRateJoy} icotype='1' />
               <RateItem value={rateVibe} setvalue={setRateVibe} icotype='2' />
               <RateItem value={rateService} setvalue={setRateService} icotype='3' />
               <RateItem value={rateMenu} setvalue={setRateMenu} icotype='4' />
               <RateItem value={ratePrice} setvalue={setRatePrice} icotype='5' />
               <Button className='md:[&>*]:h-[55px] [&>*]:h-[45px] md:[&>*]:px-[40px] sm:[&>*]:px-[27px] [&>*]:px-[18px]'><div className='flex items-center gap-[10px] text-[18px] md:text-[20px] justify-center'>Confrim rating <img src={Arrow} alt="" className='md:h-full h-[10px]' /></div></Button>
            </form >
            :
            <div onClick={() => {
               setViewRates(true);
               if (rates) {
                  setRateJoy(rates.joy);
                  setRateVibe(rates.vibe);
                  setRateService(rates.service);
                  setRateMenu(rates.menu);
                  setRatePrice(rates.price);
               }

            }} className="mt-[50px] font-[Satoshi] text-[18px] md:text-[24px] text-white hover:underline hover:text-yellow cursor-pointer flex flex-wrap text-center items-center justify-center gap-[5px] md:gap-[10px]">Change yor rate</div>
         :
         <div className="mt-[50px] font-[Satoshi] text-[18px] md:text-[24px] text-white hover:underline cursor-pointer flex flex-wrap text-center items-center justify-center gap-[5px] md:gap-[10px]">To leave a rate you need to verify your email <img className="md:mt-[5px]" src={Arrow2} alt="" /></div>
      }
   </>

   );
}
