import React, { useEffect, useRef } from 'react';

const RegistrationSwitch = (props) => {

   const line = useRef()

   useEffect(() => {
      if (!props.type) {
         line.current.style.transform = "translate(100%,-1px)"
      } else {
         line.current.style.transform = "translate(0px,-1px)"
      }
   }, [props.type])

   return (
      <div className='flex flex-col items-center '>
         <div className=' w-full sm:w-[300px] mt-[50px] mb-[40px]'>
            <div className='flex font-[Satoshi] text-[20px] sm:text-[24px] font-medium text-white'>
               <p onClick={() => { props.settype(true) }} className='w-[50%]  cursor-pointer text-center pb-[10px]'>User</p>
               <p onClick={() => { props.settype(false) }} className='w-[50%] cursor-pointer  text-center pb-[10px]'>Owner</p>
            </div>
            <div className=' w-full h-[1px] rounded-[1px]  bg-white/50 after:content-[""] relative'>
               <div ref={line} className='absolute  left-0 h-[2px] bg-white w-[50%] rounded-[2px]
            translate-y-[-0.5px] transition-all ease-in-out duration-300'></div>
            </div>
         </div>

      </div>
   );
};

export default RegistrationSwitch;