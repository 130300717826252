import React from 'react';

const Container = ({ children, ...props }) => {
   return (
      <div {...props}>
         <div className='mx-0 xl:mx-auto my-0 w-full px-5 xl:px-0 xl:w-[1200px]'>
            {children}
         </div>
      </div>

   );
};

export default Container;