import React from 'react';
import Image from '../../img/testimage.jpg'

const AdminUserListItem = () => {
   return (
      <div className='select-none relative z-10 h-full py-[10px] sm:py-0 sm:h-[100px] pl-[10px] pr-[10px] sm:pr-[30px] border-2 rounded-[50px] border-[rgba(255,255,255,0.56)] flex gap-[20px]
      bg-[linear-gradient(116deg,rgba(255,255,255,0.30)_19%,rgba(255,255,255,0.10)_81.25%)] backdrop-blur-[2.5px] shadow-[0px_4px_24px_0px_rgba(0,0,0,0.20)] after:bg-formbg after:content-[""] after:w-full after:h-full after:top-0 after:left-0 after:absolute after:rounded-[45px] after:-z-20 after:opacity-[0.04] '>
         <div className='flex sm:flex-row flex-col items-center w-full'>
            <div className='relative sm:mr-[30px] w-[80px] h-[80px] rounded-full overflow-hidden  '>
               <img className="w-full h-full object-cover saturate-[0.3]" src={Image} alt="" />
            </div>
            <h1 className='font-{GeneralSans] my-[10px] sm:my-0 flex-auto text-[24px] sm:text-[32px] font-bold text-white'>Maks Goldberg</h1>

            <button className=' mb-[5px] sm:mb-0 sm:mr-[10px] tansition-all duration-100 ease-linear w-full sm:w-[80px] h-[40px] bg-purple rounded-[10px_10px_10px_10px] border border-[#855EE752] font-[GeneralSans] text-[20px] font-medium text-white hover:shadow-[0px_0px_5px_4px_rgba(97,49,215,0.67)]'>
               more
            </button>
            <button className=' tansition-all duration-100 ease-linear w-full sm:w-[80px] h-[40px] bg-purple rounded-[10px_10px_45px_45px] sm:rounded-[10px_10px_10px_10px] border border-[#855EE752] font-[GeneralSans] text-[20px] font-medium text-white hover:shadow-[0px_0px_5px_4px_rgba(97,49,215,0.67)]'>
               block
            </button>
         </div>
      </div>
   );
};

export default AdminUserListItem;