import React from 'react';


const Loader = () => {
   /* HTML: <div class="loader"></div> */

   return <>
      <style>
         {`
.loader {
   width: 45px;
   aspect-ratio: 1;
   --c: no-repeat linear-gradient(#fff 0 0);
   background: 
     var(--c) 0%   50%,
     var(--c) 50%  50%,
     var(--c) 100% 50%;
   background-size: 20% 100%;
   animation: l1 1s infinite linear;
 }
 @keyframes l1 {
   0%  {background-size: 20% 100%,20% 100%,20% 100%}
   33% {background-size: 20% 10% ,20% 100%,20% 100%}
   50% {background-size: 20% 100%,20% 10% ,20% 100%}
   66% {background-size: 20% 100%,20% 100%,20% 10% }
   100%{background-size: 20% 100%,20% 100%,20% 100%}
 }`}
      </style>
      <div className='flex items-center justify-center flex-col h-[300px] sm:h-[600px] gap-[20px]'>
         <div className='loader' />
         <div className='text-white font-[Satoshi] font-medium text-[20px] sm:text-[28px]'>Loading</div>
      </div>

   </>
};

export default Loader;