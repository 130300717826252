import React, { useRef, useEffect } from 'react';

const EditMenu = ({ children, ...props }) => {

   const menuRef = useRef()
   useEffect(() => {
      if (props.visibility) {
         menuRef.current.style.display = '';
         document.body.style.overflow = 'hidden'
      }
      else {
         menuRef.current.style.display = 'none'
         document.body.style.overflow = 'auto'
         props.setreseter(1);
      }
      return () => { props.setreseter(0) }
   }, [props.visibility])


   return (
      <div ref={menuRef} onMouseDown={(e) => { if (e.target === menuRef.current) props.setvisibility(false) }} className='fixed w-full h-full z-[30] backdrop-blur-md top-0'>
         <div className='select-none z-[40] pb-[30px] relative sm:rounded-[45px] rounded-[35px] my-[40px]  sm:mx-auto flex flex-col max-h-[700px]  w-full md:w-[1000px] bg-gradient-to-br from-[rgba(255,255,255,0.30)] from-19% to-[rgba(255,255,255,0.10)] to-81% overflow-hidden sm:px-[40px] px-[10px] 
      shadow-[0px_4px_24px_0px_rgba(0,0,0,0.20)]
      border border-[rgba(255,255,255,0.56)]  pt-[20px] sm:pt-[30px] pb-[27px]
      backdrop-blur-[2.5px]

       after:bg-formbg after:opacity-[0.04] after:-z-10 after:absolute after:top-0 after:left-0    after:w-full after:h-full after:mix-blend-overlay'>
            <h2 className='font-[GeneralSans] text-[24px] sm:text-[36px] text-white font-bold mb-[20px] text-center'>{props.title}</h2>
            {children}
         </div>
      </div>
   );
};

export default EditMenu;