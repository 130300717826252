import { click } from '@testing-library/user-event/dist/click';
import React, { useEffect, useState, componentWillMount } from 'react';

const FilterItem = ({ children, ...props }) => {
   function clickHandler() {
      props.removefunc(props.id)
   }

   switch (props.color) {
      case 'yellow':
         return (
            <div onClick={clickHandler} id='item' className=" select-none text-nowrap inline-block cursor-pointer h-[30px] flex items-center pt-[2px] border border-yellow text-yellow from-yellow/10 to-yellow/30 px-[12px] font-[Satoshi] text-[16px] rounded-full bg-gradient-to-b from-0% to-100% shadow-[0px_2px_17px_rgba(251,203,58,0.32)]">
               {children}
               <div className=' relative ml-[10px] h-[10px] w-[1px] rounded-full bg-yellow rotate-45 after:content-[""] after:absolute  after:rounded-full after:top-0 after:left-0 after:h-[10px] after:w-[1px] after:bg-yellow after:-rotate-90 after:pointer-events-none'></div>
            </div>
         ); break;
      case 'orange':
         return (
            <div onClick={clickHandler} id='item' className=" select-none text-nowrap cursor-pointer h-[30px] flex items-center pt-[2px] border border-orange text-orange from-orange/10 to-orange/30 px-[12px] font-[Satoshi] text-[16px] rounded-full bg-gradient-to-b from-0% to-100% shadow-[0px_2px_17px_rgba(251,93,58,0.32)]">
               {children}
               <div className=' relative ml-[10px] h-[10px] w-[1px] rounded-full bg-orange rotate-45 after:content-[""] after:absolute  after:rounded-full after:top-0 after:left-0 after:h-[10px] after:w-[1px] after:bg-orange after:-rotate-90 after:pointer-events-none'></div>
            </div>
         ); break;
      case 'green':
         return (
            <div onClick={clickHandler} id='item' className=" select-none text-nowrap cursor-pointer h-[30px] flex items-center pt-[2px] border border-green text-green from-green/10 to-green/30 px-[12px] font-[Satoshi] text-[16px] rounded-full bg-gradient-to-b from-0% to-100% shadow-[0px_2px_17px_rgba(9,183,57,0.32)]">
               {children}
               <div className=' relative ml-[10px] h-[10px] w-[1px] rounded-full bg-green rotate-45 after:content-[""] after:absolute  after:rounded-full after:top-0 after:left-0 after:h-[10px] after:w-[1px] after:bg-green after:-rotate-90 after:pointer-events-none'></div>
            </div>
         ); break;
      case 'blue':
         return (
            <div onClick={clickHandler} id='item' className="select-none text-nowrap cursor-pointer h-[30px] flex items-center pt-[2px] border border-blue text-blue from-blue/10 to-blue/30 px-[12px] font-[Satoshi] text-[16px] rounded-full bg-gradient-to-b from-0% to-100% shadow-[0px_2px_17px_rgba(58,147,251,0.32)]">
               {children}
               <div className=' relative ml-[10px] h-[10px] w-[1px] rounded-full bg-blue rotate-45 after:content-[""] after:absolute  after:rounded-full after:top-0 after:left-0 after:h-[10px] after:w-[1px] after:bg-blue after:-rotate-90 after:pointer-events-none'></div>
            </div>
         ); break;
      case 'purple':
         return (
            <div onClick={clickHandler} id='item' className="select-none text-nowrap cursor-pointer h-[30px] flex items-center pt-[2px] border border-lightpurple text-lightpurple from-lightpurple/10 to-lightpurple/30 px-[12px] font-[Satoshi] text-[16px] rounded-full bg-gradient-to-b from-0% to-100% shadow-[0px_2px_17px_rgba(178,58,251,0.32)]">
               {children}
               <div className='select-none relative ml-[10px] h-[10px] w-[1px] rounded-full bg-lightpurple rotate-45 after:content-[""] after:absolute  after:rounded-full after:top-0 after:left-0 after:h-[10px] after:w-[1px] after:bg-lightpurple after:-rotate-90 after:pointer-events-none'></div>
            </div>
         ); break;
   }

};

export default FilterItem;