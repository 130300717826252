import React, { useEffect, useRef, useState } from 'react';
import MainHeader from '../components/mainheader';
import Container from '../components/container';
import ChartTitle from '../components/UI/charttitle';
import Emoji1 from '../img/chartemoji1.svg';
import Emoji2 from '../img/chartemoji2.svg';
import Emoji3 from '../img/chartemoji3.svg';
import Emoji4 from '../img/chartemoji4.svg';
import ChartItem from '../components/UI/chartitem';
import ChartScrollItem from '../components/UI/chartscrollitem';
import FooterMain from '../components/footermain';
import data from '../json/monthlybest'
import dataYearly from '../json/yearlystat';
import { auth, db } from "../firebase";
import { getDocs, getDoc, query, where, collection, orderBy, doc } from 'firebase/firestore';
import SubText from '../components/UI/subtext';

const ChartPage = () => {
   const [allCharts, setAllCharts] = useState([]);
   const [averageChart, setAverageChart] = useState([]);
   const [historyList, setHistoryList] = useState([])
   const [priceChart, setPriceChart] = useState([]);
   const [serviceChart, setServiceChart] = useState([]);
   const [month, setMonth] = useState("");
   const mainScrollbarBody = useRef();
   const mainScrollbarButton = useRef();
   const mainScrollBody = useRef();
   useEffect(() => {
      if (historyList.length > 5) {
         mainScrollBody.current.scrollLeft = 238
         var isDown
         var position
         var position2
         var range = mainScrollbarBody.current.offsetWidth - 90;
         var percentage

         const listChildren = mainScrollBody.current.firstChild.childNodes;

         var listWidth = -25;
         for (var i = 0; i < listChildren.length; i++) {
            listWidth += listChildren[i].offsetWidth + 25;
         }

         // mainScrollBody.current.addEventListener("scroll", (e) => {
         //    percentage = mainScrollBody.current.scrollTop / (listWidth - mainScrollBody.current.offsetWidth) * 100;
         //    position = percentage * range / 100;
         //    mainScrollbarButton.current.style.transform = `translate(-50%,${position}px)`
         // })


         //===============================================================================

         mainScrollbarButton.current.addEventListener("mousedown", () => {
            isDown = 1

         })
         document.addEventListener("mousemove", (e) => {
            if (isDown) {
               position = Math.min(Math.max(e.clientX - 45 - mainScrollbarBody.current.getBoundingClientRect().left, 0), mainScrollbarBody.current.offsetWidth - 90);
               percentage = position / range * 100;
               position = (percentage * range / 100);
               // console.log(mainScrollBody.current.scrollTop)
               // console.log(listWidth - mainScrollBody.current.offsetWidth)
               mainScrollbarButton.current.style.transform = `translate(${position - (mainScrollbarBody.current.offsetWidth / 2) + 10}px,-50%)`
               position2 = (percentage * (listWidth - mainScrollBody.current.offsetWidth) / 100);

               mainScrollBody.current.scrollLeft = position2;
            }
         })
         document.addEventListener("mouseup", () => {
            isDown = 0
         })

         mainScrollBody.current.addEventListener("wheel", (e) => {
            e.preventDefault()
            mainScrollBody.current.scrollLeft += ((e.deltaY) / 3.5);
            position2 = mainScrollBody.current.scrollLeft;
            percentage = 100 * position2 / (listWidth - mainScrollBody.current.offsetWidth)
            position = (percentage * range / 100);
            mainScrollbarButton.current.style.transform = `translate(${position - (mainScrollbarBody.current.offsetWidth / 2) + 10}px,-50%)`
         })
      }
   })
   useEffect(() => {
      fetchRates();

   }, [])
   useEffect(() => {
      fetchHistory();
   }, [allCharts])

   async function fetchRates() {
      var list = []

      await getDocs(collection(db, "charts")).then((querySnapshot) => {
         list = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
         if (list.length > 0) {
            list.sort((a, b) => b.date - a.date)
            setAllCharts(list)
         }
      }).then(async () => {
         if (list.length > 0) {
            var averageChartTemp = [];
            var priceChartTemp = [];
            var serviceChartTemp = [];
            const date = new Date(list[0].date.seconds * 1000);
            setMonth(date.toLocaleString("en-us", { month: "long" }))
            list[0].general_chart.forEach(async (elm) => {
               await getDoc(doc(db, "institutions", elm.id)).then(result => {
                  averageChartTemp = [...averageChartTemp, { name: result.data().name, pics: result.data().pics, average: elm.average, id: elm.id }];
               }).then(() => {
                  averageChartTemp.sort((a, b) => b.average - a.average)
                  if (averageChartTemp.length > 5) averageChartTemp.length = 5;
                  setAverageChart(averageChartTemp);
               })
            })
            list[0].price_chart.forEach(async (elm) => {
               await getDoc(doc(db, "institutions", elm.id)).then(result => {
                  priceChartTemp = [...priceChartTemp, { name: result.data().name, pics: result.data().pics, price: elm.price, id: elm.id }];
               }).then(() => {
                  priceChartTemp.sort((a, b) => b.price - a.price)
                  if (priceChartTemp.length > 5) priceChartTemp.length = 5;
                  setPriceChart(priceChartTemp);
               })
            })
            list[0].service_chart.forEach(async (elm) => {
               await getDoc(doc(db, "institutions", elm.id)).then(result => {
                  serviceChartTemp = [...serviceChartTemp, { name: result.data().name, pics: result.data().pics, service: elm.service, id: elm.id }];
               }).then(() => {
                  serviceChartTemp.sort((a, b) => b.service - a.service)
                  if (serviceChartTemp.length > 5) serviceChartTemp.length = 5;
                  setServiceChart(serviceChartTemp);
               })
            })

         }
      })
   }
   async function fetchHistory() {
      var historyList = [];
      allCharts.map(async (elm) => {
         var currentHistoryListItems = []
         await Promise.all(elm.general_chart.map(async (elm) => {
            await getDoc(doc(db, "institutions", elm.id)).then((result) => {
               currentHistoryListItems = [...currentHistoryListItems, result.data().name]
            })
         })).then(() => {
            const date1 = new Date(elm.date.seconds * 1000);
            historyList = [...historyList, { date: date1.toLocaleString("en-us", { month: 'short', year: 'numeric' }), chart: currentHistoryListItems }]
            setHistoryList(historyList)
         })
      })
   }
   // console.log(historyList)
   return (
      <div className=' relative bg-bgimage bg-cover  bg-no-repeat bg-center after:content-[""] after:absolute after:top-0 after:left-0 after:w-full after:h-full  after:bg-[radial-gradient(115.11%_87.41%_at_50%_100%,rgba(98,50,216,0.00)0%,rgba(97,49,215,0.62)_82.6%,#6131D7_100%)] after:z-10 after:bg-cover'>
         <div className='relative z-20'>
            <MainHeader />
            <Container>
               <div>
                  <ChartTitle className='mt-[30px] sm:mt-[60px]' size="major" image={Emoji1} month={month ? month : "None"}>
                     People's Choice
                  </ChartTitle>
                  <div className='sm:m-0 mx-[-10px] flex flex-col mt-[20px] sm:mt-[40px]  gap-[10px] sm:gap-[15px]'>
                     {averageChart.length > 0 ?
                        averageChart.map((element, index) =>
                           <ChartItem key={index} number={index + 1} data={element} size="major" />
                        ) :
                        <div className='flex items-center justify-center my-[50px] md:m-[70px]'>
                           <SubText>Oops! It'll take time to collect rates.</SubText>
                        </div>

                     }

                  </div>
               </div>
               <div className='flex md:flex-row flex-col'>
                  <div className='flex-auto '>
                     <ChartTitle className='mt-[30px] sm:mt-[80px]' image={Emoji2} month={month ? month : "None"}>
                        Best price
                     </ChartTitle>
                     <div className='sm:m-0 mx-[-10px] flex flex-col gap-[15px] lg:px-[30px] mt-[20px] sm:py-[40px]'>
                        {priceChart.length > 0 ?
                           priceChart.map((element, index) =>
                              <ChartItem key={index} number={index + 1} data={element} rate={element.price} />
                           ) :
                           <div className='flex items-center justify-center my-[50px] md:m-[70px]'>
                              <SubText>Oops! It'll take time to collect rates.</SubText>
                           </div>
                        }
                     </div>
                  </div>
                  <div className='flex-auto'>
                     <ChartTitle className='mt-[30px] md:mt-[350px]' image={Emoji3} month={month ? month : "None"}>
                        Best service
                     </ChartTitle>
                     <div className='sm:m-0 mx-[-10px] flex flex-col gap-[15px] lg:px-[30px]  mt-[20px] sm:pt-[40px]'>
                        {serviceChart.length > 0 ?
                           serviceChart.map((element, index) =>
                              <ChartItem key={index} number={index + 1} data={element} rate={element.service} />
                           ) :
                           <div className='flex items-center justify-center my-[50px] md:m-[70px]'>
                              <SubText>Oops! It'll take time to collect rates.</SubText>
                           </div>
                        }
                     </div>
                  </div>
               </div>
               <div className='relative flex-col sm:flex-row flex mt-[50px] sm:mt-[100px] w-full items-center justify-center'>
                  <div className='flex items-center gap-[10px] font-[GeneralSans] text-[28px] sm:text-[52px] font-bold text-white'>Chart history<img src={Emoji4} className='sm:h-auto h-[30px]' alt="" /></div>
                  {/* <DropDown className='sm:absolute mt-[20px] sm:mt-0 top-1/2 -translate-y-1/2 right-0' title='Sorting' /> */}
               </div>
               <div ref={mainScrollBody} className='sm:mb-0 mb-[50px] mt-[20px] sm:mt-[40px] overflow-x-scroll [&::-webkit-scrollbar]:hidden [scrollbar-width:none] [-ms-overflow-style:none]  [-webkit-mask-image:linear-gradient(to_right,rgba(0,0,0,0),rgba(0,0,0,1)_4%,rgba(0,0,0,1)_96%,rgba(0,0,0,0)_100%)]'>
                  <div className={historyList.length > 5 ? 'flex gap-[25px]  px-[40px] -mx-[40px]' : 'flex gap-[25px] justify-center px-[40px] -mx-[40px]'}>
                     {
                        // historyList.length != 0 ?
                        //    historyList.map((elm, index) => {
                        //       <ChartScrollItem key={index} date={elm.date} data={elm.chart} />
                        //    }) : null
                        historyList ?
                           historyList.length > 0 ?
                              historyList.map((element, index) =>
                                 <ChartScrollItem key={index} date={element.date} data={element.chart} />

                              ) :
                              <div className='flex items-center justify-center my-[50px] md:m-[70px]'>
                                 <SubText>Oops! It'll take time to collect rates.</SubText>
                              </div> : null
                     }
                  </div>
               </div>
               <div className='hidden sm:block select-none relative mx-[100px] pt-[40px] pb-[200px]'>
                  <div ref={mainScrollbarBody} className='relative   h-[1px] rounded-[0.5px] bg-[linear-gradient(104deg,rgba(255,255,255,0.56)_12.06%,rgba(96,53,205,0.59)_94.63%)]'></div>
                  <div ref={mainScrollbarButton} className='absolute top-[40px] left-1/2 -translate-y-1/2 -translate-x-1/2 w-[70px] h-[9px] bg-white rounded-[5px]'></div>
               </div>
            </Container>
            <FooterMain />
         </div>

      </div>
   );
};

export default ChartPage;