import React from "react";
export function Sortdrop({
   DropDownBack2,
   changeSortType
}) {
   return <div ref={DropDownBack2} style={{
      transform: "translate(0,-50px)"
   }} className='relative lg:mr-[50px]  h-0 opacity-0 transition ease-linear duration-100 pointer-events-none '>
      <div className='select-none cursor-pointer text-right font-[Satoshi] text-[18px] text-white flex flex-col items-center lg:items-end gap-[2px] [&>*:hover]:transition-all [&>*:hover]:ease-linear [&>*:hover]:duration-100 [&>*:hover]:drop-shadow-[0px_0px_5px_#fff] '>
         <p type="" onClick={e => changeSortType(e.target.getAttribute("type"))}>Overall best rating</p>
         <p type="joy" onClick={e => changeSortType(e.target.getAttribute("type"))}>Best “Joy” rating</p>
         <p type="vibe" onClick={e => changeSortType(e.target.getAttribute("type"))}>Best “Vibe” rating</p>
         <p type="service" onClick={e => changeSortType(e.target.getAttribute("type"))}>Best “Service” rating</p>
         <p type="price" onClick={e => changeSortType(e.target.getAttribute("type"))}>Best “Price” rating</p>
      </div>
   </div>;
}
