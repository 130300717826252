import WelcomePage from "../pages/welcomepage";
import MainPage from "../pages/mainpage";
import LoginPage from "../pages/loginpage";
import RegistrationPage from "../pages/registrationpage";
import ChartPage from "../pages/chartpage";
import ProfilePage from "../pages/profilepage";
import IdPage from "../pages/idpage";
import Admin from "../pages/admin";
import PasswordReset from "../pages/passwordreset";
import Owner from "../pages/owner";

export const privatRoutes = [
   { path: '/main', component: <MainPage />, exact: true },
   { path: '/chart', component: <ChartPage />, exact: true },
   { path: '/profile', component: <ProfilePage />, exact: true },
   { path: '/main/:id', component: <IdPage />, exact: true },
]
export const publicRoutes = [
   { path: '/welcome', component: <WelcomePage />, exact: true },
   { path: '/login', component: <LoginPage />, exact: true },
   { path: '/registration', component: <RegistrationPage />, exact: true },
   { path: '/passwordreset', component: <PasswordReset />, exact: true },
]
export const adminRoutes = [
   { path: '/admin', component: <Admin />, exact: true }
]
export const ownerRoutes = [
   { path: '/owner', component: <Owner />, exact: true }
]