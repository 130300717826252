import React from 'react';
import Star from '../../img/star.svg'

const StarsInItmes = (props) => {
   const stars = [];
   for (var i = 1; i <= Math.floor(props.generalrate); i++) {
      stars.push(
         < img src={Star} alt="" key={i} />
      )
   }
   return stars;
};

export default StarsInItmes;