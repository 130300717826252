import React, { useEffect, useState } from 'react';
import RateType from './ratetype';
import { useNavigate } from 'react-router-dom';
import { auth, db } from "../../firebase";
import { getDoc, doc } from "firebase/firestore"

const RateScrollItem = (props) => {
   const [inst, setInst] = useState()
   useEffect(() => {
      fetchInst()
   }, [])
   async function fetchInst() {
      await getDoc(doc(db, "institutions", props.data.institution)).then(result => {
         setInst(result.data())
      })
   }

   const router = useNavigate();
   function toId() {
      // router(`/main`, 0)
      router(`/main/${props.data.institution}`, 0)
      window.scrollTo(0, 0)
   }
   return (
      <div className='select-none relative z-10 h-full py-[10px] md:py-0 md:h-[70px] px-[10px] md:px-[50px] border-2 rounded-[45px] border-[rgba(255,255,255,0.56)] flex gap-[20px]
      bg-[linear-gradient(116deg,rgba(255,255,255,0.30)_19%,rgba(255,255,255,0.10)_81.25%)] backdrop-blur-[2.5px] shadow-[0px_4px_24px_0px_rgba(0,0,0,0.20)] after:bg-formbg after:content-[""] after:w-full after:h-full after:top-0 after:left-0 after:absolute after:rounded-[45px] after:-z-20 after:opacity-[0.04] '>
         <div className='flex flex-col gap-[20px] md:gap-0 md:flex-row items-center w-full'>
            <h1 className='flex-auto font-{GeneralSans] text-[24px] md:text-[30px] font-medium text-white'>{inst ? inst.name : null}</h1>
            <div className='flex justify-center flex-wrap md:flex-nowrap gap-[15px] md:gap-[30px]'>
               <RateType icotype='1' >{props.data.joy}</RateType>
               <RateType icotype='2' >{props.data.vibe}</RateType>
               <RateType icotype='3' >{props.data.service}</RateType>
               <RateType icotype='4' >{props.data.menu}</RateType>
               <RateType icotype='5' >{props.data.price}</RateType>
            </div>
            <button onClick={toId} className='md:ml-[100px] tansition-all duration-100 ease-linear w-full md:w-[90px] h-[40px] bg-purple rounded-[15px_15px_40px_40px] md:rounded-[10px_10px_10px_10px] border border-[#855EE752] font-[GeneralSans] text-[20px] font-medium text-white hover:shadow-[0px_0px_5px_4px_rgba(97,49,215,0.67)]'>
               re-rate
            </button>
         </div>
      </div>
   );
};

export default RateScrollItem;