import React, { useEffect, useState, useRef } from 'react';
import { auth } from "../firebase.js";
import { db } from "../firebase.js"
import { doc, getDocs, collection, getDoc } from "firebase/firestore";

const CitySearchBar = (props) => {
   const list = useRef()
   const mainBody = useRef()
   const [locationsData, setLocationsData] = useState();
   const [locationsOriginalList, setLocationsOriginalList] = useState();
   const [locationsList, setLocationsList] = useState([]);
   const [originalLocation, setOriginalLocation] = useState()
   const [currentLocation, setCurrentLocation] = useState("");
   useEffect(() => {
      if (locationsOriginalList && props.setlocation) {
         var nameList = [];
         locationsOriginalList.map(element => {
            nameList = [...nameList, element.key]
         })
         if (nameList.includes(currentLocation)) props.setlocation(currentLocation);
      }
   }, [currentLocation])
   useEffect(() => {
      fetchLocations();
      fetchLocation()
   }, [])
   useEffect(() => {
      var locationsListTemp = [];
      if (locationsData)
         locationsData.forEach(element => {
            locationsListTemp = [...locationsListTemp, <div key={element} className=' hover:underline cursor-pointer' onClick={locationChange} >{element}</div>]
         })

      setLocationsOriginalList(locationsListTemp)
      setLocationsList(locationsListTemp)
   }, [locationsData])
   useEffect(() => {
      if (locationsOriginalList) {
         var listTemp = locationsOriginalList.filter(location =>
            location.key.toLowerCase().includes(currentLocation.toLowerCase())
         )
         setLocationsList(listTemp)
      }

   }, [currentLocation])
   async function fetchLocations() {
      await getDocs(collection(db, "locations")).then((querySnapshot) => {
         var locationSet = new Set();
         const locationsData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
         locationsData.map(element => locationSet.add(element.location))
         setLocationsData(locationSet);
      })
      // await getDocs(query(collection(db, "institutions"), where("location", "!=", ""))).then((querySnapshot) => {
      //    var locationSet = new Set();
      //    const locationsData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      //    locationsData.map(element => locationSet.add(element.location))
      //    setLocationsData(locationSet);
      // })
   }
   async function fetchLocation() {
      const location = await getDoc(doc(db, "userData", auth.currentUser.uid.toString()));
      if (location.data() != undefined) {
         setOriginalLocation(location.data().location)
         setCurrentLocation(location.data().location);
         if (location.data().location == "" && props.setcontextvisibility) {
            props.setcontextvisibility(true)
         }
      }
      if (props.setlocation && location.data() && location.data().location) {
         props.setlocation(location.data().location)
      };

   }

   async function locationChange(e) {

      setCurrentLocation(e.target.innerHTML)
      list.current.style.display = "none"
      // await setDoc(doc(db, "userData", auth.currentUser.uid), {
      //    location: e.target.innerHTML,
      // });
   }
   function inputFocus(e) {
      if (e.target.value) setCurrentLocation("")
      list.current.style.display = "block"
      if (props.context) mainBody.current.style.height = "150px"
   }
   function inputBlur(e) {

      setTimeout(() => {
         if (list.current)
            list.current.style.display = "none"
         // if (!locationsOriginalList.includes(currentLocation)) {
         if (props.context) mainBody.current.style.height = "40px"
         //    setCurrentLocation(originalLocation)
         // }
      }, 150)
   }

   // function inputBlur(e) {
   //    list.current.style.display = "none"
   // }
   if (props.context) return (
      <div ref={mainBody} style={{ transition: "all 0.3s ease-in-out 0s" }} className={props.className}>

         <div className='relative before:content-[""] before:absolute before:bg-bglocation
            before:top-1/2 before:left-[12px] before:w-[20px] before:h-[20px] before:z-20 before:-translate-y-1/2 before:pointer-events-none before:select-none
            
            after:content-[""] after:absolute after:bg-bgsearch
            after:top-1/2 after:right-[13px] after:w-[20px] after:h-[20px] after:z-20 after:-translate-y-1/2 after:cursor-pointer after:select-none 
            
            '>
            <input value={currentLocation} onChange={(e) => { setCurrentLocation(e.target.value) }} onFocus={inputFocus} onBlur={inputBlur} type="text" placeholder={"Pick location"} className=' bg-transparent bg-gradient-to-br from-[rgba(255,255,255,0.30)] from-[19%] to-[rgba(255,255,255,0.10)] to-[81%] rounded-full w-full sm:w-[300px] h-[40px] border border-[rgba(255,255,255,0.70)] shadow-[0px_0px_24px_0px_rgba(0,0,0,0.20)] focus:outline-none px-[40px] text-white 
               font-[Satoshi] text-[18px] sm:text-[20px] placeholder:text-white/70 relative
            
            ' />
         </div>
         <div ref={list} className='hidden py-[10px] overflow-y-scroll absolute  top-0 left-0 translate-y-[50px]
      bg-transparent bg-gradient-to-br from-[rgba(255,255,255,0.30)] from-[19%] to-[rgba(255,255,255,0.10)] to-[81%] rounded-[20px] w-full sm:w-[300px] max-h-[120px] border border-[rgba(255,255,255,0.70)] shadow-[0px_0px_24px_0px_rgba(0,0,0,0.20)] focus:outline-none px-[40px] text-white 
      font-[Satoshi] text-[18px] sm:text-[20px] placeholder:text-white backdrop-blur-[2.5px]
      [&::-webkit-scrollbar]:w-0 [&>*]:z-[41]
      '>
            {locationsList.length == 0 ? "Not found :(" : locationsList}
            {/* [&::-webkit-scrollbar]:w-0 */}
         </div>
      </div>);
   else
      return (
         <div className={props.className}>

            <div className='relative before:content-[""] before:absolute before:bg-bglocation
               before:top-1/2 before:left-[12px] before:w-[20px] before:h-[20px] before:z-20 before:-translate-y-1/2 before:pointer-events-none before:select-none
               
               after:content-[""] after:absolute after:bg-bgsearch
               after:top-1/2 after:right-[13px] after:w-[20px] after:h-[20px] after:z-20 after:-translate-y-1/2 after:cursor-pointer after:select-none 
               
               '>
               <input value={currentLocation} onChange={(e) => { setCurrentLocation(e.target.value) }} onFocus={inputFocus} onBlur={inputBlur} type="text" placeholder={"Pick location"} className=' bg-transparent bg-gradient-to-br from-[rgba(255,255,255,0.30)] from-[19%] to-[rgba(255,255,255,0.10)] to-[81%] rounded-full w-full sm:w-[300px] h-[40px] border border-[rgba(255,255,255,0.70)] shadow-[0px_0px_24px_0px_rgba(0,0,0,0.20)] focus:outline-none px-[40px] text-white 
                  font-[Satoshi] text-[18px] sm:text-[20px] placeholder:text-white/70 relative
               
               ' />
            </div>
            <div ref={list} className='hidden py-[10px] overflow-y-scroll absolute  top-0 left-0 translate-y-[50px]
         bg-transparent bg-gradient-to-br from-[rgba(255,255,255,0.30)] from-[19%] to-[rgba(255,255,255,0.10)] to-[81%] rounded-[20px] w-full sm:w-[300px] max-h-[120px] border border-[rgba(255,255,255,0.70)] shadow-[0px_0px_24px_0px_rgba(0,0,0,0.20)] focus:outline-none px-[40px] text-white 
         font-[Satoshi] text-[18px] sm:text-[20px] placeholder:text-white backdrop-blur-[2.5px]
         [&::-webkit-scrollbar]:w-0 [&>*]:z-[41]
         '>
               {locationsList.length == 0 ? "Not found :(" : locationsList}
               {/* [&::-webkit-scrollbar]:w-0 */}
            </div>
         </div>

      );
};

export default CitySearchBar;