import React, { useEffect, useRef, useState } from 'react';
import RateScrollItem from './UI/ratescrollitem';
import data from '../json/profilerates.js';
import { auth, db } from "../firebase";
import { getDocs, doc, collection, query, where } from "firebase/firestore"

const RateScroll = () => {

   const mainScrollbarBody = useRef();
   const mainScrollbarButton = useRef();
   const mainScrollBody = useRef();
   const [ratesList, setRatesList] = useState([])



   useEffect(() => {
      fetchRates();
   }, [])

   useEffect(() => {
      var isDown
      var position
      var position2
      var range = mainScrollbarBody.current.offsetHeight - 90;
      var percentage

      const listChildren = mainScrollBody.current.childNodes;
      var listHeight = 65;
      for (var i = 0; i < listChildren.length; i++) {
         listHeight += listChildren[i].offsetHeight + 15;
      }
      mainScrollBody.current.addEventListener("scroll", (e) => {
         percentage = mainScrollBody.current.scrollTop / (listHeight - mainScrollBody.current.offsetHeight) * 100;
         position = percentage * range / 100;
         mainScrollbarButton.current.style.transform = `translate(-50%,${position}px)`
      })


      //===============================================================================

      mainScrollbarButton.current.addEventListener("mousedown", () => {
         isDown = 1
      })
      document.addEventListener("mousemove", (e) => {
         if (isDown) {
            position = Math.min(Math.max(e.clientY - mainScrollbarBody.current.getBoundingClientRect().top - 40, 0), mainScrollbarBody.current.offsetHeight - 90);
            percentage = position / range * 100;
            position = percentage * range / 100;
            // console.log(mainScrollBody.current.scrollTop)
            // console.log(listHeight - mainScrollBody.current.offsetHeight)
            mainScrollbarButton.current.style.transform = `translate(-50%,${position}px)`
            position2 = percentage * (listHeight - mainScrollBody.current.offsetHeight) / 100;
            mainScrollBody.current.scrollTop = position2;
         }
      })
      document.addEventListener("mouseup", () => {
         isDown = 0
      })
   })

   async function fetchRates() {
      var list = []
      await getDocs(query(collection(db, "rates"), where("user", "==", auth.currentUser.uid))).then((querySnapshot) => {
         list = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
         setRatesList(list)
      })
      // setRatesList(result);
   }

   return (
      <div className='sm:m-0 mx-[-10px] flex gap-[30px] pb-[50px]'>
         <div ref={mainScrollBody} className='h-[520px] w-full overflow-y-scroll overflow-hidden [&::-webkit-scrollbar]:hidden [scrollbar-width:none] [-ms-overflow-style:none] [-webkit-mask-image:linear-gradient(to_bottom,rgba(0,0,0,0),rgba(0,0,0,1)_10%,rgba(0,0,0,1)_90%,rgba(0,0,0,0)_100%)]'>
            <div className='w-full flex flex-col gap-[10px] md:gap-[20px] mt-[40px] mb-[40px]
         '>
               {
                  ratesList.map((element, index) =>
                     <RateScrollItem data={element} key={index} />
                  )
               }
            </div>
         </div>

         <div className='hidden md:block relative max-h-[520px]'>

            <div ref={mainScrollbarBody} draggable="0" className=' h-full relative bg-[rgba(158,158,158,0.50)] rounded-[1px] w-[2px]  shadow-[0px_0px_10px_#000] 
                     '>
            </div>
            <div ref={mainScrollbarButton} draggable="0" prevpercentage="0" percentage="0" mousedownat="0" className='select-none absolute top-[10px] left-1/2 w-[9px] h-[70px] rounded-[12px] bg-[#6131D7] -translate-x-1/2'>

            </div>
         </div>
      </div>
   );
};

export default RateScroll;