import React, { useContext, useEffect, useState } from 'react';
import SignInHeader from '../components/signinheader.jsx';
import Container from '../components/container.jsx';
import Footer from '../components/footer.jsx';
import UserRegistration from '../components/userregistration.jsx';
import RegistrationSwitch from '../components/UI/registrationswitch.jsx';
import OwnerRegistration from '../components/ownerregistration.jsx';

const RegistrationPage = () => {
   const [regTypeIsUser, setRegTypeIsUser] = useState(true);
   return (
      <div className=' relative bg-bgimage bg-cover  bg-no-repeat bg-center after:content-[""] after:absolute after:top-0 after:left-0 after:w-full after:h-full  after:bg-[radial-gradient(115.11%_87.41%_at_50%_100%,rgba(98,50,216,0.00)0%,#6131D7_100%)] after:z-10 after:bg-cover'>
         <div className='relative z-20'>
            <SignInHeader />
            <Container>
               <RegistrationSwitch type={regTypeIsUser} settype={setRegTypeIsUser} />
               {regTypeIsUser ?
                  <UserRegistration></UserRegistration>
                  :
                  <OwnerRegistration></OwnerRegistration>
               }

            </Container>
            <Footer />
         </div>
      </div>
   );
};

export default RegistrationPage;