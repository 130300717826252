import React, { useEffect, useState } from 'react';
import AdminHeader from '../components/adminheader';
import Footer from '../components/footer';
import Container from '../components/container';
import Subtext2 from '../components/UI/subtext2';
import SubText from '../components/UI/subtext';
import OwnerEditLine from '../components/UI/ownereditline';
import Facebook from "../img/facebook.svg";
import Instagram from "../img/inst.svg";
import Web from "../img/web.svg";
import Twitter from "../img/twitter.svg";
import Email from "../img/email.svg";
import EditIco from '../img/edityellow.svg';
import StatusSwitch from '../components/UI/statusswitch';
import { sendEmailVerification } from "firebase/auth"
import { getDoc, addDoc, getDocs, doc, collection, where, query, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, uploadBytesResumable, getDownloadURL, list, deleteObject } from "firebase/storage";
import { auth, db, storage } from '../firebase';
import Loader from "../components/UI/loader";
import ContextWindow from '../components/contextwindow';
import Button from '../components/UI/button';
import FormInputWhite from '../components/UI/forminputwhite';
import FilterBarAdm from '../components/UI/filterbaradm';
import Subtitle from '../components/UI/subtitle';

const Owner = () => {
   const [status, setStatus] = useState(false);
   const [institution, setInstitution] = useState();
   const [isLoading, setIsLoading] = useState(true);
   const [changePhotoContextVisibility, setChangePhotoContextVisibility] = useState(false);
   const [changeAddressContextVisibility, setChangeAddressContextVisibility] = useState(false);
   const [changeDescriptionContextVisibility, setChangeDescriptionContextVisibility] = useState(false);
   const [changeLinkContextVisibility, setChangeLinkContextVisibility] = useState(false);
   const [changeFiltersContextVisibility, setChangeFiltersContextVisibility] = useState(false);
   const [warningContextVisibility, setWarningContextVisibility] = useState(false);

   const [isNewFilters, setIsNewFilters] = useState(false);
   const [filtersArr, setFiltersArr] = useState([]);
   const [nextDayIndex, setNextDayIndex] = useState();
   const [instTags, setInstTags] = useState([]);
   const [instSchedule, setInstSchedule] = useState([]);

   const [institutionPics, setInstitutionPics] = useState([]);
   const [address, setAddress] = useState("");
   const [gmapLink, setGmapLink] = useState("");
   const [description, setDescription] = useState("");
   const [invalidGmapLink, setInvalidGmapLink] = useState("false");
   const [currentLinkType, setCurrentLinkType] = useState("");
   const [currentLink, setCurrentLink] = useState("");
   const [filterIdCounter, setFilterIdCounter] = useState(0);



   useEffect(() => {
      fetchInstitution();
   }, [])

   useEffect(() => {
      if (institution)
         if (status && institution.description != "" && institution.address != "" && institution.pics.length != 0 && auth.currentUser.emailVerified) {
            setStatusTrue();
            setLocationToCollection();
         } else if (status) {
            setStatus(false);
            setStatusFalse();
            setWarningContextVisibility(true)
         } else {
            setStatus(false);
            setStatusFalse();
         }
   }, [status])

   async function setLocationToCollection() {
      var list
      await getDocs(query(collection(db, "locations"), where("location", "==", institution.location))).then((querySnapshot) => {
         list = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      })
      if (list.length == 0) {
         await addDoc(collection(db, "locations"), {
            location: institution.location
         })
      }
   }
   async function setStatusFalse() {
      await updateDoc(doc(db, "institutions", institution.id), {
         active: false,
      })
   }
   async function setStatusTrue() {
      await updateDoc(doc(db, "institutions", institution.id), {
         active: true,
      })
   }

   async function fetchInstitution() {
      var list
      await getDocs(query(collection(db, "institutions"), where("ownerid", "==", auth.currentUser.uid))).then((querySnapshot) => {
         list = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      })
      setInstitution(list[0])
      setIsLoading(false)
   }

   function sendMail() {
      sendEmailVerification(auth.currentUser)
      alert("Verification e-mail was sent to " + auth.currentUser.email)
   }
   async function changeInstitutionPic() {
      if (institutionPics.length != 0) {
         var instPicsLinksTemp = []
         var linksPicsCounter = 0;
         await list(ref(storage, `/institutions/${institution.id}/pics/`)).then((result) => {
            result.items.forEach(async element => {
               await deleteObject(ref(storage, element.fullPath))
            })
         }).then(async () => {
            institutionPics.forEach(async (element) => {
               await uploadBytesResumable(ref(storage, `/institutions/${institution.id}/pics/${element.name}`), element).then(async (elm) => {
                  var pathName = elm.metadata.name;
                  await getDownloadURL(ref(storage, `/institutions/${institution.id}/pics/${pathName}`)).then(async (result) => {
                     instPicsLinksTemp = [...instPicsLinksTemp, result]
                     linksPicsCounter++;
                  })
               })
               if (institutionPics.length == linksPicsCounter) await updateDoc(doc(db, "institutions", institution.id), {
                  pics: instPicsLinksTemp,
               })
            })
         }).then(() => {
            setChangePhotoContextVisibility(false)
            setTimeout(() => fetchInstitution(), 1000)


         })

      }


   }
   async function changeAddress(e) {
      e.preventDefault();
      if ((gmapRegExpFirst.test(gmapLink) || gmapRegExpSecond.test(gmapLink)) || gmapLink == "") {
         await updateDoc(doc(db, "institutions", institution.id), {
            address: address,
            gmap: gmapLink
         }).then(() => {
            setChangeAddressContextVisibility(false)
            setTimeout(() => fetchInstitution(), 1000)
         })
      } else {
         alert("Invalid G-map link");
         setInvalidGmapLink("true");
      }
   }
   async function changeDescription(e) {
      e.preventDefault()
      updateDoc(doc(db, "institutions", institution.id), {
         description: description
      }).then(() => {
         setChangeDescriptionContextVisibility(false);
         setTimeout(() => fetchInstitution(), 1000)
      })
   }
   async function changeLink(e) {
      e.preventDefault();
      updateDoc(doc(db, "institutions", institution.id), {
         [currentLinkType]: currentLink
      }).then(() => {
         setChangeLinkContextVisibility(false);
         setTimeout(() => fetchInstitution(), 1000);
      })
   }

   function filterBarInteraction(color, content, tag, value) {
      if (value) setIsNewFilters(true)
      var currentValue = filtersArr;
      if (value && currentValue.filter(elm => elm.tag == tag).length > 0) {
         currentValue = currentValue.filter(elm => elm.tag != tag)
      }
      if (value) {
         currentValue = [...currentValue, { id: filterIdCounter, color: color, content: content, tag: tag }]
         setFilterIdCounter(filterIdCounter + 1)
      } else {
         currentValue = currentValue.filter(elm => elm.tag != tag)
         // console.log(filtersArr.filter(elm => elm.content != content))
      }
      setFiltersArr(currentValue)
   }
   async function changeTagsNSchedule() {

      var tags = [];
      var schedule = [];
      filtersArr.map(element => {
         if (element.tag.toLowerCase() == "mon" || element.tag.toLowerCase() == "tues" || element.tag.toLowerCase() == "weds" || element.tag.toLowerCase() == "thurs" || element.tag.toLowerCase() == "fri" || element.tag.toLowerCase() == "sat" || element.tag.toLowerCase() == "sun") {
            // days = [...days, element.tag.toLowerCase()];
            schedule = [...schedule, { day: element.tag.toLowerCase(), time: element.content.replaceAll(" ", "") }]
         }
         else
            tags = [...tags, element.tag.toLowerCase()]
      })
      await updateDoc(doc(db, "institutions", institution.id), {
         tags: tags,
         schedule: schedule
      }).then(() => {
         setChangeFiltersContextVisibility(false);
         setTimeout(() => fetchInstitution(), 1000)
      })
   }

   var gmapRegExpFirst = /^https:\/\/www\.google\.com\/maps\/place\/.*$/;
   var gmapRegExpSecond = /^https:\/\/maps\.app\.goo\.gl\/.*$/;


   return (
      <div className=' relative bg-bgimage bg-cover  bg-no-repeat bg-center after:content-[""] after:absolute after:top-0 after:left-0 after:w-full after:h-full  after:bg-[radial-gradient(115.11%_87.41%_at_50%_100%,rgba(98,50,216,0.00)0%,#6131D7_100%)] after:z-10 after:bg-cover'>
         <div className='relative z-20'>
            <AdminHeader />
            {!isLoading
               ?
               <Container>
                  <ContextWindow onclose={() => { setInstitutionPics([]) }} description="The number of photos is limited to 5" title="Institution picture" visibility={changePhotoContextVisibility} setvisibility={setChangePhotoContextVisibility}>
                     <div className='flex items-center gap-[15px] flex-wrap justify-center'>
                        <label htmlFor="institution-pic" className='flex justify-center items-center gap-[5px] md:gap-[10px] font-[Satoshi] text-[18px] md:text-[24px] text-white hover:underline cursor-pointer'>Upload</label>
                        {/* <p className=' text-nowrap font-[Satoshi] text-[18px] md:text-[24px] text-white font-light'>{institutionPics ? institutionPics.name.slice(institutionPics.name.lastIndexOf("\\") + 1) : "No image"}</p> */}
                        <input multiple="5" accept=".jpg,.png,.webp" onChange={(e) => {
                           if (e.target.files.length > 5) {
                              alert("The number of photos is limited to 5")
                           }
                           else
                              if (e.target.files)
                                 setInstitutionPics(Array.from(e.target.files))
                        }} className="hidden" id='institution-pic' type="file" />
                     </div>
                     <div className='flex flex-col max-h-[300px] gap-[20px] [&>*]:rounded-[15px] mt-[20px] overflow-y-scroll pb-[10px] [&::-webkit-scrollbar]:hidden [scrollbar-width:none] [-ms-overflow-style:none] 
                     [-webkit-mask-image:linear-gradient(to_bottom,rgba(0,0,0,0),rgba(0,0,0,1)_10%,rgba(0,0,0,1)_90%,rgba(0,0,0,0)_100%)] py-[20px]'>
                        {institutionPics.length != 0
                           ?
                           institutionPics.map((element, id) => <img className="h-[150px] w-full object-cover" key={id} src={URL.createObjectURL(element)} />)
                           :
                           institution.pics.length != 0
                              ? institution.pics.map((element, id) => <img className="h-[150px] w-full object-cover" key={id} src={element} />)
                              : null


                        }
                     </div>
                     <Button onClick={changeInstitutionPic} className='sm:mt-[30px] mt-[20px]'>Change</Button>

                  </ContextWindow>
                  <ContextWindow onclose={() => { setAddress(""); setGmapLink("") }} description="Change your institution's address" title="Change address" visibility={changeAddressContextVisibility} setvisibility={setChangeAddressContextVisibility}>
                     <form onSubmit={changeAddress} className='flex  flex-col items-center justify-center'>
                        <FormInputWhite value={address} onChange={(e) => { setAddress(e.target.value) }} type="text" required placeholder="Address"></FormInputWhite>
                        <FormInputWhite onFocus={() => { setInvalidGmapLink("false") }} invalid={invalidGmapLink} value={gmapLink} onChange={(e) => { setGmapLink(e.target.value) }} type="text" placeholder="G-map link"></FormInputWhite>
                        <Button className="mt-[30px]" type="submit">Change</Button>
                     </form>
                  </ContextWindow>
                  <ContextWindow onclose={() => { setDescription("") }} description="Change your institution's description" title="Change description" visibility={changeDescriptionContextVisibility} setvisibility={setChangeDescriptionContextVisibility}>
                     <form onSubmit={changeDescription} className='flex  flex-col items-center justify-center'>
                        <textarea value={description} onChange={(e) => setDescription(e.target.value)} required placeholder='Description' className="sm:[&:not(&:last-of-type)]:mb-[20px] [&:not(&:last-of-type)]:mb-[15px] w-full sm:w-[400px] h-[120px] sm:h-[150px] border border-white rounded-[20px] bg-[rgba(255,255,255,0.10)] focus:outline-none sm:px-[20px] sm:py-[10px] p-[10px]  text-[16px] sm:text-[20px] font-[Satoshi] font-extralight text-white placeholder:text-[rgba(255,255,255,0.76)]
      valid:shadow-[0px_0px_21px_0px_#26E974] valid:border-[#26E974] valid:text-[#26E974] resize-none"></textarea>
                        <Button className="mt-[30px]" type="submit">Change</Button>
                     </form>
                  </ContextWindow>
                  <ContextWindow onclose={() => { setCurrentLink(""); setCurrentLinkType("") }} title={`Change ${currentLinkType} link`} visibility={changeLinkContextVisibility} setvisibility={setChangeLinkContextVisibility}>
                     <form onSubmit={changeLink} className='flex  flex-col items-center justify-center'>
                        <FormInputWhite value={currentLink} onChange={(e) => setCurrentLink(e.target.value)} type="text" placeholder="Link"></FormInputWhite>
                        <Button className="mt-[30px]" type="submit">Change</Button>
                     </form>
                  </ContextWindow>
                  <ContextWindow key={changeFiltersContextVisibility} full onclose={() => { setIsNewFilters(false); setFiltersArr([]) }} title="Tags & schedule" visibility={changeFiltersContextVisibility} setvisibility={setChangeFiltersContextVisibility}>
                     {!isNewFilters
                        ?
                        <div className=' flex flex-col gap-[20px] mb-[20px]'>{instTags.length != 0 ?
                           <div className="flex gap-[15px]">
                              <Subtitle>Tags:</Subtitle>
                              <div className="flex gap-[10px] items-center flex-wrap">
                                 {instTags.map(element => <div className="font-[Satoshi] text-[18px] md:text-[24px] text-white ">{element};</div>)
                                 }
                              </div>
                           </div>
                           : null
                        }{
                              instSchedule.length != 0 ?
                                 <div className="flex gap-[15px]">
                                    <Subtitle>Schedule:</Subtitle>
                                    <div className="flex gap-[10px] w-full md:max-h-[150px] flex-wrap flex-col">
                                       {
                                          instSchedule.map(element => <div className="font-[Satoshi] text-[18px] md:text-[22px] text-white"> {element.day.toUpperCase()}: {element.time};</div>)
                                       }
                                    </div>
                                 </div>
                                 : null
                           }


                        </div>
                        : null
                     }
                     <FilterBarAdm nextDay={setNextDayIndex} func={filterBarInteraction}></FilterBarAdm>
                     <div className="mt-[10px]">
                        <Subtext2>n.d. - stands for "next day"</Subtext2>
                     </div>
                     <Button onClick={changeTagsNSchedule} className="mt-[30px]" type="submit">Change</Button>
                  </ContextWindow>
                  <ContextWindow title="Warning" visibility={warningContextVisibility} setvisibility={setWarningContextVisibility}>
                     <div className='px-[10px] [&>*]:break-keep text-center flex flex-col gap-[10px]'>
                        <Subtext2>Before setting institution's status to active you should fill in description, address and add a picture. </Subtext2>
                        <Subtext2>Also, your e-mail should be verified.</Subtext2>
                     </div>

                  </ContextWindow>
                  <div key={institution} className='flex lg:flex-row flex-col  gap-[60px] sm:gap-[80px] lg:gap-0  my-[60px]  sm:my-[80px]'>
                     <div className='w-full order-2 lg:order-1'>
                        {institution != null && institution.pics && institution.pics[0]
                           ?
                           <div onClick={() => setChangePhotoContextVisibility(true)} className='w-full sm:w-[500px]  h-[200px] sm:h-[300px] shadow-[0px_4px_24px_0px_rgba(0,0,0,0.20)]
                           border border-[rgba(255,255,255,0.56)] rounded-[35px]  overflow-hidden flex items-center justify-center sm:mx-auto relative cursor-pointer
                           
                           after:content-[""] after:absolute after:w-full after:h-full after:top-0 after:left-0 after:bg-[radial-gradient(50%_50%_at_50%_50%,rgba(97,49,215,0.00)_0%,rgba(97,49,215,0.00)_71.77%,rgba(97,49,215,0.23)_100%)] after:z-10  hover:[&:after]:bg-[radial-gradient(50%_50%_at_50%_50%,rgba(97,49,215,0.10)_0%,rgba(97,49,215,0.20)_71.77%,rgba(97,49,215,0.43)_100%)]
                           after:transition-all after:duration-300 after:ease-in-out 
            
                           before:content-[""] before:top-0 before:left-0 before:w-full before:h-full before:bg-editicoyellow before:absolute before:bg-no-repeat before:bg-center before:transition-all before:duration-300 before:ease-in-out before:opacity-0 hover:[&:before]:opacity-100 hover:[&:before]:drop-shadow-[0px_0px_5px_yellow] 
                           '>
                              <img src={institution.pics[0]} className="object-cover h-full w-full" alt="" />
                           </div>
                           :
                           <div className='
                        select-none z-[20] relative sm:rounded-[45px] rounded-[35px]  w-full sm:w-[500px] bg-gradient-to-br from-[rgba(255,255,255,0.30)] from-19% to-[rgba(255,255,255,0.10)] to-81% overflow-hidden h-[200px] sm:h-[300px] flex items-center justify-center sm:mx-auto
                        shadow-[0px_4px_24px_0px_rgba(0,0,0,0.20)]
                        border border-[rgba(255,255,255,0.56)] 
                        backdrop-blur-[2.5px] 
               
                         after:bg-formbg after:opacity-[0.04] after:-z-10 after:absolute after:top-0 after:left-0    after:w-full after:h-full after:mix-blend-overlay
                        '>
                              <div onClick={() => setChangePhotoContextVisibility(true)} className='[&:hover>img]:drop-shadow-[0px_0px_5px_yellow] cursor-pointer flex gap-[10px] md:max-md:[&>*:nth-child(1)>*]:text-[18px] '>
                                 <SubText>No photo</SubText><img className='  transition-all duration-200 ease-in-out' src={EditIco} />
                              </div>

                           </div>

                        }

                     </div>
                     <div className='w-full flex flex-col items-center justify-center order-1 lg:order-2'>
                        <h2 className='font-[GeneralSans] font-bold text-white text-[32px] md:text-[40px] '>{institution.name}</h2>
                        <Subtext2 >({institution.location})</Subtext2>
                        <p className='font-[GeneralSans] font-bold text-[24px] md:text-[32px] text-white'>{institution.owner}</p>
                        <Subtext2>{auth.currentUser.email}</Subtext2>
                        {auth.currentUser.emailVerified
                           ?
                           <Subtext2><span className='text-[#09B739] font-[500]'>verified</span></Subtext2>
                           :
                           <button onClick={sendMail} className='[&>*]:text-yellow [&>*:hover]:underline'><Subtext2>verify</Subtext2></button>
                        }

                     </div>
                  </div>
                  <div className='flex mb-[60px] sm:mb-[80px] lg:flex-row flex-col gap-[60px] sm:gap-[80px] lg:gap-0 items-center lg:items-start'>
                     <div className='w-full sm:px-[50px]  flex justify-center lg:block lg:justify-start'>
                        <div className='flex flex-col max-w-[500px] gap-[30px] ' >
                           <OwnerEditLine onclick={() => {
                              setChangeAddressContextVisibility(true)
                              setAddress(institution.address);
                           }} title="Address:">{institution.address == "" ? "None" : institution.address}</OwnerEditLine>
                           <OwnerEditLine onclick={() => {
                              setChangeDescriptionContextVisibility(true)
                              setDescription(institution.description);
                           }} title="Description:">{institution.description == "" ? "None" : institution.description}</OwnerEditLine>
                           <OwnerEditLine onclick={() => {
                              setChangeFiltersContextVisibility(true);
                              setInstTags(institution.tags);

                              setInstSchedule(institution.schedule);
                           }} title="Tags & Schedule"></OwnerEditLine>
                           <div className='flex gap-[10px] item-center'>
                              <Subtext2><span className='font-bold'>Status:</span></Subtext2>
                              <StatusSwitch status={status} setstatus={setStatus} />
                           </div>
                        </div>
                     </div>
                     <div className='flex flex-col w-full lg:px-[50px] gap-[20px] items-center lg:items-start [&>*:nth-child(1)]:self-start max-w-[500px] lg:max-w-auto'>
                        <Subtext2><span className='font-bold  '>Links:</span></Subtext2>
                        <div className='flex flex-col gap-[20px] '>
                           <OwnerEditLine onclick={() => {
                              setChangeLinkContextVisibility(true);
                              setCurrentLink(institution.facebook);
                              setCurrentLinkType("facebook");
                           }} logo><img src={Facebook} className='h-[25px] translate-y-[-2px]  inline-block mr-[10px] ' />{institution.facebook == "" ? "None" : institution.facebook}</OwnerEditLine>
                           <OwnerEditLine onclick={() => {
                              setChangeLinkContextVisibility(true);
                              setCurrentLink(institution.instagram);
                              setCurrentLinkType("instagram");
                           }} logo><img src={Instagram} className='h-[25px] translate-y-[-2px]  inline-block mr-[10px]' />{institution.instagram == "" ? "None" : institution.instagram}</OwnerEditLine>
                           <OwnerEditLine onclick={() => {
                              setChangeLinkContextVisibility(true);
                              setCurrentLink(institution.twitter);
                              setCurrentLinkType("twitter");
                           }} logo><img src={Twitter} className='h-[25px] translate-y-[-2px]  inline-block mr-[10px]' />{institution.twitter == "" ? "None" : institution.twitter}</OwnerEditLine>
                           <OwnerEditLine onclick={() => {
                              setChangeLinkContextVisibility(true);
                              setCurrentLink(institution.website);
                              setCurrentLinkType("website");
                           }} logo><img src={Web} className='h-[25px] translate-y-[-2px]  inline-block mr-[10px]' />{institution.website == "" ? "None" : institution.websites}</OwnerEditLine>
                           <OwnerEditLine onclick={() => {
                              setChangeLinkContextVisibility(true);
                              setCurrentLink(institution.email);
                              setCurrentLinkType("email");
                           }} logo><img src={Email} className='w-[25px] translate-y-[-2px]  inline-block mr-[10px]' />{institution.email == "" ? "None" : institution.email}</OwnerEditLine>
                        </div>
                     </div>
                  </div>
                  <div className='mb-[40px]'>
                     <p className='font-[GeneralSans] font-bold text-white text-[32px] md:text-[40px] text-center lg:text-start'>Statistics</p>
                     <div className='w-full flex py-[60px] sm:py-[100px] items-center justify-center'>
                        <Subtext2>Oops! Stats features aren't here yet. Hang tight, we're adding them soon!</Subtext2>
                     </div>
                  </div>
                  <div className='mb-[40px]'>
                     <p className='font-[GeneralSans] font-bold text-white text-[32px] md:text-[40px]  text-center lg:text-start'>Notifications</p>
                     <div className='w-full flex py-[60px] sm:py-[100px] items-center justify-center'>
                        <Subtext2>Oops! Stats features aren't here yet. Hang tight, we're adding them soon!</Subtext2>
                     </div>
                  </div>
               </Container>
               :
               <Loader />
            }

            <Footer />
         </div>
      </div>
   );
};

export default Owner;