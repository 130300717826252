import React, { useEffect, useRef } from 'react';
import ArrowDown from '../../img/arrowDown2.svg';

const DropDown = ({ children, ...props }) => {

   const arrow = useRef();
   const dropDown = useRef();
   useEffect(() => {
      if (props.setref)
         props.setref(dropDown)
   })

   function ClickHandler() {

      if (props.onClick)
         props.onClick();
      if (!arrow.current.className.includes(' rotate-180')) {
         arrow.current.className = arrow.current.className + " rotate-180"
         arrow.current.className = arrow.current.className.replace(" pl-[5px]", " pr-[5px]")
      }
      else {
         arrow.current.className = arrow.current.className.replace(" rotate-180", "")
         arrow.current.className = arrow.current.className.replace(" pr-[5px]", " pl-[5px]")
      }

      // document.getElementById('arrow').classList.toggle('rotate-180')
   }

   return (
      <div ref={dropDown} onClick={ClickHandler} className={props.className}>
         <div className=' text-nowrap items-center  select-none cursor-pointer flex gap-[5px] font-[GeneralSans] font-medium text-white text-[18px] md:text-[24px]'>
            <img src={props.image} className='h-[15px] md:h-auto' alt="" />
            {props.title}
            <img ref={arrow} src={ArrowDown} alt="" className='  transition-all ease-linear duration-100 pl-[5px] md:h-auto h-[6px] ' />
         </div>
      </div>

   );
};

export default DropDown;