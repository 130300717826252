import React, { useEffect, useState, useRef } from "react";
import AdminListItem from "./UI/adminlistitem";
import Container from "./container";
import FormInput from "./UI/forminput";
import EditMenu from "./editmenu";
import Title from "./UI/title"
import Loader from "./UI/loader"
import Subtitle from "./UI/subtitle";
import FormInputWhite from "./UI/forminputwhite";
import EditMenuItem from "./UI/editmenuitem";
import FilterBar from "./UI/filterbar";
import Button from "./UI/button";

import { auth, db, storage } from "../firebase";
import { getDoc, getDocs, addDoc, updateDoc, collection, doc, deleteDoc } from "firebase/firestore";
import { uploadBytesResumable, ref, getDownloadURL, deleteObject, listAll, list } from "firebase/storage";
import ContextWindow from "./contextwindow";
import FilterBarAdm from "./UI/filterbaradm";

export function Institutionedit({ ...props }) {

   const instForm = useRef()

   const [editMenuVisibility, setEditMenuVisibility] = useState(false);
   const [filterIdCounter, setFilterIdCounter] = useState(1);
   const [instPics, setInstPics] = useState([]);
   const [filtersArr, setFiltersArr] = useState([]);
   const [nextDayIndex, setNextDayIndex] = useState();
   const [reseter, setReseter] = useState();
   const [instSearch, setInstSearch] = useState()
   const [deleteContextVisibility, setDeleteContextvisibility] = useState(false)
   const [deleteInvalid, setDeleteInvalid] = useState("false");
   const [deleteName, setDeleteName] = useState("");
   const [deleteId, setDeleteId] = useState()
   const [editId, setEditId] = useState()

   const [instRequestList, setInstRequestlist] = useState()
   const [filteredList, setFilteredList] = useState()

   //form variables
   const [instPicsLinks, setInstPicsLinks] = useState([]);
   const [instAwardsLinks, setInstAwardsLinks] = useState([]);
   const [isEdit, setIsEdit] = useState(false)
   const [isNewFilters, setIsNewFilters] = useState(false);


   const [instName, setInstName] = useState("");
   const [instDecription, setInstDescription] = useState("");
   const [instLocation, setInstLocation] = useState("");
   const [instAddress, setInstAddress] = useState("");
   const [instGmap, setInstGmap] = useState("");
   const [instInstagram, setInstInstagram] = useState("");
   const [instFacebook, setInstFacebook] = useState("");
   const [instTwitter, setInstTwitter] = useState("");
   const [instWebsite, setInstWebsite] = useState("");
   const [instEmail, setInstEmail] = useState("");
   const [instAwards, setInstAwards] = useState([]);
   const [instTags, setInstTags] = useState();
   const [instSchedule, setInstSchedule] = useState();

   useEffect(() => {
      instForm.current.reset()
      if (reseter == 1) {
         fieldsReset()
      };
      // document.querySelectorAll(`input[type="file"]`).forEach(element => console.log(element.value))

   }, [reseter])
   useEffect(() => {
      fetchInstitutions()
   }, [])
   useEffect(() => {
      listFilter()
   }, [instSearch])
   useEffect(() => {
      setDeleteName("");
      setDeleteInvalid("false")
   }, [deleteContextVisibility])
   async function fetchInstitutions() {
      await getDocs(collection(db, "institutions")).then(querySnapshot => {
         const newDataList = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
         setInstRequestlist(newDataList)
         setFilteredList(newDataList)
      })
   }
   function fieldsReset() {
      setFiltersArr([]);
      setInstPics([]);
      setInstAwards([]);
      setInstName("");
      setInstDescription("");
      setInstLocation("");
      setInstAddress("");
      setInstGmap("");
      setInstInstagram("");
      setInstFacebook("");
      setInstTwitter("");
      setInstWebsite("");
      setInstEmail("");
      setInstTags([])
      setInstSchedule([])
      setInstPicsLinks([]);
      setInstAwardsLinks([])
      setIsNewFilters(false);
   }
   function filterBarInteraction(color, content, tag, value) {
      if (isEdit && value) setIsNewFilters(true)
      var currentValue = filtersArr;
      if (value && currentValue.filter(elm => elm.tag == tag).length > 0) {
         currentValue = currentValue.filter(elm => elm.tag != tag)
      }
      if (value) {
         currentValue = [...currentValue, { id: filterIdCounter, color: color, content: content, tag: tag }]
         setFilterIdCounter(filterIdCounter + 1)
      } else {
         currentValue = currentValue.filter(elm => elm.tag != tag)
         // console.log(filtersArr.filter(elm => elm.content != content))
      }
      setFiltersArr(currentValue)
      // console.log(currentValue)
   }
   async function createNewInstitution() {
      var tags = [];
      var schedule = [];
      filtersArr.map(element => {
         if (element.tag.toLowerCase() == "mon" || element.tag.toLowerCase() == "tues" || element.tag.toLowerCase() == "weds" || element.tag.toLowerCase() == "thurs" || element.tag.toLowerCase() == "fri" || element.tag.toLowerCase() == "sat" || element.tag.toLowerCase() == "sun") {
            // days = [...days, element.tag.toLowerCase()];
            schedule = [...schedule, { day: element.tag.toLowerCase(), time: element.content.replaceAll(" ", "") }]
         }
         else
            tags = [...tags, element.tag.toLowerCase()]
      })
      await addDoc(collection(db, "institutions"), {
         name: instName,
         pics: [],
         description: instDecription,
         location: instLocation,
         address: instAddress,
         gmap: instGmap,
         instagram: instInstagram,
         facebook: instFacebook,
         twitter: instTwitter,
         website: instWebsite,
         email: instEmail,
         reviews: 0,
         joy: 0,
         vibe: 0,
         service: 0,
         menu: 0,
         price: 0,
         average: 0,
         awards: [],
         tags: tags,
         schedule: schedule,
         addedBy: auth.currentUser.email
      }).then(async (document) => {

         var instPicsLinksTemp = []
         var linksPicsCounter = 0;
         var instAwardsLinksTemp = []
         var linksAwardsCounter = 0;
         instPics.forEach(async (element) => {
            await uploadBytesResumable(ref(storage, `/institutions/${document.id}/pics/${element.name}`), element).then(async (elm) => {
               var pathName = elm.metadata.name;
               await getDownloadURL(ref(storage, `/institutions/${document.id}/pics/${pathName}`)).then(async (result) => {
                  instPicsLinksTemp = [...instPicsLinksTemp, result]
                  linksPicsCounter++;
               })
            })
            if (instPics.length == linksPicsCounter) await updateDoc(doc(db, "institutions", document.id), {
               pics: instPicsLinksTemp,
            })
         })
         instAwards.forEach(async (element) => {
            await uploadBytesResumable(ref(storage, `/institutions/${document.id}/awards/${element.name}`), element).then(async (elm) => {
               var pathName = elm.metadata.name;
               await getDownloadURL(ref(storage, `/institutions/${document.id}/awards/${pathName}`)).then(async (result) => {
                  instAwardsLinksTemp = [...instAwardsLinksTemp, result]
                  linksAwardsCounter++;
               })
            })
            if (instAwards.length == linksAwardsCounter) await updateDoc(doc(db, "institutions", document.id), {
               awards: instAwardsLinksTemp,
            })
         })

         fetchInstitutions()
      })
   }
   async function editInstitution() {
      var tags = [];
      var schedule = [];
      filtersArr.map(element => {
         if (element.tag.toLowerCase() == "mon" || element.tag.toLowerCase() == "tues" || element.tag.toLowerCase() == "weds" || element.tag.toLowerCase() == "thurs" || element.tag.toLowerCase() == "fri" || element.tag.toLowerCase() == "sat" || element.tag.toLowerCase() == "sun") {
            // days = [...days, element.tag.toLowerCase()];
            schedule = [...schedule, { day: element.tag.toLowerCase(), time: element.content.replaceAll(" ", "") }]
         }
         else
            tags = [...tags, element.tag.toLowerCase()]
      })
      await updateDoc(doc(db, "institutions", editId), {
         name: instName,
         description: instDecription,
         location: instLocation,
         address: instAddress,
         gmap: instGmap,
         instagram: instInstagram,
         facebook: instFacebook,
         twitter: instTwitter,
         website: instWebsite,
         email: instEmail,
         tags: tags,
         schedule: schedule,
         editedBy: auth.currentUser.email
      })
      if (instPics.length != 0) {
         var instPicsLinksTemp = []
         var linksPicsCounter = 0;
         await list(ref(storage, `/institutions/${editId}/pics/`)).then((result) => {
            result.items.forEach(async element => {
               await deleteObject(ref(storage, element.fullPath))
            })
         }).then(async () => {
            instPics.forEach(async (element) => {
               await uploadBytesResumable(ref(storage, `/institutions/${editId}/pics/${element.name}`), element).then(async (elm) => {
                  var pathName = elm.metadata.name;
                  await getDownloadURL(ref(storage, `/institutions/${editId}/pics/${pathName}`)).then(async (result) => {
                     instPicsLinksTemp = [...instPicsLinksTemp, result]
                     linksPicsCounter++;
                  })
               })
               if (instPics.length == linksPicsCounter) await updateDoc(doc(db, "institutions", editId), {
                  pics: instPicsLinksTemp,
               })
            })
         }).then(() => fetchInstitutions())

      }
      if (instAwards.length != 0) {
         var instAwardsLinksTemp = []
         var linksAwardsCounter = 0;
         await list(ref(storage, `/institutions/${editId}/awards/`)).then((result) => {
            result.items.forEach(async element => {
               await deleteObject(ref(storage, element.fullPath))
            })
         }).then(async () => {
            instAwards.forEach(async (element) => {
               await uploadBytesResumable(ref(storage, `/institutions/${editId}/awards/${element.name}`), element).then(async (elm) => {
                  var pathName = elm.metadata.name;
                  await getDownloadURL(ref(storage, `/institutions/${editId}/awards/${pathName}`)).then(async (result) => {
                     instAwardsLinksTemp = [...instAwardsLinksTemp, result]
                     linksAwardsCounter++;
                  })
               })
               if (instAwards.length == linksAwardsCounter) await updateDoc(doc(db, "institutions", editId), {
                  awards: instAwardsLinksTemp,
               })
            })
         }).then(() => fetchInstitutions())
      }
      fetchInstitutions()
      setIsEdit(false)
   }
   function submitCatcher(e) {
      e.preventDefault()
      if (isEdit)
         editInstitution();
      else createNewInstitution()
      setEditMenuVisibility(false)
   }
   function listFilter() {
      if (instRequestList) {
         var listInProcc = [];
         listInProcc = instRequestList.filter(element => {
            if (instSearch)
               return element.name.toLowerCase().includes(instSearch.toLowerCase())
            else return true
         })
         setFilteredList(listInProcc)
      }
   }
   async function deleteInstitutuion(e) {
      e.preventDefault()
      if (instRequestList.filter(element => element.name == deleteName)[0]) {
         await list(ref(storage, `/institutions/${deleteId}/pics/`)).then((result) => {
            result.items.forEach(async element => {
               await deleteObject(ref(storage, element.fullPath))
            })
         })
            .then(async () => {
               console.log("Pics of inst. has been deleted")
               await list(ref(storage, `/institutions/${deleteId}/awards/`)).then((result) => {
                  result.items.forEach(async element => {
                     await deleteObject(ref(storage, element.fullPath))
                  })
               }).then(() => {
                  console.log("Awards of inst. has been deleted")
               })
            })

         // await deleteObject(ref(storage, `/institutions/${deleteId}/`)).then(async () => {

         // })
         await deleteDoc(doc(db, "institutions", deleteId)).then(() => {
            console.log("Data of inst. has been deleted")
         })
         setDeleteContextvisibility(false)
         setDeleteId("")
         fetchInstitutions()

      } else setDeleteInvalid("true")
   }
   function editButtonCatcher(element) {
      setIsEdit(true)
      setEditMenuVisibility(true);
      setInstName(element.name);
      setInstAddress(element.address);
      setInstLocation(element.location);
      setInstEmail(element.email);
      setInstDescription(element.description);
      setInstGmap(element.gmap);
      setInstInstagram(element.instagram);
      setInstFacebook(element.facebook);
      setInstTwitter(element.twitter);
      setInstWebsite(element.website);
      setInstPicsLinks(element.pics);
      setInstAwardsLinks(element.awards);
      setInstTags(element.tags);
      setInstSchedule(element.schedule);
   }

   return (
      <div className="flex items-center justify-center flex-col">
         <ContextWindow title="Delete institution" description="type in the name of inst." visibility={deleteContextVisibility} setvisibility={setDeleteContextvisibility}>
            <form onSubmit={deleteInstitutuion} className="flex flex-col justify-center items-center gap-[20px]">
               <Subtitle>Are you sure?</Subtitle>
               <FormInputWhite invalid={deleteInvalid} onClick={() => setDeleteInvalid("false")} type="text" required
                  placeholder="Name" value={deleteName} onChange={(e) => {
                     setDeleteName(e.target.value)
                  }}
               >
               </FormInputWhite>
               <Button onClick={() => {
                  if (deleteName == "") {
                     console.log(1)
                     setDeleteInvalid("true")
                  }
               }}>Delete</Button>
            </form>
         </ContextWindow>
         <EditMenu key={reseter} setreseter={setReseter} title={isEdit ? "Edit" : "Create"} visibility={editMenuVisibility} setvisibility={setEditMenuVisibility}>
            <form onSubmit={submitCatcher} ref={instForm} action="" className="flex flex-col gap-[30px] overflow-y-scroll overflow-x-visible px-[20px] mx-[-20px] py-[50px] [&::-webkit-scrollbar]:w-0  [-webkit-mask-image:linear-gradient(to_bottom,rgba(0,0,0,0),rgba(0,0,0,1)_10%,rgba(0,0,0,1)_90%,rgba(0,0,0,0)_100%)]">
               <div className="font-[GeneralSans] text-[22px] md:text-[32px] text-center font-bold text-white">Main</div>
               <div className="flex gap-[15px] items-center ">
                  <Subtitle>Pictures:</Subtitle>
                  <label htmlFor="file-upload-admin" className='flex justify-center items-center gap-[5px] md:gap-[10px] font-[Satoshi] text-[18px] md:text-[24px] text-white hover:underline cursor-pointer'>Upload</label>
                  {/* <p className=' text-nowrap font-[Satoshi] text-[18px] md:text-[24px] text-white font-light'>{profilePic ? profilePic.name.slice(profilePic.name.lastIndexOf("\\") + 1) : "No image"}</p> */}
                  <input required={isEdit ? false : true} accept=".jpg,.png,.webp" multiple onChange={(e) => {
                     if (e.target.files) {
                        setInstPics(Array.from(e.target.files))
                        setInstPicsLinks([])
                     }
                  }} className="hidden" id='file-upload-admin' type="file" />

                  {/* <p className="font-[Satoshi] text-[18px] md:text-[24px] text-white hover:underline cursor-pointer">Upload</p> */}
               </div>

               {instPics[0] != undefined || instPicsLinks[0] != undefined ?
                  <div className="mb-[10px] [&::-webkit-scrollbar]:hidden [scrollbar-width:none] [-ms-overflow-style:none]  flex min-h-[150px] gap-[15px] [&>*]:rounded-[15px] w-full overflow-x-scroll  ">
                     {isEdit
                        ?
                        instPicsLinks && instPicsLinks.length != 0
                           ?
                           instPicsLinks.map(element => <img className="h-[150px]" key={element.name} src={element} />)

                           :
                           instPics.map(element => <img className="h-[150px]" key={element.name} src={URL.createObjectURL(element)} />)
                        :
                        instPics.map(element => <img className="h-[150px]" key={element.name} src={URL.createObjectURL(element)} />)
                     }
                  </div>
                  : null}
               <div className="flex flex-wrap justify-between gap-y-[30px] overflow-visible">
                  <EditMenuItem value={instName} onChange={(e) => setInstName(e.target.value)} name="Name:" type="text" required ></EditMenuItem>
                  <EditMenuItem value={instLocation} onChange={(e) => setInstLocation(e.target.value)} name="Location:" type="text" required ></EditMenuItem>
                  <EditMenuItem value={instAddress} onChange={(e) => setInstAddress(e.target.value)} name="Address:" type="text" required ></EditMenuItem>
                  <EditMenuItem value={instGmap} onChange={(e) => setInstGmap(e.target.value)} name="G-maps link:" type="text" required ></EditMenuItem>
                  <div className="flex  gap-[15px] items-start overflow-visible " >
                     <Subtitle>Description:</Subtitle>
                     <textarea value={instDecription} onChange={(e) => setInstDescription(e.target.value)} required className="sm:[&:not(&:last-of-type)]:mb-[20px] [&:not(&:last-of-type)]:mb-[15px] w-full sm:w-[400px] h-[120px] sm:h-[150px] border border-white rounded-[20px] bg-[rgba(255,255,255,0.10)] focus:outline-none sm:px-[20px] sm:py-[10px] p-[10px]  text-[16px] sm:text-[20px] font-[Satoshi] font-extralight text-white placeholder:text-[rgba(255,255,255,0.76)]
      valid:shadow-[0px_0px_21px_0px_#26E974] valid:border-[#26E974] valid:text-[#26E974] resize-none"></textarea>
                  </div>
               </div>
               <div className="font-[GeneralSans] text-[22px] md:text-[32px] text-center font-bold text-white">Socials</div>
               <div className="flex flex-wrap justify-between gap-y-[30px] overflow-visible">
                  <EditMenuItem value={instInstagram} onChange={(e) => setInstInstagram(e.target.value)} name="Instagram link:" type="text"  ></EditMenuItem>
                  <EditMenuItem value={instTwitter} onChange={(e) => setInstTwitter(e.target.value)} name="Twitter link:" type="text"  ></EditMenuItem>
                  <EditMenuItem value={instWebsite} onChange={(e) => setInstWebsite(e.target.value)} name="Website link:" type="text"  ></EditMenuItem>
                  <EditMenuItem value={instFacebook} onChange={(e) => setInstFacebook(e.target.value)} name="Facebook link:" type="text"  ></EditMenuItem>
                  <EditMenuItem value={instEmail} onChange={(e) => setInstEmail(e.target.value)} name="E-mail:" type="email" ></EditMenuItem>
               </div>
               <div className="font-[GeneralSans] text-[22px] md:text-[32px] text-center font-bold text-white">Tags</div>
               <FilterBarAdm nextDay={setNextDayIndex} func={filterBarInteraction}></FilterBarAdm>

               {isEdit && !isNewFilters ?
                  <>
                     <div className="flex gap-[15px]">
                        <Subtitle>Tags:</Subtitle>
                        <div className="flex gap-[10px] items-center flex-wrap">
                           {instTags.map(element => <div className="font-[Satoshi] text-[18px] md:text-[24px] text-white ">{element};</div>)
                           }
                        </div>
                     </div>
                     <div className="flex gap-[15px]">
                        <Subtitle>Schedule:</Subtitle>
                        <div className="flex gap-[10px] w-full md:max-h-[150px] flex-wrap flex-col">
                           {
                              instSchedule.map(element => <div className="font-[Satoshi] text-[18px] md:text-[22px] text-white"> {element.day.toUpperCase()}: {element.time};</div>)
                           }
                        </div>
                     </div>
                  </>

                  : null}
               <div className="font-[GeneralSans] text-[22px] md:text-[32px] text-center font-bold text-white">Awards</div>
               <div className="flex gap-[15px] items-center just">
                  <Subtitle>Pictures:</Subtitle>
                  <label htmlFor="file-upload-awards-admin" className='flex justify-center items-center gap-[5px] md:gap-[10px] font-[Satoshi] text-[18px] md:text-[24px] text-white hover:underline cursor-pointer'>Upload</label>
                  {/* <p className=' text-nowrap font-[Satoshi] text-[18px] md:text-[24px] text-white font-light'>{profilePic ? profilePic.name.slice(profilePic.name.lastIndexOf("\\") + 1) : "No image"}</p> */}
                  <input accept=".jpg,.png,.webp,.svg" multiple onChange={(e) => {
                     if (e.target.files) {
                        setInstAwards(Array.from(e.target.files))
                        setInstAwardsLinks([])
                     }

                  }} className="hidden" id='file-upload-awards-admin' type="file" />

               </div>
               {instAwards[0] != undefined || instAwardsLinks[0] != undefined ?
                  <div className="mb-[10px] [&::-webkit-scrollbar]:hidden [scrollbar-width:none] [-ms-overflow-style:none]  flex min-h-[150px] gap-[15px] [&>*]:rounded-[15px] w-full overflow-x-scroll  ">
                     {isEdit
                        ?
                        instAwardsLinks && (instAwardsLinks.length != 0)
                           ?
                           instAwardsLinks.map(element => <img className="h-[150px]" key={element.name} src={element} />)
                           :
                           instAwards.map(element => <img className="h-[150px]" key={element.name} src={URL.createObjectURL(element)} />)
                        :
                        instAwards.map(element => <img className="h-[150px]" key={element.name} src={URL.createObjectURL(element)} />)
                     }
                  </div>
                  : null}
               {/* <p className="font-[Satoshi] text-[18px] md:text-[24px] text-white hover:underline cursor-pointer">Upload</p> */}
               <div className="flex justify-around">
                  {isEdit ? <Button>Edit</Button> : <Button>Add</Button>}
               </div>
            </form>
         </EditMenu>
         <Container className='pt-[30px] sm:pt-[60px] flex flex-col justify-center items-center'>
            <h1 className='font-[GeneralSans] text-[24px] md:text-[36px] text-center font-bold text-white'>Institutions:</h1>
            <div className='flex flex-wrap sm:flex-nowrap gap-[20px] sm:gap-[20px] pt-[20px] sm:pt-[30px] items-center justify-center pb-[20px]'>
               <FormInput value={instSearch} onChange={(e) => setInstSearch(e.target.value)} required type='text' placeholder='Name'></FormInput>
               <button onClick={() => {
                  setEditMenuVisibility(true)
               }}
                  className=' relative
                    text-yellow rounded-full border-[1px] border-yellow  w-[50px] h-[50px] sm:w-[60px] sm:h-[60px]
                      flex justify-center items-center 
                        after:content-[` `] after:absolute after:left-0 after:top-0 after:bg-gradient-to-b after:from-[rgba(255,206,60,0.24)]
                        after:to-[rgba(251,203,58,0.64)] after:w-full after:h-full after:rounded-full after:opacity-50 after:transition-all after:delay-0 after:duration-100 after:ease-linear
                        transition-all delay-0 duration-100 ease-linear

                        hover:after:to-90% hover:after:opacity-70 hover:shadow-[0_2px_25px_rgba(251,203,58,0.90)]
                        '>
                  <div className='select-none relative  h-[15px] w-[1px] rounded-full bg-yellow  after:content-[""] after:absolute  after:rounded-full after:top-0 after:left-0 after:h-[15px] after:w-[1px] after:bg-yellow after:-rotate-90 after:pointer-events-none'></div>
               </button>

            </div>
            <div ref={props.mainScrollBody} className='h-[730px]  items-center justify-center w-full overflow-y-scroll [&::-webkit-scrollbar]:hidden [scrollbar-width:none] [-ms-overflow-style:none] 
                     [-webkit-mask-image:linear-gradient(to_bottom,rgba(0,0,0,0),rgba(0,0,0,1)_10%,rgba(0,0,0,1)_90%,rgba(0,0,0,0)_100%)]
                     '>
               <div className=' lg:w-full flex flex-col gap-[15px] mt-[40px] mb-[40px] justify-center items-center'>
                  {filteredList && filteredList.length != 0 ?
                     filteredList.map(element => <AdminListItem key={element.id} data={element} edit={(e) => { editButtonCatcher(element); setEditId(element.id) }} delete={(e) => { setDeleteContextvisibility(true); setDeleteId(element.id) }}></AdminListItem>)
                     : <Loader />
                  }
               </div>
            </div>
         </Container>

      </div >
   );
}
