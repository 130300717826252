import React, { useState, useEffect } from 'react';
import RateType from './ratetype';
import Image from '../../img/listitemimg.jpg';
import Star from '../../img/star.svg';
import { useNavigate } from 'react-router-dom';
import StarsInItmes from './starsinitmes';

const ChartItem = (props) => {
   const router = useNavigate();
   const [name, setName] = useState("");
   useEffect(() => {
      if (props.data.name.length > 10) {
         var nameTemp = props.data.name.slice(0, 9) + "..."
         setName(nameTemp)
      } else {
         setName(props.data.name)
      }
   }, [])
   function toId() {
      router(`/main/${props.data.id}`, 0)
      window.scrollTo(0, 0)
   }
   if (props.size == "major")
      return (
         <div className='select-none relative z-10 h-[50px] sm:h-[70px] lg:h-[100px] px-[20px] lg:px-[50px] border-2 rounded-[45px] border-[rgba(255,255,255,0.56)] flex gap-[10px] lg:gap-[20px]
      bg-[linear-gradient(116deg,rgba(255,255,255,0.30)_19%,rgba(255,255,255,0.10)_81.25%)] backdrop-blur-[2.5px] shadow-[0px_4px_24px_0px_rgba(0,0,0,0.20)] after:bg-formbg after:content-[""] after:w-full after:h-full after:top-0 after:left-0 after:absolute after:rounded-[45px] after:-z-20 after:opacity-[0.04] '>
            <div className='flex items-center w-full'>
               <span className='font-{GeneralSans] text-[18px] sm:text-[22px] lg:text-[36px] font-bold text-white mr-[10px] sm:mr-[20px] lg:mr-[30px]'>{props.number}.</span>
               <div className='relative mr-[20px] lg:mr-[30px] w-auto lg:w-[160px] sm:h-[60px] lg:h-[80px] rounded-[15px] overflow-hidden after:content-[""] sm:block hidden
         after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-[linear-gradient(180deg,rgba(98,50,216,0.00)0%,rgba(97,50,216,0.63)100%)] '>
                  <img className="w-full h-full object-cover saturate-[0.3]" src={props.data.pics} alt="" />
               </div>
               <h1 className='font-{GeneralSans] w-auto whitespace-nowrap xl:w-[250px] md:w-[200px] text-[16px]  sm:text-[24px] lg:text-[32px]  font-bold text-white'>{props.data.name.length>12?props.data.name.substring(0,11)+'...':props.data.name}</h1>
               <div className='hidden sm:flex ml-[20px] md:ml-0 h-[25px] md:h-[35px] lg:h-[50px]'>
                  <StarsInItmes generalrate={props.data.average} />
               </div>
               <div className='flex-auto ml-[10px] sm:ml-[20px] font-[Satoshi] text-[16px] sm:text-[20px] lg:text-[28px] text-yellow'>
                  ({Math.round(props.data.average * 100) / 100})
               </div>
               <button onClick={toId} className='ml-[5px] tansition-all duration-100 ease-linear w-[60px] sm:w-[80px] h-[30px] sm:h-[40px] bg-purple rounded-[10px_10px_10px_10px] border border-[#855EE752] font-[GeneralSans] text-[16px] sm:text-[20px] font-medium text-white hover:shadow-[0px_0px_5px_4px_rgba(97,49,215,0.67)]'>
                  more
               </button>
            </div>
         </div>
      );
   else return (
      <div className='select-none relative z-10 h-[50px] sm:h-[60px] lg:h-[70px] px-[20px] lg:px-[40px] border-2 rounded-[45px] border-[rgba(255,255,255,0.56)] flex gap-[20px]
      bg-[linear-gradient(116deg,rgba(255,255,255,0.30)_19%,rgba(255,255,255,0.10)_81.25%)] backdrop-blur-[2.5px] shadow-[0px_4px_24px_0px_rgba(0,0,0,0.20)] after:bg-formbg after:content-[""] after:w-full after:h-full after:top-0 after:left-0 after:absolute after:rounded-[45px] after:-z-20 after:opacity-[0.04] '>
         <div className='flex items-center w-full'>
            <span className='font-{GeneralSans] text-[18px] sm:text-[20px] lg:text-[24px] font-bold text-white mr-[10px] sm:mr-[20px] lg:mr-[30px]'>{props.number}.</span>

            <h1 className='font-{GeneralSans] w-auto xl:w-[170px] text-[18px] sm:text-[22px] lg:text-[30px] font-bold text-white'>{name}</h1>
            <div className='flex-auto ml-[10px] sm:ml-[15px] lg:ml-[20px] font-[Satoshi] text-[16px] sm:text-[20px] lg:text-[28px] text-yellow'>
               ({Math.round(props.rate * 10) / 10})
            </div>
            <button onClick={toId} className='ml-[5px] tansition-all duration-100 ease-linear  w-[60px] sm:w-[80px] h-[30px] sm:h-[40px] bg-purple rounded-[10px_10px_10px_10px] border border-[#855EE752] font-[GeneralSans] text-[16px] sm:text-[20px] font-medium text-white hover:shadow-[0px_0px_5px_4px_rgba(97,49,215,0.67)]'>
               more
            </button>
         </div>
      </div>
   );
};

export default ChartItem;