import React from 'react';
import image from '../../img/edityellow.svg';
import Subtext2 from './subtext2';

const OwnerEditLine = ({ children, ...props }) => {
   if (props.logo)
      return (
         <Subtext2>
            {children}
            <img onClick={props.onclick} className='ml-[10px] translate-y-[-3px] inline-block w-[20px] h-[20px] hover:drop-shadow-[0px_0px_5px_yellow] cursor-pointer transition-all duration-200 ease-in-out' src={image} alt="" />
         </Subtext2>
      );
   else return (
      <Subtext2>
         <span className='font-bold mr-[10px]'>{props.title}</span>
         {children}
         <img onClick={props.onclick} className='ml-[10px] translate-y-[-3px] inline-block w-[20px] h-[20px] hover:drop-shadow-[0px_0px_5px_yellow] cursor-pointer transition-all duration-200 ease-in-out' src={image} alt="" />
      </Subtext2>

   );
};

export default OwnerEditLine;