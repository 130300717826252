import { Institutionedit } from '../components/institutionedit';
import React, { useRef } from 'react';
import SignInHeader from '../components/signinheader';
import Container from '../components/container';
import FormInput from '../components/UI/forminput';
import SubmitArrow from '../img/ArrowSmallSubmit.svg';
import AdminListItem from '../components/UI/adminlistitem';
import AdminUserListItem from '../components/UI/adminuserlistitem.jsx';
import FooterMain from '../components/footermain.jsx';
import AdminHeader from '../components/adminheader.jsx';

const Admin = () => {
   const mainScrollBody = useRef();

   return (
      <div className=' relative bg-bgimage bg-cover  bg-no-repeat bg-center after:content-[""] after:absolute after:top-0 after:left-0 after:w-full after:h-full  after:bg-[radial-gradient(115.11%_87.41%_at_50%_100%,rgba(98,50,216,0.00)0%,#6131D7_100%)] after:z-10 after:bg-cover'>
         <div className='relative z-20'>
            <AdminHeader />
            <Institutionedit mainScrollBody={mainScrollBody} />
            <Container className='sm:pt-[60px] pt-[30px] w-full pb-[100px] flex flex-col justify-center items-center'>
               <h1 className='font-[GeneralSans] text-[24px] md:text-[36px] text-center font-bold text-white'>Users:</h1>
               <div className='flex gap-[20px] pt-[30px] w-full items-center justify-center pb-[20px]'>
                  <FormInput required type='text' placeholder='Nickname'></FormInput>

                  <button className=' relative
                         text-yellow rounded-full border-[1px] border-yellow  min-w-[50px] h-[50px] sm:w-[60px] sm:h-[60px]
                         flex justify-center items-center 
                         after:content-[` `] after:absolute after:left-0 after:top-0 after:bg-gradient-to-b after:from-[rgba(255,206,60,0.24)]
                         after:to-[rgba(251,203,58,0.64)] after:w-full after:h-full after:rounded-full after:opacity-50 after:transition-all after:delay-0 after:duration-100 after:ease-linear
                         transition-all delay-0 duration-100 ease-linear

                         hover:after:to-90% hover:after:opacity-70 hover:shadow-[0_2px_25px_rgba(251,203,58,0.90)]
                         '>
                     <img src={SubmitArrow} alt="" />
                  </button>
               </div>
               <div ref={mainScrollBody} className='max-h-[500px] w-full overflow-y-scroll overflow-hidden [&::-webkit-scrollbar]:hidden [scrollbar-width:none] [-ms-overflow-style:none] 
                     [-webkit-mask-image:linear-gradient(to_bottom,rgba(0,0,0,0),rgba(0,0,0,1)_10%,rgba(0,0,0,1)_90%,rgba(0,0,0,0)_100%)]
                     '>
                  <div className=' w-full flex flex-col gap-[15px] mt-[40px] mb-[40px]'>
                     <AdminUserListItem />
                     <AdminUserListItem />
                     <AdminUserListItem />
                     <AdminUserListItem />
                     <AdminUserListItem />
                     <AdminUserListItem />
                     <AdminUserListItem />
                     <AdminUserListItem />
                     <AdminUserListItem />
                     <AdminUserListItem />
                     <AdminUserListItem />
                  </div>
               </div>
            </Container>
            <FooterMain />
         </div>
      </div>
   );
};


export default Admin;