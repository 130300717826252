import React, { useEffect, useState } from 'react';
import GoogleSignIn from './googlesignin';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from "../../firebase.js"
import { db } from "../../firebase.js"
import { doc, setDoc, getDocs, collection } from "firebase/firestore";

const Form = ({ children, ...props }) => {
   const provider = new GoogleAuthProvider();
   const [usernames, setUsernames] = useState();

   useEffect(() => {
      fetchNames()
   }, [])
   async function fetchNames() {
      await getDocs(collection(db, "userData")).then((querySnapshot) => {
         const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
         setUsernames(newData);
      })

   }
   function signUpGoogle() {
      signInWithPopup(auth, provider)
         .then(async (result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            var inclusdes = false;
            usernames.map(element => {
               if (element.id == user.uid) inclusdes = true;
            })
            if (!inclusdes) {
               await setDoc(doc(db, "userData", user.uid), {
                  nickname: user.email.slice(0, user.email.indexOf('@')),
                  location: "",
               });

               // IdP data available using getAdditionalUserInfo(result)
               // ...
            }
         }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
         });
   }
   return (
      <div className={props.className}>

         <form onSubmit={props.onSubmit} className='select-none z-[20] pb-[30px] relative sm:rounded-[45px] rounded-[35px] mx-auto flex flex-col  items-center w-full sm:w-[500px] bg-gradient-to-br from-[rgba(255,255,255,0.30)] from-19% to-[rgba(255,255,255,0.10)] to-81% overflow-hidden px-[20px]
         shadow-[0px_4px_24px_0px_rgba(0,0,0,0.20)]
         border border-[rgba(255,255,255,0.56)]  pt-[20px] sm:pt-[30px] pb-[27px]
         backdrop-blur-[2.5px]

          after:bg-formbg after:opacity-[0.04] after:-z-10 after:absolute after:top-0 after:left-0    after:w-full after:h-full after:mix-blend-overlay'>
            <h1 className='font-[GeneralSans] text-[24px] sm:text-[36px] text-white font-bold  
            '>{props.formTitle}</h1>
            <GoogleSignIn onClick={signUpGoogle} className='flex justify-center mx-auto w-full sm:my-[30px] my-[20px]' />
            <div className='w-full sm:w-[450px] h-[1px] bg-white/50 sm:mb-[30px] mb-[20px]'></div>
            {children}
         </form>

      </div>
   );
};

export default Form;