import React from 'react';

const LinkWithLogo = ({ children, ...props }) => {
   if (props.src)
      return (
         <div className={props.className}>
            <a rel="nofollow" href={props.href} target={props.target} className='w-full  sm:w-full hover:underline cursor-pointer flex gap-[10px] font-[Satoshi] text-[18px] font-light text-white items-center'>
               <img src={props.src} className='sm:h-auto h-[15px] sm:w-auto w-[15px]' alt="" />
               {children}
            </a>
         </div>


      )
   else return (
      <a href={props.href} target={props.target} className=' flex font-[Satoshi] text-[18px] font-light text-white'>
         {children}
      </a>

   );
};

export default LinkWithLogo;