import React, { Children } from 'react';

const Subtext2 = ({ children, ...props }) => {
   return (
      <p className="font-[Satoshi] text-[18px] md:text-[20px] text-white break-all whitespace-normal">
         {children ? children : null}
      </p>
   );
};

export default Subtext2;