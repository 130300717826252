import React from 'react';
import Logo from '../../img/LogoBlack.svg';


const LogoBlack = (props) => {
   return (
      <div onClick={props.onClick} className={props.className}>
         <div className='relative pointer-events-none'>
            <img className="w-[111px] h-30" src={Logo} alt="" />
         </div>
      </div>

   );
};

export default LogoBlack;