import React from 'react';
import Edit from '../../img/editico.svg';

const EditLink = ({ children, ...props }) => {
   return (
      <div {...props} className='flex justify-center items-center gap-[5px] md:gap-[10px] font-[Satoshi] text-[20px] md:text-[32px] text-white hover:underline cursor-pointer
      
      '>
         {children}
         <img className='pt-[3px] md:h-auto h-[20px]' src={Edit} alt="" />
      </div>
   );
};

export default EditLink;