
import React, { useContext, useEffect, useState } from 'react';
import SignInHeader from '../components/signinheader.jsx';
import Form from '../components/UI/form.jsx';
import Container from '../components/container.jsx';
import FormInputWhite from '../components/UI/forminputwhite.jsx';
import Checkbox from '../components/UI/checkbox.jsx';
import Button from '../components/UI/button.jsx';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/footer.jsx';
import { AuthContext } from '../context';
import { createUserWithEmailAndPassword, sendEmailVerification, beforeAuthStateChanged } from 'firebase/auth';
import { getFunctions } from 'firebase/functions'
import { auth } from "../firebase.js";
import { db } from "../firebase.js"
import { doc, setDoc, getDocs, collection, query, addDoc, where } from "firebase/firestore";
import cross from "../img/errorcross.svg"
import AgreePrivacy from './UI/agreeprivacy.jsx';


const UserRegistration = () => {


   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const [username, setUsername] = useState("")
   const [passwordDuplication, setPasswordDuplication] = useState("");
   const [usernames, setUsernames] = useState();
   const [errors, setErrors] = useState([]);
   const [nameIsUnique, setNameIsUnique] = useState(true);
   const [passwordError, setPasswordError] = useState("false");
   const [emailError, setEmailError] = useState("false");
   const [usernameError, setUsernameError] = useState("false");
   const [receiveNews, setReceiveNews] = useState(false);
   const [privacy, setPrivacy] = useState(false);

   useEffect(() => {
      fetchNames()
   }, [])
   useEffect(() => {
      nameUniqueCheck()
   }, [username])


   async function fetchNames() {
      await getDocs(collection(db, "userData")).then((querySnapshot) => {
         const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
         setUsernames(newData);
      })

   }
   function nameUniqueCheck() {
      var unique = true
      if (usernames)
         usernames.map(element => {
            if (element.nickname == username) unique = false;
         })
      setNameIsUnique(unique)
   }
   function signUp() {
      var errorsLocal = []

      createUserWithEmailAndPassword(auth, email, password)
         .then(async (userCredantial) => {
            if (auth.currentUser != null) {
               await setDoc(doc(db, "userData", userCredantial.user.uid), {
                  nickname: username,
                  location: "",
               }).then(async () => {
                  if (receiveNews) {
                     var emailTemp = [];
                     await getDocs(query(collection(db, "emails"), where("email", "==", email))).then((querySnapshot) => {
                        emailTemp = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                     }).finally(async () => {
                        if (emailTemp.length == 0) {
                           await addDoc(collection(db, "emails"), {
                              email: email
                           })
                        }
                     })
                  }
               });
            }
         }).catch((error) => {
            errorsLocal = [...errorsLocal, <div className='flex gap-[10px] text-[#DE3B3B] mt-[20px] text-[16px] sm:text-[20px] font-[Satoshi] font-normal '><img src={cross} alt="" />{error.message}</div>]
            console.log(error)
            if (error.message.includes("password")) setPasswordError("true");
            if (error.message.includes("email")) setEmailError("true");
            setErrors(errorsLocal);
         });

   }

   function clickCatcher(e) {
      var errors = [];
      e.preventDefault();

      if (!nameIsUnique) {
         errors = [...errors, <div className='flex gap-[10px] text-[#DE3B3B] mt-[20px] text-[16px] sm:text-[20px] font-[Satoshi] font-normal '><img src={cross} alt="" />Username is already taken</div>]
         setUsernameError("true")
      }
      if (password != passwordDuplication) {
         errors = [...errors, <div className='flex gap-[10px] text-[#DE3B3B] mt-[20px] text-[16px] sm:text-[20px] font-[Satoshi] font-normal '><img src={cross} alt="" />Passsword duplication isn't match</div>]
         setPasswordError("true")
      }
      if (!privacy) {
         errors = [...errors, <div className='flex gap-[10px] text-[#DE3B3B] mt-[20px] text-[16px] sm:text-[20px] font-[Satoshi] font-normal '><img src={cross} alt="" />Confirm agreement with privacy policy</div>]
      }
      if ((password == passwordDuplication) && nameIsUnique && errors.length == 0) {
         signUp()
      }
      setErrors(errors)
      // setIsAuth(true);
      // localStorage.setItem('auth', 'true');
   }
   const router = useNavigate();
   function routerLinker() {
      router('/login')
   }
   return (
      <Form onSubmit={clickCatcher} className='pb-[100px]' formTitle='Registration'>
         <FormInputWhite invalid={emailError} onFocus={() => { setEmailError("false") }} value={email} onChange={(e) => { setEmail(e.target.value) }} placeholder='E-mail' required type='email' />
         <FormInputWhite invalid={usernameError} onFocus={() => { setUsernameError("false") }} value={username} onChange={(e) => { setUsername(e.target.value) }} placeholder='Username' required type='text' />
         <FormInputWhite invalid={passwordError} onFocus={() => { setPasswordError("false") }} value={password} onChange={(e) => { setPassword(e.target.value) }} className=' tracking-[150%]' placeholder='Password' required type='password' />
         <FormInputWhite invalid={passwordError} onFocus={() => { setPasswordError("false") }} value={passwordDuplication} onChange={(e) => { setPasswordDuplication(e.target.value) }} className=' tracking-[150%]' placeholder='Repeat password' required type='password' />
         {errors}
         <AgreePrivacy value={{ privacy, setPrivacy }}></AgreePrivacy>
         <div className='mt-[20px] gap-[10px]  flex  w-full w-full sm:w-[400px] font-[Satoshi] text-[16px] font-light text-white'>
            <Checkbox valueCheck value={{ receiveNews, setReceiveNews }}></Checkbox>
            I want to receive news on email
         </div>
         <Button className='sm:mt-[30px] mt-[20px]'>Sign up</Button>
         <div className='sm:mt-[30px] mt-[20px] font-[Satoshi] text-[16px] font-light text-white'>Already have an account? <span onClick={routerLinker} className='text-yellow hover:underline cursor-pointer'>Log in</span></div>
      </Form>
   );
};

export default UserRegistration;