import React from 'react';

const SubText = ({ children, ...props }) => {
   return (
      <div className={props.className}>
         <p className=' font-[Satoshi] text-[20px] sm:text-[24px] text-white font-light text-center px-[10px] sm:px-0'>
            {children}
         </p>
      </div >

   );
};

export default SubText;