import React from 'react';

const ChartTitle = ({ children, ...props }) => {
   if (props.size == "major")
      return (
         <div className={props.className}>
            <div className='flex flex-col items-center justify-center'>
               <div className='flex gap-[5px] lg:gap-[10px] font-[GeneralSans] text-[24px] sm:text-[32px] lg:text-[52px] font-bold text-white items-center'>
                  {children}
                  <img src={props.image} className='lg:h-auto h-[35px]' alt="" />
               </div>
               <div className='font-[Satoshi] text-[18px] sm:text-[22px] lg:text-[28px] text-white'>({props.month})</div>
            </div>
         </div>
      );
   else return (
      <div className={props.className}>
         <div className='flex flex-col items-center justify-center'>
            <div className='flex gap-[5px] lg:gap-[10px] font-[GeneralSans] text-[24px] sm:text-[30px] lg:text-[46px] font-bold text-white items-center'>
               {children}
               <img src={props.image} className='lg:h-auto h-[35px]' alt="" />
            </div>
            <div className='font-[Satoshi] text-[18px] sm:text-[20px] lg:text-[24px] text-white'>({props.month})</div>
         </div>
      </div>
   );
};

export default ChartTitle;