import React, { useEffect } from 'react';
import Container from '../components/container';
import Logo from '../components/UI/logo';
import Button from '../components/UI/button';
import ArrowDown from "../img/arrowDown.svg";
import Picture from '../components/UI/picture';
import Gallery1 from '../img/gallery1.png';
import Gallery2 from '../img/gallery2.png';
import Title from '../components/UI/title';
import Emoji2 from '../img/emoji2.svg';
import Emoji3 from '../img/emoji3.svg';
import Emoji4 from '../img/emoji4.svg';
import SubText from '../components/UI/subtext';
import SubscribeBlock from '../components/subscribeblock';
import Footer from '../components/footer';
import { useNavigate } from 'react-router-dom';
import Emoji1 from '../img/smilyFace.png';



const WelcomePage = () => {
   // const [arrowSource, setAarrowSource] = useState(ArrowSmall);
   function ArrowSourceActive() {
      // setAarrowSource(ArrowSmallActive);
      document.getElementById('arrow').classList.add('bg-arrowSmallActive');
   }
   function ArrowSourceNonActive() {
      // setAarrowSource(ArrowSmall);
      document.getElementById('arrow').classList.remove('bg-arrowSmallActive');
   }

   useEffect(() => {
      const arrow = document.getElementById('#arrowdown');
      document.addEventListener('scroll', () => {
         if (arrow.getBoundingClientRect().top < (window.innerHeight / 1.5) && !arrow.classList.contains('opacity-0'))
            arrow.classList.add('opacity-0');
         else if (arrow.getBoundingClientRect().top > (window.innerHeight / 1.5))
            arrow.classList.remove('opacity-0');
      })
   }, [])

   const router = useNavigate();
   function LoginLink() {
      router('/login', 0);
   }
   function RegistrationLink() {
      router('/registration', 0);
   }

   return (
      <div className="">
         <div className=" bg-bggradient select-none">
            <Container>{/* Header */}
               <div className='pt-5 sm:pt-10 flex justify-between items-center'>
                  <Logo />
                  <div onMouseEnter={ArrowSourceActive} onMouseLeave={ArrowSourceNonActive}
                     onClick={LoginLink}
                     className='
                     transition-all delay-0 duration-100 ease-linear text-white font-[Satoshi] flex items-center gap-2.5
                     font-medium text-[16px] sm:text-[24px] leading-normal cursor-pointer hover:text-[#D5C3FF]
                     '>
                     Log in
                     <div id="arrow" className='h-[16px] w-[16px] transition-all delay-0 duration-100 ease-linear bg-arrowSmall'></div>
                     {/* <img src={arrowSource} alt="" className='transition-all delay-0 duration-300 ease-linear' /> */}
                  </div>
               </div>
            </Container>
            <Container>
               {/* flex flex-col */}
               <div className='mt-[60px] sm:mt-[200px]  flex flex-col items-center lg:block'>
                  <h1 className='
                  text-white font-[GeneralSans] inline-block float-left  leading-[100%] items-center text-center lg:text-start font-bold text-[28px] sm:text-[54px] mb-[30px] sm:mb-[50px]
                  '>
                     Keep in touch with your city !
                     <img className='float-center ml-[10px] inline-block h-[36px] sm:h-[52px]' src={Emoji1} alt="" />
                  </h1>
                  <p className=' max-w-[590px] font-[Satoshi] text-white px-[10px] sm:px-0 text-[20px] text-center lg:text-start sm:text-[28px]  font-normal'>
                     Chose the best spots for your night. Bars, clubs, restaurants all over your place. Accurate rating compiled by our community.
                  </p>
                  <Button onClick={RegistrationLink} className="sm:mt-[60px] mt-[50px]">Let's go!</Button>
               </div>
               <div id='#arrowdown' className='hidden sm:block transition-all duration-100 delay-0 ease-linear pointer-events-none flex items-center mt-[229px]'>
                  <img className='mx-auto mb-10' src={ArrowDown} alt="" />
               </div>
            </Container>
            <Container>
               <div className='mt-[80px] sm:mt-0 flex flex-col gap-[10px] lg:flex-row justify-between items-center '>
                  <Picture src={Gallery1} />
                  <div>
                     <Title image={Emoji2}>
                        Rating & Filters
                     </Title>
                     <SubText className='sm:w-[485px] mt-[20px] sm:mt-[45px]'>
                        Rating of institutions based on detailed ratings of our users. When evaluating, users put a mark on each of the 5 criteria of the institution, after which the system builds a detailed rating.
                        <br />
                        <br />
                        A detailed filter will help you choose the best based on your preferences. Quiet, noisy, delicious, cheap, whatever you want.
                     </SubText>
                  </div>
               </div>
               <div className='mt-[60px] sm:mt-[170px] flex flex-col-reverse lg:flex-row justify-between items-center'>
                  <div>
                     <Title image={Emoji3}>
                        Thread
                     </Title>
                     <SubText className='mt-[20px] sm:mt-[45px] max-w-[510px]'>
                        An active thread allows you to keep your finger on the pulse of your city. Find out about all spontaneous events and concerts. It is an opportunity to meet many new people at any time.
                     </SubText>
                  </div>
                  <Picture className='500px' src={Gallery2} />
               </div>
               <div className=' pt-[60px] pb-[60px] sm:pt-[150px] sm:pb-[110px]'>
                  <Button onClick={RegistrationLink} className='flex items-center justify-center'>
                     Let's go!
                  </Button>
                  <div className='flex items-center justify-center mt-[30px] gap-[0px] sm:gap-[10px]'>
                     <SubText className=''>
                        We are waiting for you
                     </SubText>
                     <img className='pointer-events-none sm:h-full h-[28px]' src={Emoji4} alt="" />
                  </div>
               </div>
            </Container>
            {/* <div className='pb-[120px] flex justify-center'>
               <iframe width="560" height="315" src="https://www.youtube.com/embed/jIQ6UV2onyI?si=bJooUt7E5JAV_-wl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div> */}
            <SubscribeBlock />
            <Footer />
         </div>
      </div >
   );
};

export default WelcomePage;