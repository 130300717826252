import React, { useState } from 'react';
import SignInHeader from '../components/signinheader';
import Container from '../components/container';
import Form from '../components/UI/form';
import FormInputWhite from '../components/UI/forminputwhite';
import Button from '../components/UI/button';
import Footer from '../components/footer';
import { auth } from "../firebase"
import { sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';



const PasswordReset = () => {
   const [email, setEmail] = useState("");
   const router = useNavigate();
   function clickCatcher(e) {
      e.preventDefault();
      if (email != "")
         sendPasswordResetEmail(auth, email)
            .then((userCredantial) => {
               router(-1)
            }).catch((error) => {
               console.log(error)
            });
   }
   return (
      <div className=' relative bg-bgimage bg-cover  bg-no-repeat bg-center after:content-[""] after:absolute after:top-0 after:left-0 after:w-full after:h-full  after:bg-[radial-gradient(115.11%_87.41%_at_50%_100%,rgba(98,50,216,0.00)0%,#6131D7_100%)] after:z-10 after:bg-cover'>
         <div className='relative z-20'>
            <SignInHeader />
            <Container className="flex items-center lg:min-h-[900px] md:min-h-[700px] min-h-[500px]">
               <form onSubmit={clickCatcher} className='select-none z-[20] pb-[30px] relative sm:rounded-[45px] rounded-[35px] mx-auto flex flex-col     items-center w-full sm:w-[500px] bg-gradient-to-br from-[rgba(255,255,255,0.30)] from-19% to-[rgba(255,255,255,0.10)] to-81% overflow-hidden px-[20px]
               shadow-[0px_4px_24px_0px_rgba(0,0,0,0.20)]
               border border-[rgba(255,255,255,0.56)]  pt-[20px] sm:pt-[30px] pb-[27px]
               backdrop-blur-[2.5px]

               after:bg-formbg after:opacity-[0.04] after:-z-10 after:absolute after:top-0 after:left-0    after:w-full after:h-full after:mix-blend-overlay'>
                  <h1 className='font-[GeneralSans] text-[24px] sm:text-[36px] text-white font-bold mb-[30px]'>Reset password</h1>
                  <FormInputWhite value={email} onChange={(e) => { setEmail(e.target.value) }} placeholder='E-mail' required type='email' />

                  <Button className='sm:mt-[30px] mt-[20px]'>Send reset e-mail</Button>
               </form>


            </Container>
            <Footer />
         </div>
      </div>
   );
};

export default PasswordReset;