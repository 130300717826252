import React, { useState, useEffect } from 'react';
import FormTimeInput from './UI/formtimeinput';
import Checkbox from './UI/checkbox';

const ScheduleField = (props) => {
   const [fromValue, setFromValue] = useState();
   const [tillValue, setTillValue] = useState();
   const [nextDayIndex, setNextDayIndex] = useState(false);
   const [reset, setReset] = useState(0);

   function resetFunc() {
      setReset(0);
   }

   useEffect(() => {
      if (props.nextDay)
         props.nextDay(nextDayIndex)
   }, [nextDayIndex])

   useEffect(() => {
      //  
      if (fromValue && tillValue && (tillValue < fromValue) && !nextDayIndex) {
         props.func("green", `${fromValue} - ${tillValue}`, props.day, 0)
         setReset(1);
      }
      else if (fromValue && tillValue) {
         props.func("green", `${fromValue} - ${tillValue}`, props.day, 1)
      } else {
         props.func("green", `${fromValue} - ${tillValue}`, props.day, 0)
      }
   }, [fromValue, tillValue, nextDayIndex])
   return (
      <div className='flex flex-wrap justify-between items-center
      text-[18px] gap-x-[5px] gap-y-[8px]'>
         {props.day}
         <FormTimeInput resetContent={props.resetContent} setResetContent={props.setResetContent} reset={reset} resetfunc={resetFunc} func={setFromValue} color="[background:linear-gradient(180deg,rgba(45,180,116,0.60)0%,rgba(45,180,116,0.80)100%);]" />
         :
         <FormTimeInput resetContent={props.resetContent} setResetContent={props.setResetContent} func={setTillValue} color="[background:linear-gradient(180deg,rgba(45,180,116,0.60)0%,rgba(45,180,116,0.80)100%);]" />
         <Checkbox formalcolor="green" value={{ nextDayIndex, setNextDayIndex }} valueCheck color="[background:linear-gradient(180deg,rgba(45,180,116,0.60)0%,rgba(45,180,116,0.80)100%);]">n.d.</Checkbox>
      </div>
   );
};

export default ScheduleField;