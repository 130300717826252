import { Rates } from '../components/rates.jsx';
import React, { useEffect, useState } from 'react';
import MainHeader from '../components/mainheader';
import Container from '../components/container';
import { useNavigate, useParams } from 'react-router-dom';
import Image from '../img/listitemimg.jpg';
import LinkWithLogo from '../components/UI/linkwithlogo';
import LocationIco from '../img/location.svg'
import TwitterIco from '../img/twitter.svg';
import FacebookIco from '../img/facebook.svg';
import InstagramIco from '../img/inst.svg';
import WebIco from '../img/web.svg';
import EmailIco from '../img/emailico.svg';
import Star from '../img/star.svg'
import StarsInItmes from '../components/UI/starsinitmes.jsx'
import RateType from '../components/UI/ratetype';
import Award1 from '../img/awardico1.svg';
import Award2 from '../img/awardico2.svg';
import Award3 from '../img/awardico3.svg';
import Award4 from '../img/awardico4.svg';
import FooterMain from '../components/footermain';
import RateItem from '../components/UI/rateitem';
import Button from '../components/UI/button.jsx';
import Arrow from '../img/ArrowSmallSubmit.svg';
import PostService from '../API/postservice.js';
import { useFetching } from '../hooks/usefetching.js';
import Loader from '../components/UI/loader.jsx';

const IdPage = () => {
   const params = useParams()

   const [institution, setInstitution] = useState({ "": "" });
   const [reFetchInst, setReFetchInst] = useState();
   const [fetchPostById, isLoading, error] = useFetching(async (id) => {
      const response = await PostService.getById(id);
      setInstitution(response)
   });
   useEffect(() => {
      fetchPostById(params.id)
   }, [reFetchInst])

   const router = useNavigate()
   function BackLink() {
      router(-1);
   }
   function ArrowSourceActive() {
      // setAarrowSource(ArrowSmallActive);
      document.getElementById('arrow').classList.add('bg-arrowSmallActive');
   }
   function ArrowSourceNonActive() {
      // setAarrowSource(ArrowSmall);
      document.getElementById('arrow').classList.remove('bg-arrowSmallActive');
   }
   return (
      <div className='relative bg-bgimage bg-cover  bg-no-repeat bg-center after:content-[""] after:absolute after:top-0 after:left-0 after:w-full after:h-full  after:bg-[radial-gradient(115.11%_87.41%_at_50%_100%,rgba(98,50,216,0.00)0%,#6131D7_100%)] after:z-10 after:bg-cover'>
         <div className='relative z-20'>
            <MainHeader />
            {
               isLoading
                  ? <Loader />
                  : <Container className='pt-[20px] md:pt-[50px] flex items-center'>
                     <div onMouseEnter={ArrowSourceActive} onMouseLeave={ArrowSourceNonActive}
                        onClick={BackLink}
                        className=' 
                     transition-all delay-0 duration-100 ease-linear text-white font-[Satoshi] flex items-center justify-center gap-2.5
                     font-medium text-[20px] md:text-2xl leading-normal cursor-pointer hover:text-[#D5C3FF]
                     '>

                        <div id="arrow" className='rotate-180 bg-cover h-[12px] w-[12px] md:h-[16px] md:w-[16px] transition-all delay-0 duration-100 ease-linear bg-arrowSmall'></div>

                        back
                     </div>
                     <div className='mt-[20px] select-none relative sm:mx-0 mx-[-10px] z-10 mb-[40px] p-[20px] md:p-[40px] border-2 rounded-[45px]        border-[rgba(255,255,255,0.56)] flex flex-col sm:gap-10 gap-[30px] 
                    bg-[linear-gradient(116deg,rgba(255,255,255,0.30)_19%,rgba(255,255,255,0.10)_81.25%)] backdrop-blur-[2.5px] shadow-[0px_4px_24px_0px_rgba(0,0,0,0.20)] after:bg-formbg after:content-[""] after:w-full after:h-full after:top-0 after:left-0 after:absolute after:rounded-[45px] after:-z-20 after:opacity-[0.04]'>
                        <div className='flex md:gap-y-[40px] gap-y-[15px] sm:gap-y-[30px] lg:gap-[40px] flex-row flex-wrap lg:flex-nowrap justify-around items-center md:items-start lg:justify-start'>
                           <div className='order-1  basis-[100%] lg:basis-auto relative w-[350px] h-[180px] sm:h-[270px] rounded-[30px] md:rounded-[40px] overflow-hidden after:content-[""]
                     after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-[linear-gradient(180deg,rgba(98,50,216,0.00)0%,rgba(97,50,216,0.63)100%)] '>
                              <img className="w-full h-full object-cover saturate-[0.3]" src={institution.pics} alt="" />
                           </div>
                           <div className='order-3 md:order-2 flex-col flex justify-center items-center md:block'>
                              <div className=' flex items-center gap-[10px]'>
                                 <h1 className='leading-[100%] font-[GeneralSans] font-bold md:text-[36px] sm:text-[28px] text-[26px] lg:text-[48px] text-white'>{institution.name}</h1>
                                 <p className='leading-[100%] font-[GeneralSans] md:text-[28px] text-[24px] lg:text-[34px] text-yellow'>({Math.round(institution.average * 100) / 100})</p>
                              </div>
                              <LinkWithLogo target="_blank" href={institution.gmap} className="sm:p-0 px-[10px] flex-auto flex mb-[15px] sm:mb-[20px] md:mb-[30px] lg:[&>*]:text-[20px] sm:[&>*]:text-[18px]" src={LocationIco} >
                                 {institution.address}
                              </LinkWithLogo>
                              <p className='md:text-start text-center font-[Satoshi] text-white text-[18px] lg:text-[20px] md:max-w-[350px] lg:max-w-[390px]  mb-[20px] md:mb-[30px]'>{institution.description}</p>
                              <div className='sm:p-0 px-[10px] flex md:gap-y-[30px] gap-y-[20px] md:flex-nowrap flex-wrap md:justify-start justify-center gap-[30px] [&>*]:cursor-pointer'>
                                 {institution.twitter != "" ? <a rel="nofollow" target='_blank' href={institution.twitter}><img src={TwitterIco} alt="" /></a> : null}
                                 {institution.facebook != "" ? <a rel="nofollow" target='_blank' href={institution.facebook}><img src={FacebookIco} alt="" /></a> : null}
                                 {institution.instagram ? <a rel="nofollow" target='_blank' href={institution.instagram}><img src={InstagramIco} alt="" /></a> : null}
                                 {institution.website != "" ? <a rel="nofollow" target='_blank' href={institution.website}><img src={WebIco} alt="" /></a> : null}
                                 {institution.email != "" ? <a rel="nofollow" target='_blank' className='flex items-center justify-center' href={"mailto:" + institution.email}><img src={EmailIco} alt="" /></a> : null}
                              </div>
                           </div>
                           <div className='order-2 md:order-3 select-none flex lg:flex-auto flex-col items-center sm:gap-[8px] gap-[6px]'>
                              <div className='pointer-events-none flex h-[30px] sm:h-[40px] md:h-[45px] lg:h-[40px] xl:h-[50px] gap-[10px] [&>*]:h-full'>
                                 <StarsInItmes generalrate={Math.round(institution.average)} />
                              </div>
                              <p className='font-[GeneralSans] text-[16px] text-white font-light'>({institution.reviews} reviews)</p>
                           </div>
                        </div>
                        <div className='flex items-center justify-center h-[1px] bg-[linear-gradient(270deg,rgba(255,255,255,0.49)0%,rgba(255,255,255,0.49)100%)] mx-[20px] md:mx-[50px]'></div>
                        <div className='flex lg:justify-between mx-[20px] md:mx-[50px] lg:flex-nowrap flex-wrap  justify-center gap-[20px] sm:gap-[40px] sm:gap-y-[30px] lg:gap-[20px]'>
                           <RateType icotype='1' major>{Math.round(institution.joy * 10) / 10}</RateType>
                           <RateType icotype='2' major>{Math.round(institution.vibe * 10) / 10}</RateType>
                           <RateType icotype='3' major>{Math.round(institution.service * 10) / 10}</RateType>
                           <RateType icotype='4' major>{Math.round(institution.menu * 10) / 10}</RateType>
                           <RateType icotype='5' major>{Math.round(institution.price * 10) / 10}</RateType>
                        </div>
                        <div className='flex items-center justify-center h-[1px] bg-[linear-gradient(270deg,rgba(255,255,255,0.49)0%,rgba(255,255,255,0.49)100%)] mx-[20px] md:mx-[50px]'></div>
                        <div className='sm:[&>*]:h-full [&>*]:h-[100px] flex gap-[10px] sm:gap-[20px] justify-center flex-wrap sm:flex-nowrap'>
                           {
                              institution.awards && institution.awards.length > 0
                                 ?
                                 institution.awards.map((element, index) => <img key={index} src={element} alt="" />)
                                 : <p className='font-[Satoshi] text-white text-[20px]'>No awards</p>
                           }

                        </div>
                     </div>
                     <div className='mt-[20px] sm:mx-0 mx-[-10px] select-none relative z-10 h-full md:h-[500px] md:p-[40px] p-[30px] border-2 rounded-[45px_45px_0px_0px] border-b-0 border-[rgba(78,48,154,0.27)] flex flex-col gap-10 items-center 
                    bg-[linear-gradient(116deg,rgba(26,26,26,0.54)19%,rgba(14,14,14,0.83)81.25%)] backdrop-blur-[2.5px] shadow-[0px_4px_24px_0px_rgba(0,0,0,0.20)] after:bg-formbg after:content-[""] after:w-full after:h-full after:top-0 after:left-0 after:absolute after:rounded-[45px] after:-z-20 after:opacity-[0.04]'>
                        <Rates refetch={setReFetchInst} id={params.id} />
                        <div className='flex flex-col sm:p-0 py-[50px] items-center justify-center h-full font-[Satoshi] text-white'>
                           <p className='text-[24px] md:text-[36px] font-medium'>Comments</p>
                           <p className='text-[16px] md:text-[20px] font-light'>Work in progress...</p>
                        </div>
                     </div>
                  </Container>
            }

            <FooterMain />
         </div>
      </div>
   );
};

export default IdPage;