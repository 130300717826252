import React from 'react';
import Subtitle from './subtitle';
import FormInputWhite from './forminputwhite';

const EditMenuItem = ({ children, ...props }) => {
   return (
      <div className="flex  gap-[15px] items-center just [&>input]:w-[250px]">
         <Subtitle>{props.name}</Subtitle>
         <FormInputWhite  {...props}></FormInputWhite>
      </div>
   );
};

export default EditMenuItem;