import React from 'react';

const FormInput = (props) => {
   return (
      <input value={props.value} onChange={(e) => props.onChange(e)} placeholder={props.placeholder} type={props.type} required={props.required}
         className='
         rounded-[30px] w-full sm:w-[500px] h-[50px] sm:h-[60px] border-[1px] border-[rgba(255,255,255,0.76)] bg-[rgba(255,255,255,0.10)] px-[20px] sm:px-10 text-[20px] sm:text-[24px] font-[Satoshi] font-light text-white placeholder:text-[rgba(255,255,255,0.76)]
         focus:outline-0
      '
      />
   );
};

export default FormInput;