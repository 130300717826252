import React, { useEffect, useState } from 'react';
import Image from '../../img/listitemimg.jpg'
import Star from '../../img/star.svg'
import LinkWithLogo from './linkwithlogo';
import StarsInItmes from "../UI/starsinitmes";
import LocationIco from '../../img/location.svg';
import RateType from './ratetype';

const AdminListItem = (props) => {
   // const [institution, setInstitution] = useState();
   // useEffect(() => {
   //    setInstitution(props.item)
   // }, [])

   return (
      <div className='select-none items-center max-w-[500px] lg:max-w-full lg:w-full relative z-10 min-[1200px]:h-[150px] p-[15px] lg:p-[10px] border-2 rounded-[45px] border-[rgba(255,255,255,0.56)] flex flex-col lg:flex-row gap-[10px] sm:gap-[20px]
      bg-[linear-gradient(116deg,rgba(255,255,255,0.30)19%,rgba(255,255,255,0.20)81.25%)] backdrop-blur-[2.5px] shadow-[0px_4px_24px_0px_rgba(0,0,0,0.20)] after:bg-formbg after:content-[""] after:w-full after:h-full after:top-0 after:left-0 after:absolute after:rounded-[45px] after:-z-20 after:opacity-[0.04] 
      '>
         <div className='relative w-full lg:max-w-[300px] min-[1200px]:max-w-[160px] rounded-[40px] h-[150px] sm:h-[250px] lg:h-[150px] min-[1200px]:h-full overflow-hidden after:content-[""]
         after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-[linear-gradient(180deg,rgba(98,50,216,0.00)0%,rgba(97,50,216,0.63)100%)] '>
            <img className="w-full h-full object-cover saturate-[0.3]" src={props.data.pics[0]} alt="" />
         </div>
         <div className='flex flex-col w-full gap-[10px]'>
            <div className='flex justify-center lg:justify-start flex-wrap lg:flex-no-wrap gap-[5px] sm:gap-[15px] items-center'>
               <h1 className='font-[GeneralSans] text-[24px] text-white font-bold'>{props.data.name}</h1>
               <div className='flex sm:h-full h-[25px]'>
                  <StarsInItmes generalrate={Math.round(props.data.average)} />
               </div>
               <p className='font-[GeneralSans] text-[16px] text-white font-light'>({props.data.reviews} reviews)</p>
               <LinkWithLogo className="hover:decoration-transparent  lg:flex-auto flex flex-row sm:[&>*]:justify-end [&>*]:justify-center sm:mr-[20px]" src={LocationIco} >
                  {props.data.address}
               </LinkWithLogo>
            </div>
            <p className='font-[Satoshi] text-center lg:text-start text-[16px] sm:text-[20px] text-white'>{
               props.data.description.length > 100
                  ?
                  props.data.description.slice(0, 100) + "..."
                  :
                  props.data.description
            }</p>
            <div className='flex lg:flex-row flex-col justify-between items-center'>
               <div className='flex flex-wrap flex-auto justify-center lg:justify-start mt-[0px] mb-[20px] sm:my-[30px] lg:my-0 lg:flex-nowrap gap-[15px] sm:gap-[30px]'>
                  <RateType icotype='1' >{Math.round(props.data.joy * 10) / 10}</RateType>
                  <RateType icotype='2' >{Math.round(props.data.vibe * 10) / 10}</RateType>
                  <RateType icotype='3' >{Math.round(props.data.service * 10) / 10}</RateType>
                  <RateType icotype='4' >{Math.round(props.data.menu * 10) / 10}</RateType>
                  <RateType icotype='5' >{Math.round(props.data.price * 10) / 10}</RateType>
               </div>
               <button onClick={props.delete} className='lg:ml-[5px] mb-[5px] sm:mb-0 min-[1200px]:ml-0 tansition-all duration-100 ease-linear w-full sm:mb-[10px] lg:w-[100px] h-[50px] lg:h-[40px] bg-purple lg:mr-[5px] rounded-[15px_15px_15px_15px] lg:rounded-[10px_10px_10px_10px] border border-[#855EE752] font-[GeneralSans] text-[20px] font-medium text-white hover:shadow-[0px_0px_5px_4px_rgba(97,49,215,0.67)]'>
                  delete
               </button>
               <button onClick={props.edit} className='lg:ml-[5px] min-[1200px]:ml-0 tansition-all duration-100 ease-linear w-full sm:mb-[10px] lg:w-[100px] h-[50px] lg:h-[40px] bg-purple lg:mr-[20px] rounded-[15px_15px_40px_40px] lg:rounded-[10px_10px_20px_10px] border border-[#855EE752] font-[GeneralSans] text-[20px] font-medium text-white hover:shadow-[0px_0px_5px_4px_rgba(97,49,215,0.67)]'>
                  edit
               </button>
            </div>
         </div>
      </div>
   );
};

export default AdminListItem;