import React from 'react';
import Logo from './UI/logo.jsx';
import Container from './container.jsx';
import { useNavigate } from 'react-router-dom';
import { auth } from "../firebase.js";
import { signOut } from 'firebase/auth';

const AdminHeader = () => {
   const router = useNavigate()
   function logout(e) {
      e.preventDefault();
      signOut(auth).then(() => {
         console.log('Sign out successful')
         router(0);
      }).catch((error) => { console.log(error) })
      // setIsAuth(false);
      // localStorage.removeItem('auth');
   }
   // function ArrowSourceActive() {
   //    // setAarrowSource(ArrowSmallActive);
   //    document.getElementById('arrow').classList.add('bg-arrowSmallActive');
   // }
   // function ArrowSourceNonActive() {
   //    // setAarrowSource(ArrowSmall);
   //    document.getElementById('arrow').classList.remove('bg-arrowSmallActive');
   // }
   return (

      <Container >
         <header className=' pt-[40px] flex justify-between'>
            <Logo />
            <div
               onClick={logout}
               className=' 
                     transition-all delay-0 duration-100 ease-linear text-white font-[Satoshi] flex items-center gap-[20px]
                     font-medium text-[16px] sm:text-[24px] leading-normal cursor-pointer hover:text-[#D5C3FF] [&:hover>:after]:bg-[#D5C3FF] [&:hover>*]:bg-[#D5C3FF]
                     '>
               {/* <img src={arrowSource} alt="" className='transition-all delay-0 duration-300 ease-linear' /> */}
               Log out
               <div className=' relative h-[10px] md:h-[20px] w-[2px] md:w-[3px] rounded-full bg-white hover:bg-[#D5C3FF] rotate-45 after:content-[""] after:absolute  after:rounded-full after:top-0 after:left-0 md:after:h-[20px] after:h-[10px] after:w-[2px] md:after:w-[3px] after:bg-white after:-rotate-90 after:pointer-events-none '></div>
            </div>
         </header>
      </Container>

   );
};

export default AdminHeader;