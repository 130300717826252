import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, Navigate } from "react-router-dom";
import { adminRoutes, ownerRoutes, privatRoutes, publicRoutes } from '../router/routes.js';
import { AuthContext } from '../context/index.js';
import Loader from './UI/loader.jsx';
import { auth, db } from '../firebase.js';
import { collection, doc, getDocs, getDoc, query, setDoc, updateDoc, where } from "firebase/firestore";
import Logo from '../components/UI/logo';
import Container from './container.jsx';

const AppRouter = () => {
   const { isAuth, isLoading } = useContext(AuthContext);

   const [isAdmin, setIsAdmin] = useState(false);
   const [isOwner, setIsOwner] = useState(false)
   useEffect(() => {
      fetchAdmin()
   }, [isAuth])
   async function fetchAdmin() {
      if (auth.currentUser) {
         const nameAdmin = await getDocs(query(collection(db, "admins"), where("id", "==", auth.currentUser.uid)))
         const nameOwner = await getDoc(doc(db, "owners", auth.currentUser.uid));
         if (!nameAdmin.empty) { setIsAdmin(true) }
         else if (nameOwner.data() !== undefined) {
            setIsOwner(true);
         }
      }
   }


   // if (isLoading === true) {
   //    console.log("loading")
   //    return (<Loader />)
   // }
   if (isLoading == true) {
      return (
         <div className='flex-col w-full h-[100vh] bg-bgimage bg-cover  bg-no-repeat bg-center after:content-[""] after:absolute after:top-0 after:left-0 after:w-full after:h-full  after:bg-[radial-gradient(115.11%_87.41%_at_50%_100%,rgba(98,50,216,0.00)0%,#6131D7_100%)] after:z-10 after:bg-cover  [&>*]:z-20'>
            <Container className="relative h-full [&>*]:h-full [&>*]:flex [&>*]:flex-col">
               <div className='pt-5 sm:pt-10 flex justify-between items-center relative'>
                  <Logo />
               </div>
               <div className='flex items-center justify-center relative flex-auto'>
                  <Loader />
               </div>
            </Container>


         </div>)
   } else if (isAuth && isAdmin) {
      return <Routes>
         {adminRoutes.map(route =>
            <Route path={route.path} element={route.component} exact={route.exact} key={route.path} />
         )}
         <Route path="*" element={<Navigate to="/admin" replace />} />
      </Routes>
   } else if (isAuth && isOwner) {
      return <Routes>
         {ownerRoutes.map(route =>
            <Route path={route.path} element={route.component} exact={route.exact} key={route.path} />
         )}
         <Route path="*" element={<Navigate to="/owner" replace />} />
      </Routes>
   } else if (isAuth) {
      return <Routes>
         {privatRoutes.map(route =>
            <Route path={route.path} element={route.component} exact={route.exact} key={route.path} />
         )}
         <Route path="*" element={<Navigate to="/main" replace />} />
      </Routes>
   } else if (isAuth === false) {
      console.log(isLoading)
      return <Routes>
         {publicRoutes.map(route =>
            <Route path={route.path} element={route.component} exact={route.exact} key={route.path} />
         )}
         <Route path="*" element={<Navigate to="/welcome" replace />} />
      </Routes>
   }


   // return (
   //    isAuth
   //       ?
   //       isAdmin ?
   //          <Routes>
   //             {adminRoutes.map(route =>
   //                <Route path={route.path} element={route.component} exact={route.exact} key={route.path} />
   //             )}
   //             <Route path="*" element={<Navigate to="/admin" replace />} />
   //          </Routes>
   //          :
   //          isOwner ?
   //             <Routes>
   //                {ownerRoutes.map(route =>
   //                   <Route path={route.path} element={route.component} exact={route.exact} key={route.path} />
   //                )}
   //                <Route path="*" element={<Navigate to="/owner" replace />} />
   //             </Routes>
   //             :
   //             <Routes>
   //                {privatRoutes.map(route =>
   //                   <Route path={route.path} element={route.component} exact={route.exact} key={route.path} />
   //                )}
   //                <Route path="*" element={<Navigate to="/main" replace />} />
   //             </Routes>
   //       : <Routes>
   //          {publicRoutes.map(route =>
   //             <Route path={route.path} element={route.component} exact={route.exact} key={route.path} />
   //          )}
   //          <Route path="*" element={<Navigate to="/welcome" replace />} />
   //       </Routes>
   // );
};

export default AppRouter;