import React from 'react';
import GoogleIco from '../../img/googleico.png';

const GoogleSignIn = (props) => {
   return (
      <div {...props} className={props.className}>
         <div className='select-none cursor-pointer relative flex justify-center items-center font-[Roboto] font-[700] rounded-full w-full sm:w-[400px] h-[45px] sm:h-[55px] bg-white text-[16px] sm:text-[20px]'>
            <img className='cursor-pointer absolute left-0 ml-[8px] sm:w-10 h-[25px] sm:h-10' src={GoogleIco} alt="" />
            <div className='font-[Roboto] '>Continue with Google</div>
            <div></div>
         </div>
      </div>

   );
};

export default GoogleSignIn;