import { Sortdrop } from '../components/sortdrop';

import React, { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../context';
import MainHeader from '../components/mainheader';
import FilterIco from '../img/filterico2.svg'
import Container from '../components/container';
import DropDown from '../components/UI/dropdown';
import FilterItem from '../components/UI/filteritem';
import FilterBarItem from '../components/UI/filterbaritem';
import CheckBox from '../components/UI/checkbox'
import FormTimeInput from '../components/UI/formtimeinput';
import FilterBar from '../components/UI/filterbar';
import ListItem from '../components/listitem';

import Footer from '../components/footer'
import FooterMain from '../components/footermain';
// import data from '../json/insttest';
import { auth, db } from '../firebase';
import { getDocs, doc, collection, ref, where, query, updateDoc } from "firebase/firestore"
import ContextWindow from '../components/contextwindow';
import Button from '../components/UI/button';
import CitySearchBar from '../components/citysearchbar';



const MainPage = () => {
   const { setIsAuth } = useContext(AuthContext);
   function logout(e) {
      e.preventDefault();
      setIsAuth(false);

   }

   const filterScroll = useRef();
   const filterScrollParent = useRef();

   const [welcomeContextVisibility, setWelcomeContextVisibility] = useState(false);
   const [locationTemp, setLocationTemp] = useState();
   const [headerReset, setHeaderReset] = useState(0);



   const DropDownBack = useRef();
   const DropDownBack2 = useRef();
   const mainScrollbarBody = useRef();
   const mainScrollbarButton = useRef();
   const mainScrollBody = useRef();
   const [originalList, setOriginalList] = useState([]);
   const [instList, setInstList] = useState([]);
   const [filterIdCounter, setFilterIdCounter] = useState(1);
   const [filtersArr, setFiltersArr] = useState([]);
   const [resetContent, setResetContent] = useState();
   const [resetContentAll, setResetContentAll] = useState();
   const [sortingType, setSortingType] = useState();
   const [sortRef, setSortRef] = useState();
   const [nextDayIndex, setNextDayIndex] = useState();
   const [location, setLocation] = useState();

   // const [institutions, setInstitutions] = useState();

   const timeRegEx = /\b[0-2]?[0-9]:[0-5][0-9]\b/g;

   async function fetchInstitutions() {

      if (location) {

         var list = []
         await getDocs(query(collection(db, "institutions"), where("location", "==", location), where("active", "==", true))).then((querySnapshot) => {
            list = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
         })

         setOriginalList(list.sort((a, b) => b.average - a.average))
         setInstList(list.sort((a, b) => b.average - a.average))
         setResetContentAll(1)
      }

   }
   useEffect(() => {
      fetchInstitutions()
   }, [location])
   function contains(where, what) {
      for (var i = 0; i < what.length; i++) {
         if (where.indexOf(what[i].toLowerCase()) == -1) return false;
      }
      return true;
   }

   function filtersHandler() {
      var currentFilters = [];
      var scheduleLimits = [];
      var timeLimits;
      filtersArr.map(element => {
         if (element.content == "Mon" || element.content == "Tues" || element.content == "Weds" || element.content == "Thurs" || element.content == "Fri" || element.content == "Sat" || element.content == "Sun")
            scheduleLimits.push(element.content.toLowerCase());
         else if (element.tag == "time")
            timeLimits = element.content;
         else
            currentFilters.push(element.content)

         // { "content": element.content, "tag": element.tag }
      })
      var result = [];
      originalList.map(mapelm => {

         if (contains(mapelm.tags, currentFilters) && currentFilters && scheduleCoincidance(mapelm, timeLimits, scheduleLimits)) {

            result.push(mapelm);
         }
      })

      if (sortingType)
         changeSortType(sortingType.toLowerCase(), 1, result)
      else
         setInstList(result)
   }
   function scheduleCoincidance(element, timeLimits, scheduleLimits) {

      if (!timeLimits && scheduleLimits.length == 0) return true;
      if (element.schedule) {
         var elementDays = [];
         var isScheduleValid = true;

         if (scheduleLimits) {
            var elementDays = [];
            element.schedule.map(elementSch => {
               elementDays.push(elementSch.day)
            })
            if (!contains(elementDays, scheduleLimits)) isScheduleValid = false
         }
         var isTimeVaild = true;

         if (timeLimits) {
            scheduleLimits.map(elementOfDays => {
               var instDay = element.schedule.find(elementOfSchedule => elementOfSchedule.day == elementOfDays.toLowerCase());
               // console.log(instDay)
               if (instDay != null) {
                  var isNextDay = instDay.time.match(timeRegEx)[0] > instDay.time.match(timeRegEx)[1];

                  var isValid = instDay.time.match(timeRegEx)[0] <= timeLimits.match(timeRegEx)[0];

                  var isTotalyvalid = (isValid
                     &&
                     (
                        (((isNextDay == false) && (nextDayIndex == false)) && instDay.time.match(timeRegEx)[1] >= timeLimits.match(timeRegEx)[1])
                        ||
                        (((isNextDay == true) && (nextDayIndex == true)) && instDay.time.match(timeRegEx)[1] >= timeLimits.match(timeRegEx)[1])
                        ||
                        ((isNextDay == true) && (nextDayIndex == false))
                     ));

                  if (!isTotalyvalid)
                     isTimeVaild = false;
               }
               else {
                  return false
               }
            })
         }
         if ((element.schedule > 0 && element.schedule.length < scheduleLimits) || !isScheduleValid || !isTimeVaild)
            return false;
         else
            return true;

      }
      else
         return false;

   }
   useEffect(() => {
      filtersHandler()
   }, [filtersArr])

   useEffect(() => {
      const filterChildren = filterScroll.current.childNodes;
      var filterWidth = -10;
      for (var i = 0; i < filterChildren.length; i++) {
         filterWidth += filterChildren[i].offsetWidth + 10;
      }

      filterScroll.current.prevpercentage = 0



      if (filterScroll.current.parentElement.offsetWidth < filterWidth) {
         filterScrollParent.current.addEventListener("wheel", (e) => {
            e.preventDefault()
            filterScrollParent.current.scrollLeft += (e.deltaY) / 3.5;

         })
      }
      var isDown
      var position
      var position2
      var range = mainScrollbarBody.current.offsetHeight - 90;
      var percentage

      const listChildren = mainScrollBody.current.childNodes;
      var listHeight = 65;
      for (var i = 0; i < listChildren.length; i++) {
         listHeight += listChildren[i].offsetHeight + 15;
      }
      mainScrollBody.current.addEventListener("scroll", (e) => {
         percentage = mainScrollBody.current.scrollTop / (listHeight - mainScrollBody.current.offsetHeight) * 100;
         position = percentage * range / 100;
         mainScrollbarButton.current.style.transform = `translate(-50%,${position}px)`
      })


      //===============================================================================

      mainScrollbarButton.current.addEventListener("mousedown", () => {
         isDown = 1
      })
      document.addEventListener("mousemove", (e) => {
         if (isDown) {
            position = Math.min(Math.max(e.clientY - mainScrollbarBody.current.getBoundingClientRect().top - 40, 0), mainScrollbarBody.current.offsetHeight - 90);
            percentage = position / range * 100;
            position = percentage * range / 100;
            // console.log(mainScrollBody.current.scrollTop)
            // console.log(listHeight - mainScrollBody.current.offsetHeight)
            mainScrollbarButton.current.style.transform = `translate(-50%,${position}px)`
            position2 = percentage * (listHeight - mainScrollBody.current.offsetHeight) / 100;
            mainScrollBody.current.scrollTop = position2;
         }
      })
      document.addEventListener("mouseup", () => {
         isDown = 0
      })
   })
   function filterDrop() {

      if (DropDownBack.current.style.transform == 'translate(0px, -50px)') {
         DropDownBack.current.style.transform = 'translate(0px, 0px)'
         DropDownBack.current.style.opacity = "1";
         DropDownBack.current.style.pointerEvents = "all";
         DropDownBack.current.style.marginBottom = "0px";
         DropDownBack.current.style.height = "100%";
         DropDownBack.current.style.zIndex = "10";
         DropDownBack.current.style.marginTop = "30px";
      }
      else {
         DropDownBack.current.style.transform = 'translate(0px, -50px)'
         DropDownBack.current.style.pointerEvents = "none";
         DropDownBack.current.style.opacity = '';
         DropDownBack.current.style.marginBottom = "0";
         DropDownBack.current.style.height = "0";
         DropDownBack.current.style.zIndex = "-1";
         DropDownBack.current.style.marginTop = "0px";
      }
   }
   function sortingDrop() {
      if (DropDownBack2.current.style.transform == 'translate(0px, -50px)') {
         DropDownBack2.current.style.marginTop = "30px";
         DropDownBack2.current.style.transform = 'translate(0px, 0px)'
         DropDownBack2.current.style.opacity = "1";
         DropDownBack2.current.style.pointerEvents = "all";
         // DropDownBack2.current.style.marginBottom = "-190px";
         DropDownBack2.current.style.height = "100%";
      }
      else {
         DropDownBack2.current.style.transform = 'translate(0px, -50px)'
         DropDownBack2.current.style.opacity = '';
         DropDownBack2.current.style.pointerEvents = "none";
         // DropDownBack2.current.style.marginBottom = "0px";
         DropDownBack2.current.style.height = "0";
         DropDownBack2.current.style.marginTop = "0px";
      }
   }
   function filterBarInteraction(color, content, tag, value) {

      var currentValue = filtersArr;
      if (value && currentValue.filter(elm => elm.tag == tag).length > 0) {
         currentValue = currentValue.filter(elm => elm.tag != tag)
      }
      if (value) {
         currentValue = [...currentValue, { id: filterIdCounter, color: color, content: content, tag: tag }]
         setFilterIdCounter(filterIdCounter + 1)
      } else {
         currentValue = currentValue.filter(elm => elm.tag != tag)
         // console.log(filtersArr.filter(elm => elm.content != content))
      }
      setFiltersArr(currentValue)
   }
   function removeFromFilterBar(id) {

      setFiltersArr(filtersArr.filter(elm => elm.id != id))
      setResetContent(filtersArr.filter(elm => elm.id == id)[0].content)
   }
   function changeSortType(e, system = 0, list = instList) {
      switch (e) {
         case "joy":
            setSortingType("Joy")
            setInstList([...list].sort((a, b) => b.joy - a.joy))
            break;
         case "vibe":
            setSortingType("Vibe")
            setInstList([...list].sort((a, b) => b.vibe - a.vibe))
            break;
         case "service":
            setSortingType("Service")
            setInstList([...list].sort((a, b) => b.vibe - a.vibe))
            break;
         case "price":
            setSortingType("Price")
            setInstList([...list].sort((a, b) => b.price - a.price))
            break;
         default:
            setSortingType(null)
            setInstList([...list].sort((a, b) => b.average - a.average))
            break;
      }
      if (!system)
         sortRef.current.click()
   }
   async function locationChange() {
      if (locationTemp) {
         setWelcomeContextVisibility(false);
         if (locationTemp)
            await updateDoc(doc(db, "userData", auth.currentUser.uid), {
               location: locationTemp,
            });
      }
      setHeaderReset(0);
      setHeaderReset(1);
      fetchInstitutions();
   }
   return (

      <div className=' relative bg-bgimage bg-cover  bg-no-repeat bg-center after:content-[""] after:absolute after:top-0 after:left-0 after:w-full after:h-full  after:bg-[radial-gradient(115.11%_87.41%_at_50%_100%,rgba(98,50,216,0.00)0%,#6131D7_100%)] after:z-10 after:bg-cover'>
         <ContextWindow description="Please pick preferred location" visibility={welcomeContextVisibility} setvisibility={setWelcomeContextVisibility} title="Welcome to Nightlife" >
            <CitySearchBar context setlocation={setLocationTemp} className='relative' setcontextvisibility={setWelcomeContextVisibility} />
            <Button onClick={locationChange} className='sm:mt-[30px] mt-[20px]'>Pick</Button>
         </ContextWindow>
         <div className='relative z-20'>
            <MainHeader key={headerReset} setlocation={setLocation} />
            <div>
               <Container>
                  <div className='flex mt-[00px] sm:mt-[30px]  flex-wrap md:flex-nowrap justify-between md:justify-start flex-row md:gap-[20px] lg:gap-0 '>
                     <DropDown onClick={filterDrop} className=' pl-[10px] md:pl-0 justify-center flex  flex-shrink-0 order-2 md:order-1' image={FilterIco} title="Filters" />
                     <div ref={filterScrollParent} className={filtersArr.length > 0 ? 'basis-[100%] oreder-1 md:order-2 min-w-[120px] flex-auto mx-[5px] overflow-x-scroll [&::-webkit-scrollbar]:hidden [scrollbar-width:none] [-ms-overflow-style:none] flex items-center h-[70px] [-webkit-mask-image:linear-gradient(to_right,rgba(0,0,0,0),rgba(0,0,0,1)_4%,rgba(0,0,0,1)_96%,rgba(0,0,0,0)_100%)]' : 'h-[35px] md:h-[70px] basis-[100%] oreder-1 md:order-2 min-w-[120px] flex-auto mx-[5px] overflow-x-scroll [&::-webkit-scrollbar]:hidden [scrollbar-width:none] [-ms-overflow-style:none] flex items-center [-webkit-mask-image:linear-gradient(to_right,rgba(0,0,0,0),rgba(0,0,0,1)_4%,rgba(0,0,0,1)_96%,rgba(0,0,0,0)_100%)]'}>
                        <div prevpercentage="0" percentage="0" mousedownat="0" ref={filterScroll} className=' mx-[30px] gap-[10px] flex items-center mt-[-20px] mb-[-20px] h-[70px]'>
                           {filtersArr.map((element) =>
                              <FilterItem resetAll={resetContentAll} removefunc={removeFromFilterBar} id={element.id} key={element.id} color={element.color}>{element.content}</FilterItem>
                           )}
                        </div>
                     </div>
                     <DropDown setref={setSortRef} className='justify-center flex  order-3 pr-[10px] md:pr-[25px]  ' onClick={sortingDrop} title={sortingType ? "Sort: " + sortingType.toString() : "Sorting"} />
                  </div>
                  <div className='flex justify-between flex-col lg:flex-row h-full 
                  '>
                     <div ref={DropDownBack} style={{ transform: "translate(0,-50px)" }} className='h-0 lg:mt-0  opacity-0 transition ease-linear duration-100 relative pointer-events-none'>
                        <FilterBar location={location} resetContent={resetContent} nextDay={setNextDayIndex} setResetContent={setResetContent} filtersArr={filtersArr} func={filterBarInteraction} />
                     </div>
                     <Sortdrop DropDownBack2={DropDownBack2} changeSortType={changeSortType} />
                  </div>

               </Container>
               <Container className=' lg:pt-[10px] pb-[60px]'>
                  <div className='flex flex-row gap-[30px] sm:m-0 mx-[-10px]'>
                     <div ref={mainScrollBody} className='max-h-[730px] w-full overflow-y-scroll overflow-hidden [&::-webkit-scrollbar]:hidden [scrollbar-width:none] [-ms-overflow-style:none] 
                     [-webkit-mask-image:linear-gradient(to_bottom,rgba(0,0,0,0),rgba(0,0,0,1)_10%,rgba(0,0,0,1)_90%,rgba(0,0,0,0)_100%)]
                     '>
                        <div className='md:min-h-[700px] min-h-[500px] w-full flex flex-col gap-[15px] mt-[40px] mb-[40px] items-center'>
                           {instList.length != 0
                              ? instList.map((element, index) =>
                                 <ListItem data={element} key={index} />
                              )
                              : <p className='flex min-h-[300px] sm:min-h-[600px] justify-center items-center font-[GeneralSans] font-medium text-white text-[18px] md:text-[24px]'>Error: No elements found</p>
                           }
                        </div>
                     </div>
                     <div className='hidden lg:block  max-h-[730px] relative select-none'>

                        <div ref={mainScrollbarBody} draggable="0" className=' h-full relative bg-[rgba(158,158,158,0.50)] rounded-[1px] w-[2px] mt-[30px] mb-[40px] shadow-[0px_0px_10px_#000] 
                     '>
                        </div>
                        <div ref={mainScrollbarButton} draggable="0" prevpercentage="0" percentage="0" mousedownat="0" className='select-none absolute top-[40px] left-1/2 w-[9px] h-[70px] rounded-[12px] bg-[#6131D7] -translate-x-1/2'>

                        </div>
                     </div>



                  </div>
               </Container>
               <FooterMain />
            </div>
         </div>
      </div>
   );
};


function sortdrop({ DropDownBack2, changeSortType }) {
   return (<div ref={DropDownBack2} style={{
      transform: "translate(0,-50px)"
   }} className='relative lg:mr-[50px]  h-0 opacity-0 transition ease-linear duration-100 pointer-events-none '>
      <div className='select-none cursor-pointer text-right font-[Satoshi] text-[18px] text-white flex flex-col items-center lg:items-end gap-[2px] [&>*:hover]:transition-all [&>*:hover]:ease-linear [&>*:hover]:duration-100 [&>*:hover]:drop-shadow-[0px_0px_5px_#fff] '>
         <p type="" onClick={changeSortType}>Overall best rating</p>
         <p type="joy" onClick={changeSortType}>Best “Joy” rating</p>
         <p type="vibe" onClick={changeSortType}>Best “Vibe” rating</p>
         <p type="service" onClick={changeSortType}>Best “Service” rating</p>
         <p type="price" onClick={changeSortType}>Best “Price” rating</p>
      </div>
   </div>);
}
export default MainPage;