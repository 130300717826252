import React from 'react';

const Title = ({ children, ...props }) => {
   return (
      <h2 className='justify-center items-center mt-[30px] lg:mt-0 font-[GeneralSans] text-white text-[24px] sm:text-[38px] font-bold flex gap-[6px]'>
         {children}
         <img className='pointer-events-none sm:h-full h-[28px]' src={props.image} alt="" />
      </h2>
   );
};

export default Title;