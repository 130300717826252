import React from 'react';
import SubmitArrow from '../../img/ArrowSmallSubmit.svg'


const FormSubmit = () => {
   return (
      <button className=' relative
      text-yellow rounded-full border-[1px] border-yellow  w-[50px] h-[50px] sm:w-[60px] sm:h-[60px]
      flex justify-center items-center 
      after:content-[` `] after:absolute after:left-0 after:top-0 after:bg-gradient-to-b after:from-[rgba(255,206,60,0.24)]
      after:to-[rgba(251,203,58,0.64)] after:w-full after:h-full after:rounded-full after:opacity-50 after:transition-all after:delay-0 after:duration-100 after:ease-linear
      transition-all delay-0 duration-100 ease-linear
      
      hover:after:to-90% hover:after:opacity-70 hover:shadow-[0_2px_25px_rgba(251,203,58,0.90)]
      '>
         <img src={SubmitArrow} alt="" />
      </button>
   );
};

export default FormSubmit;