import React from 'react';


const FilterBarItem = ({ children, ...props }) => {
   return (
      <div className='select-none relative flex flex-col  justify-start font-[GeneralSans] text-[20px] text-white 
      [&:not(&:last-child)]:pr-[20px] [&:not(&:last-child)]:mr-[20px]
      [&:not(&:last-child)::after]:block after:hidden after:mt-[5px] after:-mb-[5px] after:absolute after:top-0 after:right-0 after:h-full after:w-[1px] after:bg-white '>
         <h1 className='font-medium mb-[4px]'>{props.title}</h1>
         <div className={props.wrap}>
            {children}
         </div>
      </div>
   );
};

export default FilterBarItem;