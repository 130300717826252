import React, { useEffect } from 'react';
import Joy from '../../img/joy.svg';
import Vibe from "../../img/good-vibes.svg";
import Service from "../../img/Service.svg";
import Menu from '../../img/poinsettia.svg';
import Money from '../../img/money.svg';

const RateType = ({ children, ...props }) => {




   var Icons = [
      { id: 1, ico: Joy, name: "Enjoyability" },
      { id: 2, ico: Vibe, name: "Overall vibe" },
      { id: 3, ico: Service, name: "Service quality" },
      { id: 4, ico: Menu, name: "Menu" },
      { id: 5, ico: Money, name: "Price" }
   ];



   var currentIco;
   var currentName;

   if (parseInt(props.icotype) <= 5 && parseInt(props.icotype) >= 1) {
      currentIco = Icons.filter((e) => {
         return e.id == props.icotype;
      })[0].ico;
      currentName = Icons.filter((e) => {
         return e.id == props.icotype;
      })[0].name;
   }
   else
      currentIco = Icons[0].ico;

   if (props.major)
      return (
         <div className='flex flex-col items-center'>
            <div className='flex font-[Satoshi] text-[20px] sm:text-[24px] items-center font-light text-white gap-[10px] leading-[100%] mb-[10px]'>
               {currentName}
               <img src={currentIco} className='select-none pointer-events-none  h-[30px] w-[30px]' alt="" />
            </div>
            <div className=' select-none pointer-events-none flex flex-auto justify-end items-center font-[GeneralSans] text-[18px] text-white'>
               <p className='text-yellow'>{children}</p>
               <div className='h-[20px] w-[1px] bg-white rounded-[0.5px] rotate-[20deg] mx-[10px]'></div>
               5
            </div>
         </div >
      );
   else
      return (
         <div className='flex w-[85px]'>
            <img src={currentIco} className='select-none pointer-events-none h-[30px] w-[30px]' alt="" />
            <div className=' select-none pointer-events-none flex flex-auto justify-end items-center font-[GeneralSans] text-[18px] text-white'>
               <p className='text-yellow'>{children}</p>

               <div className='h-[20px] w-[1px] bg-white rounded-[0.5px] rotate-[20deg] mx-[6px]'></div>
               5
            </div>
         </div >
      );
};

export default RateType;