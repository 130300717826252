import React, { useContext, useState, useEffect } from 'react';
import SignInHeader from '../components/signinheader';
import Image from '../img/icoplug.png'
import EditLink from '../components/UI/editlink';
import Container from '../components/container';
import RateScroll from '../components/ratescroll';
import Footer from '../components/footer';
import { AuthContext } from '../context';
import FooterMain from '../components/footermain';
import { auth, db, storage } from "../firebase"
import { signOut } from 'firebase/auth';
import { ref, uploadBytes, uploadBytesResumable, getDownloadURL, list, deleteObject } from "firebase/storage";
import { updateEmail, reauthenticateWithPopup, GoogleAuthProvider, sendEmailVerification, updateProfile } from "firebase/auth"
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import ContextWindow from '../components/contextwindow.jsx';
import FormInputWhite from '../components/UI/forminputwhite.jsx';
import Button from '../components/UI/button.jsx';
import cross from "../img/errorcross.svg";
import check from "../img/checkValidIco.svg"
import CitySearchBar from '../components/citysearchbar.jsx';

const ProfilePage = () => {

   const [username, setUsername] = useState();
   const [userLocation, setUserLocation] = useState();
   const [emailContextVisibility, setEmailContextVisibility] = useState(false);
   const [profilePic, setProfilePic] = useState();
   const [locationTemp, setLocationTemp] = useState();
   const [locationContextVisibility, setLocationContextVisibility] = useState(false);
   const [profilePicVisibility, setProfilePicVisibility] = useState()
   const [email, setEmail] = useState("");
   const [emailError, setEmailError] = useState("false");
   const [errors, setErrors] = useState([]);
   const [photoUrl, setPhotoUrl] = useState();
   const [location, setLocation] = useState("");
   const [locationError, setLocationError] = useState("false")

   useEffect(() => {
      fetchName();
      fetchLocation();
      fetchPhotoUrl()
   }, [])

   async function fetchName() {
      const name = await getDoc(doc(db, "userData", auth.currentUser.uid.toString()));
      setUsername(name.data().nickname);
   }
   async function fetchLocation() {
      const name = await getDoc(doc(db, "userData", auth.currentUser.uid.toString()));
      setUserLocation(name.data().location);
   }
   function logout(e) {
      e.preventDefault();
      signOut(auth).then(() => {
         console.log('Sign out successful')
      }).catch((error) => { console.log(error) })
      // setIsAuth(false);
      // localStorage.removeItem('auth');
   }
   const googleProvider = new GoogleAuthProvider();

   function reauthWithGoogle() {
      return reauthenticateWithPopup(auth.currentUser, googleProvider).catch((error) => {
         var errorsLocal = <div className='flex gap-[10px] text-[#DE3B3B] mt-[20px] text-[16px] sm:text-[20px] font-[Satoshi] font-extralight '><img src={cross} alt="" />{error.message}</div>
         setErrors(errorsLocal)
      })
   }
   function sendMail() {
      sendEmailVerification(auth.currentUser)
      alert("Verification e-mail was sent to " + auth.currentUser.email)
   }
   function emailContextSubmit(e) {
      var errorsLocal = []
      e.preventDefault()
      updateEmail(auth.currentUser, email).then(() => {
         errorsLocal = [...errorsLocal, <div className='flex gap-[10px] text-[#26E974] mt-[20px] text-[16px] sm:text-[20px] font-[Satoshi] font-extralight '><img src={check} alt="" />E-mail was successfully changed!</div>]
         setErrors(errorsLocal)
      }).catch(async (error) => {
         if (error.message.includes("email")) setEmailError("true");
         errorsLocal = [...errorsLocal, <div className='flex gap-[10px] text-[#DE3B3B] mt-[20px] text-[16px] sm:text-[20px] font-[Satoshi] font-extralight '><img src={cross} alt="" />{error.message}</div>]
         if (error.message.includes("requires-recent-login")) {
            await reauthWithGoogle().then(() => {
               emailContextSubmit(e);
            }).catch((error) => {
               errorsLocal = [...errorsLocal, <div className='flex gap-[10px] text-[#DE3B3B] mt-[20px] text-[16px] sm:text-[20px] font-[Satoshi] font-extralight '><img src={cross} alt="" />{error.message}</div>]
            });
         }
         setErrors(errorsLocal)

      });


   }
   async function locationChange() {
      if (locationTemp) {
         setLocationContextVisibility(false);
         if (locationTemp && fetchLocation)
            await updateDoc(doc(db, "userData", auth.currentUser.uid), {
               location: locationTemp,
            });
      }
      fetchLocation()
   }
   function fetchPhotoUrl() {
      auth.currentUser.photoURL ? setPhotoUrl(auth.currentUser.photoURL) : setPhotoUrl(Image)
   }

   async function changePprofilePic() {
      if (profilePic) {
         const metadata = {
            contentType: `image/${profilePic.name.slice(profilePic.name.indexOf('.') + 1)}`,
         };
         //${auth.currentUser.uid + profilePic.slice(profilePic.lastIndexOf("."))}`)
         var fileName;
         await list(ref(storage, `/profilepics/${auth.currentUser.uid}/`), { maxResults: 1 }).then((result) => {
            if (result.items[0]) {
               fileName = result.items[0].name;
               deleteObject(ref(storage, `/profilepics/${auth.currentUser.uid}/${result.items[0].name}`))
            }
            console.log(result.items[0].name)
         }).catch((error) => {
            console.log(error)
         })
         await uploadBytesResumable(ref(storage, `/profilepics/${auth.currentUser.uid}/${profilePic.name}`), profilePic, metadata).then(() => {
            console.log("uploaded successfuly");
         })
         await list(ref(storage, `/profilepics/${auth.currentUser.uid}/`), { maxResults: 1 }).then((result) => {
            if (result.items[0]) {
               fileName = result.items[0].name;
            }
         }).catch((error) => {
            console.log(error)
         })
         await getDownloadURL(ref(storage, `/profilepics/${auth.currentUser.uid}/${fileName}`)).then((result) => {
            updateProfile(auth.currentUser, {
               photoURL: result
            }).then(() => {
               fetchPhotoUrl()
            })

         })



         setProfilePicVisibility(false)
      }
      // console.log(profilePic.slice(profilePic.lastIndexOf("\\") + 1))
   }




   return (
      <div className=' relative bg-bgimage bg-cover  bg-no-repeat bg-center after:content-[""] after:absolute after:top-0 after:left-0 after:w-full after:h-full  after:bg-[radial-gradient(115.11%_87.41%_at_50%_100%,rgba(98,50,216,0.00)0%,#6131D7_100%)] after:z-10 after:bg-cover'>
         <ContextWindow description="It may require re-authentication" title='Change e-mail' visibility={emailContextVisibility} setvisibility={setEmailContextVisibility}>
            <form className='w-full flex flex-col items-center' onSubmit={emailContextSubmit}>
               <FormInputWhite invalid={emailError} onFocus={() => { setEmailError("false") }} value={email} onChange={(e) => { setEmail(e.target.value) }} placeholder='E-mail' required type='email'></FormInputWhite>
               {errors}
               <Button className='sm:mt-[30px] mt-[20px]'>Change</Button>
            </form>
         </ContextWindow>
         <ContextWindow title='Change search location' visibility={locationContextVisibility} setvisibility={setLocationContextVisibility}>
            <CitySearchBar setlocation={setLocationTemp} className='relative min-h-[150px]' />
            <Button onClick={locationChange} className='sm:mt-[30px] mt-[20px]'>Change</Button>
            {/* <form className='w-full flex flex-col items-center' onSubmit={emailContextSubmit}>
               <FormInputWhite invalid={locationError} onFocus={() => { setLocationError("false") }} value={location} onChange={(e) => { setLocation(e.target.value) }} placeholder='Location' required type='text'></FormInputWhite>
               {errors}
               
            </form> */}
         </ContextWindow>
         <ContextWindow description="File size is limited by 2MB" title='Change the profile picture' visibility={profilePicVisibility} setvisibility={setProfilePicVisibility}>
            {/* <CitySearchBar setlocation={setLocationTemp} className='relative min-h-[150px]' /> */}
            <div className='flex items-center gap-[15px] flex-wrap justify-center'>
               <label htmlFor="file-upload" className='flex justify-center items-center gap-[5px] md:gap-[10px] font-[Satoshi] text-[18px] md:text-[24px] text-white hover:underline cursor-pointer'>Upload</label><p className=' text-nowrap font-[Satoshi] text-[18px] md:text-[24px] text-white font-light'>{profilePic ? profilePic.name.slice(profilePic.name.lastIndexOf("\\") + 1) : "No image"}</p>
               <input accept=".jpg,.png,.webp" onChange={(e) => {
                  if (e.target.files[0])
                     if (e.target.files[0].size < 2097152)
                        setProfilePic(e.target.files[0])
               }} className="hidden" id='file-upload' type="file" />
            </div>
            <Button onClick={changePprofilePic} className='sm:mt-[30px] mt-[20px]'>Change</Button>
         </ContextWindow>
         <div className='relative z-20'>
            <SignInHeader />
            <div className='pt-[60px] pb-[30px] md:pt-[130px] md:pb-[150px] flex items-center flex-col gap-[15px] md:gap-[20px]'>
               <div onClick={() => { setProfilePicVisibility(true) }} className='w-[120px] h-[120px] md:w-[220px] md:h-[220px] rounded-full border-[2px] border-[rgba(255,255,255,0.70)] drop-shadow-[0px_4px_24px_0px_rgba(0,0,0,0.20)] relative overflow-hidden mb-[10px] cursor-pointer
               
               after:content-[""] after:absolute after:w-full after:h-full after:top-0 after:left-0 after:bg-[radial-gradient(50%_50%_at_50%_50%,rgba(97,49,215,0.00)_0%,rgba(97,49,215,0.00)_71.77%,rgba(97,49,215,0.23)_100%)] after:z-10  hover:[&:after]:bg-[radial-gradient(50%_50%_at_50%_50%,rgba(97,49,215,0.10)_0%,rgba(97,49,215,0.20)_71.77%,rgba(97,49,215,0.43)_100%)]
               after:transition-all after:duration-300 after:ease-in-out 

               before:content-[""] before:top-0 before:left-0 before:w-full before:h-full before:bg-editico before:absolute before:bg-no-repeat before:bg-center before:transition-all before:duration-300 before:ease-in-out before:opacity-0 hover:[&:before]:opacity-100 
               '>
                  <img src={photoUrl} className='h-full w-full object-cover' alt="" />
               </div>

               <h1 className='font-[GeneralSans] text-[24px] md:text-[40px] font-bold text-white '>{username ? username : "Loading..."}</h1>
               <EditLink onClick={() => { setEmail(""); setErrors([]); setEmailContextVisibility(true) }}>{auth.currentUser.email}</EditLink>
               <div className='flex gap-[15px] flex-wrap justify-center items-center px-[15px]'><p className='font-[GeneralSans] text-[22px] md:text-[32px] font-bold text-white'>Verification:</p>
                  {auth.currentUser.emailVerified ? <div className='flex items-center gap-[10px] text-[#26E974] font-[Satoshi] text-[20px] md:text-[32px] '>Verified<img className='md:h-[25px]' src={check} alt="" /></div> : <EditLink onClick={sendMail}>Send an e-mail</EditLink>}
               </div>
               <p className='font-[GeneralSans] text-[22px] md:text-[32px] font-bold text-white'>Permanent location:</p>
               <EditLink onClick={() => { setLocation(""); setErrors([]); setLocationContextVisibility(true) }}>{userLocation ? userLocation : "None"}</EditLink>
               <div onClick={logout} className='flex gap-[20px] md:gap-[30px] font-[GeneralSans] text-[20px] md:text-[32px] font-bold text-white items-center transition-all duration-300 ease-in-out cursor-pointer hover:drop-shadow-[0px_0px_10px_white]'>
                  Log out
                  <div className=' relative h-[20px] md:h-[30px] w-[3px] md:w-[4px] rounded-full bg-white rotate-45 after:content-[""] after:absolute  after:rounded-full after:top-0 after:left-0 md:after:h-[30px] after:h-[20px] after:w-[3px] md:after:w-[4px] after:bg-white after:-rotate-90 after:pointer-events-none'></div>
               </div>
            </div>
            <Container>
               <RateScroll />

            </Container>
            <FooterMain />
         </div>
      </div>
   );
};

export default ProfilePage;