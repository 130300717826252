import React, { useEffect, useState, useRef } from 'react';
import FilterBarItem from './filterbaritem';
import CheckBox from './checkbox';
import FormTimeInput from './formtimeinput.jsx'
import ScheduleField from '../schedulefield.jsx';

const FilterBarAdm = (props) => {

   return (
      <div className=' lg:ml-[50px] flex flex-wrap lg:flex-nowrap gap-y-[20px] lg:gap-y-0 [&>*]:flex-auto'>
         <FilterBarItem title="Min age:">
            <CheckBox resetContent={props.resetContent} setResetContent={props.setResetContent} filterCheck formalcolor="yellow" func={props.func} color="[background:linear-gradient(180deg,rgba(251,203,58,0.60)0%,rgba(251,203,58,0.80)100%);]">16</CheckBox>
            <CheckBox resetContent={props.resetContent} setResetContent={props.setResetContent} filterCheck formalcolor="yellow" func={props.func} color="[background:linear-gradient(180deg,rgba(251,203,58,0.60)0%,rgba(251,203,58,0.80)100%);]">18</CheckBox>
         </FilterBarItem>
         <FilterBarItem title="Institutions:">
            <CheckBox resetContent={props.resetContent} setResetContent={props.setResetContent} filterCheck formalcolor="orange" func={props.func} color="[background:linear-gradient(180deg,#E64421,0%,rgba(230,68,33,0.60)0%,rgba(230,68,33,0.80)100%);]">Club</CheckBox>
            <CheckBox resetContent={props.resetContent} setResetContent={props.setResetContent} filterCheck formalcolor="orange" func={props.func} color="[background:linear-gradient(180deg,#E64421,0%,rgba(230,68,33,0.60)0%,rgba(230,68,33,0.80)100%);]">Restaurant</CheckBox>
            <CheckBox resetContent={props.resetContent} setResetContent={props.setResetContent} filterCheck formalcolor="orange" func={props.func} color="[background:linear-gradient(180deg,#E64421,0%,rgba(230,68,33,0.60)0%,rgba(230,68,33,0.80)100%);]">Bar</CheckBox>
            <CheckBox resetContent={props.resetContent} setResetContent={props.setResetContent} filterCheck formalcolor="orange" func={props.func} color="[background:linear-gradient(180deg,#E64421,0%,rgba(230,68,33,0.60)0%,rgba(230,68,33,0.80)100%);]">Other</CheckBox>
         </FilterBarItem>
         <FilterBarItem title="Menu:">
            <CheckBox resetContent={props.resetContent} setResetContent={props.setResetContent} filterCheck formalcolor="blue" func={props.func} color="[background:linear-gradient(180deg,rgba(58,147,251,0.60)0%,rgba(58,147,251,0.80)100%);]">Drinks only</CheckBox>
            <CheckBox resetContent={props.resetContent} setResetContent={props.setResetContent} filterCheck formalcolor="blue" func={props.func} color="[background:linear-gradient(180deg,rgba(58,147,251,0.60)0%,rgba(58,147,251,0.80)100%);]">Drinks &<br />food</CheckBox>
         </FilterBarItem>
         <FilterBarItem title="Aditional:">
            <CheckBox resetContent={props.resetContent} setResetContent={props.setResetContent} filterCheck formalcolor="purple" func={props.func} color="[background:linear-gradient(180deg,rgba(178,58,251,0.60)0%,rgba(178,58,251,0.80)100%);]">Hookah</CheckBox>
         </FilterBarItem>
         <FilterBarItem title="Opened at:">
            {/* <div className="max-h-[100px] flex flex-col flex-wrap [&>*:not(:last-child)]:mr-[20px]">
               <CheckBox resetContent={props.resetContent} setResetContent={props.setResetContent} filterCheck formalcolor="green" func={props.func} color="[background:linear-gradient(180deg,rgba(45,180,116,0.60)0%,rgba(45,180,116,0.80)100%);]">Mon</CheckBox>
               <CheckBox resetContent={props.resetContent} setResetContent={props.setResetContent} filterCheck formalcolor="green" func={props.func} color="[background:linear-gradient(180deg,rgba(45,180,116,0.60)0%,rgba(45,180,116,0.80)100%);]">Tues</CheckBox>
               <CheckBox resetContent={props.resetContent} setResetContent={props.setResetContent} filterCheck formalcolor="green" func={props.func} color="[background:linear-gradient(180deg,rgba(45,180,116,0.60)0%,rgba(45,180,116,0.80)100%);]">Weds</CheckBox>
               <CheckBox resetContent={props.resetContent} setResetContent={props.setResetContent} filterCheck formalcolor="green" func={props.func} color="[background:linear-gradient(180deg,rgba(45,180,116,0.60)0%,rgba(45,180,116,0.80)100%);]">Thurs</CheckBox>
               <CheckBox resetContent={props.resetContent} setResetContent={props.setResetContent} filterCheck formalcolor="green" func={props.func} color="[background:linear-gradient(180deg,rgba(45,180,116,0.60)0%,rgba(45,180,116,0.80)100%);]">Fri</CheckBox>
               <CheckBox resetContent={props.resetContent} setResetContent={props.setResetContent} filterCheck formalcolor="green" func={props.func} color="[background:linear-gradient(180deg,rgba(45,180,116,0.60)0%,rgba(45,180,116,0.80)100%);]">Sat</CheckBox>
               <CheckBox resetContent={props.resetContent} setResetContent={props.setResetContent} filterCheck formalcolor="green" func={props.func} color="[background:linear-gradient(180deg,rgba(45,180,116,0.60)0%,rgba(45,180,116,0.80)100%);]">Sun</CheckBox>
            </div> */}
            <ScheduleField day="Mon" nextDay={props.nexDay} func={props.func} resetContent={props.resetContent} setResetContent={props.setResetContent} ></ScheduleField>
            <ScheduleField day="Tues" nextDay={props.nexDay} func={props.func} resetContent={props.resetContent} setResetContent={props.setResetContent} ></ScheduleField>
            <ScheduleField day="Weds" nextDay={props.nexDay} func={props.func} resetContent={props.resetContent} setResetContent={props.setResetContent} ></ScheduleField>
            <ScheduleField day="Thurs" nextDay={props.nexDay} func={props.func} resetContent={props.resetContent} setResetContent={props.setResetContent} ></ScheduleField>
            <ScheduleField day="Fri" nextDay={props.nexDay} func={props.func} resetContent={props.resetContent} setResetContent={props.setResetContent} ></ScheduleField>
            <ScheduleField day="Sat" nextDay={props.nexDay} func={props.func} resetContent={props.resetContent} setResetContent={props.setResetContent} ></ScheduleField>
            <ScheduleField day="Sun" nextDay={props.nexDay} func={props.func} resetContent={props.resetContent} setResetContent={props.setResetContent} ></ScheduleField>
            {/* <div className='flex flex-wrap justify-between items-center
          text-[18px] gap-x-[20px] gap-y-[8px]'>
               From
               <FormTimeInput resetContent={props.resetContent} setResetContent={props.setResetContent} reset={reset} resetfunc={resetFunc} func={getFromValue} color="[background:linear-gradient(180deg,rgba(45,180,116,0.60)0%,rgba(45,180,116,0.80)100%);]" />
               till
               <FormTimeInput resetContent={props.resetContent} setResetContent={props.setResetContent} func={getTillValue} color="[background:linear-gradient(180deg,rgba(45,180,116,0.60)0%,rgba(45,180,116,0.80)100%);]" />
               <CheckBox formalcolor="green" value={{ nextDayIndex, setNextDayIndex }} valueCheck color="[background:linear-gradient(180deg,rgba(45,180,116,0.60)0%,rgba(45,180,116,0.80)100%);]">next day</CheckBox>
            </div> */}
         </FilterBarItem>
      </div>
   );
};

export default FilterBarAdm;