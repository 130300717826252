import React, { useState } from 'react';
import SubText from './UI/subtext';
import Container from './container';
import FormInput from './UI/forminput';
import FormSubmit from './UI/formsubmit';
import check from "../img/checkValidIco.svg";
import { db } from "../firebase";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore"


const SubscribeBlock = () => {

   const [email, setEmail] = useState("");
   const emailRegEx = RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
   async function mailSubmit(e) {
      e.preventDefault();
      if (emailRegEx.test(email)) {
         var emailTemp = [];
         await getDocs(query(collection(db, "emails"), where("email", "==", email))).then((querySnapshot) => {
            emailTemp = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
         }).finally(async () => {
            if (emailTemp.length == 0) {
               await addDoc(collection(db, "emails"), {
                  email: email
               })
            }
         })
      }
      setEmail("");
   }

   return (
      <div className=' bg-black'>
         <Container>
            <SubText className='pt-[50px]'>
               Subscribe to our updates!
            </SubText>
            <form method="post" onSubmit={mailSubmit} action='#' className='flex sm:flex-row items-center sm:items-start flex-col gap-5 justify-center mt-[30px] pb-[30px] sm:pb-[90px]'>
               <FormInput value={email} onChange={(e) => { setEmail(e.target.value) }} required type='email' placeholder='E-mail' />
               <FormSubmit />

            </form>

         </Container>
      </div>
   );
};

export default SubscribeBlock;