import React, { useEffect, useRef } from 'react';

const FormInputWhite = (props) => {
   const passInput = useRef();
   const regInput = useRef();
   useEffect(() => {
      if (passInput.current) {
         if (props.invalid == "true") {
            passInput.current.style.border = '1px solid #DE3B3B';
            passInput.current.style.boxShadow = '0px 0px 21px 0px #DD1616';
            passInput.current.style.color = '#DE3B3B';
         } else if (props.invalid != "true") {
            passInput.current.style.border = '';
            passInput.current.style.boxShadow = '';
            passInput.current.style.color = '';
         }
      }
      if (regInput.current) {
         if (props.invalid == "true") {
            regInput.current.style.border = '1px solid #DE3B3B';
            regInput.current.style.boxShadow = '0px 0px 21px 0px #DD1616';
            regInput.current.style.color = '#DE3B3B';
         } else if (props.invalid != "true") {
            regInput.current.style.border = '';
            regInput.current.style.boxShadow = '';
            regInput.current.style.color = '';
         }
      }
   })

   if (props.type == "password")
      return (
         <input ref={passInput} {...props} className=' placeholder:tracking-normal tracking-widest [&:not(&:last-of-type)]:mb-[15px] sm:[&:not(&:last-of-type)]:mb-[20px] w-full sm:w-[400px] h-[45px] sm:h-[55px] border placeholder-shown:border-white rounded-full bg-[rgba(255,255,255,0.10)] focus:outline-none sm:px-10 px-[20px]  text-[16px] sm:text-[20px] font-[Satoshi] font-extralight text-white placeholder:text-[rgba(255,255,255,0.76)] valid:shadow-[0px_0px_21px_0px_#26E974] valid:border-[#26E974] valid:text-[#26E974]
         
      ' />
      );
   else return (
      <input ref={regInput} {...props} className='sm:[&:not(&:last-of-type)]:mb-[20px] [&:not(&:last-of-type)]:mb-[15px] w-full sm:w-[400px] h-[45px] sm:h-[55px] border border-white rounded-full bg-[rgba(255,255,255,0.10)] focus:outline-none sm:px-10 px-[20px]  text-[16px] sm:text-[20px] font-[Satoshi] font-extralight text-white placeholder:text-[rgba(255,255,255,0.76)]
      valid:shadow-[0px_0px_21px_0px_#26E974] valid:border-[#26E974] valid:text-[#26E974]
      ' />
   );
};

export default FormInputWhite;